import * as opApi from "@/api/offerPosition";
import * as opgApi from "@/api/offerPositionGroup";
import * as supplierRfqApi from "@/api/supplierRfq";
import { useRouteParams } from "@/composables/useRouteParams";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Product } from "@/types/product";
import type { Supplier } from "@/types/supplier";
import type { SupplierRFQ } from "@/types/supplierRfq";
import type { Tag } from "@/types/tag";
import type { User } from "@/types/user";
import { getAmountAndUnitForNewOfferPosition, getProductPriceInUnit } from "@/utils/productUnitAmount";
import { isEmpty } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useCurrentInquiryStore } from "./currentInquiry";
import { useCurrentUserStore } from "./currentUser";

export const useCurrentOfferPositionGroupsStore = defineStore(
  "currentOfferPositionGroups",
  () => {
    const { organizationId, inboxId, inquiryId } = useRouteParams();
    const { currentRoute } = useRouter();

    const offerPositionGroups = ref<OfferPositionGroup[] | null>(null);
    const draftSupplierRfqsBySupplierId = ref<Map<number, SupplierRFQ>>(
      new Map()
    );

    const numOpenLoadingRequests = ref(0);
    const isLoading = computed(() => numOpenLoadingRequests.value > 0);

    const draftSuppliers = computed(() => {
      return Array.from(draftSupplierRfqsBySupplierId.value.values()).map(
        (supplierRfq: SupplierRFQ) => ({
          ...supplierRfq.supplier,
          rfqId: supplierRfq.id,
        })
      );
    });

    const filterText = ref("");
    const filterOffered = ref(false);
    const filterNotCompleted = ref(false);
    const filterSupplierRfqs = ref(false);
    const filterTags = ref<Tag[]>([]);

    const visibleOfferPositionGroups = computed(() => {
      if (!offerPositionGroups.value) return null;

      let visibleOPGs = offerPositionGroups.value;

      if (filterOffered.value)
        visibleOPGs = visibleOPGs.filter((opg) => opg.isOffered);

      if (filterNotCompleted.value)
        visibleOPGs = visibleOPGs.filter((opg) => !opg.isCompleted);

      if (filterSupplierRfqs.value)
        visibleOPGs = visibleOPGs.filter(
          (opg) =>
            opg.supplierRfqsDraft.length > 0 ||
            opg.supplierRfqsNotDraft.length > 0
        );

      if (filterTags.value.length)
        visibleOPGs = visibleOPGs.filter((opg) =>
          filterTags.value.every((tag) => opg.tags.some((t) => t.id === tag.id))
        );

      if (filterText.value.length) {
        const filterTextLc = filterText.value.toLowerCase();
        visibleOPGs = visibleOPGs.filter(
          (opg) =>
            opg.boqReferenceId?.toLowerCase().includes(filterTextLc) ||
            opg.boqText?.toLowerCase().includes(filterTextLc)
        );
      }

      return visibleOPGs;
    });

    function clear() {
      offerPositionGroups.value = null;
      draftSupplierRfqsBySupplierId.value = new Map();
    }

    async function update() {
      if (
        isNaN(organizationId.value) ||
        isNaN(inboxId.value) ||
        isNaN(inquiryId.value)
      ) {
        offerPositionGroups.value = null;
        return;
      }
      if (currentRoute.value.name !== "inquiry-positions") return;

      numOpenLoadingRequests.value++;
      try {
        offerPositionGroups.value = await opgApi.getOfferPositionGroups(
          organizationId.value,
          inboxId.value,
          inquiryId.value
        );
      } catch (error: any) {
        if (error.response?.status === 404) {
          // 404 errors are handled by InquiryPositionsPage
          offerPositionGroups.value = null;
          return;
        } else {
          throw error;
        }
      } finally {
        updateDraftSupplierRfqsBySupplierId();
        numOpenLoadingRequests.value--;
      }
    }
    watch(
      () => [organizationId.value, inboxId.value, inquiryId.value],
      async (newVal, oldVal) => {
        if ([0, 1, 2].every((i) => newVal[i] == oldVal[i])) return;
        offerPositionGroups.value = null;

        if (currentRoute.value.name !== "inquiry-positions") return;
        await update();
      }
    );
    watch(
      () => currentRoute.value.name,
      async () => {
        if (currentRoute.value.name !== "inquiry-positions") return;
        await update();
      }
    );
    if (currentRoute.value.name === "inquiry-positions") update();

    function updateDraftSupplierRfqsBySupplierId() {
      draftSupplierRfqsBySupplierId.value = new Map();
      offerPositionGroups.value?.forEach((opg) => {
        opg.supplierRfqsDraft.forEach((sr) => {
          draftSupplierRfqsBySupplierId.value.set(sr.supplier.id, sr);
        });
      });
    }

    async function addOfferPositionGroup(afterId: number | null) {
      if (!offerPositionGroups.value) return;
      const temporaryId = crypto.randomUUID();

      const opgBefore = afterId
        ? offerPositionGroups.value.find((opg) => opg.id === afterId)
        : null;

      const tempOfferPositionGroup: OfferPositionGroup = reactive({
        id: getNewId(),
        order: opgBefore?.order || 0,
        documentId: null,
        emailId: null,
        requestedSupplierType: "NEUTRAL",
        isOffered: true,
        isCompleted: false,
        boundingBoxes: [],
        confidence: 1,
        boqReferenceId: null,
        boqText: null,
        boqTextShort: null,
        boqUnit: null,
        boqAmount: null,
        offerPositions: [],
        highlightPolygons: [],
        productCandidates: [],
        isManuallyCreated: true,
        supplierRfqsDraft: [],
        supplierRfqsNotDraft: [],
        temporaryId,
        spotlightId: crypto.randomUUID(),
        spotlightElementIds: [],
        tags: [],
      });

      // insert the group after the group with the given ID
      if (afterId) {
        const index = offerPositionGroups.value.findIndex(
          (opg) => opg.id === afterId
        );
        offerPositionGroups.value.splice(index + 1, 0, tempOfferPositionGroup);
      } else {
        offerPositionGroups.value.unshift(tempOfferPositionGroup);
      }

      try {
        const newOfferPositionGroup = await opgApi.addOfferPositionGroup(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          afterId || null,
          tempOfferPositionGroup
        );
        // update the local object since the ID assigned in the backend might be different
        Object.assign(tempOfferPositionGroup, newOfferPositionGroup);
        tempOfferPositionGroup.temporaryId = undefined;
      } catch (e) {
        // Remove the group if the addition fails
        offerPositionGroups.value = offerPositionGroups.value.filter(
          (opg) => opg.temporaryId !== temporaryId
        );
        return;
      }
    }

    async function deleteOfferPositionGroup(id: number) {
      if (!offerPositionGroups.value) return;

      offerPositionGroups.value = offerPositionGroups.value.filter(
        (opg) => opg.id !== id
      );

      await opgApi.deleteOfferPositionGroup(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        id
      );
    }

    async function moveOfferPositionGroupAfterAnother(
      firstId: number,
      secondId: number
    ) {
      if (!offerPositionGroups.value) return;

      const firstIndex = offerPositionGroups.value.findIndex(
        (opg) => opg.id === firstId
      );
      const secondIndex = offerPositionGroups.value.findIndex(
        (opg) => opg.id === secondId
      );

      if (firstIndex === -1 || secondIndex === -1) return;
      if (firstIndex === secondIndex) return;

      const first = offerPositionGroups.value[firstIndex];

      offerPositionGroups.value.splice(firstIndex, 1);
      offerPositionGroups.value.splice(secondIndex, 0, first);

      await opgApi.moveOfferPositionGroupAfterAnother(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        firstId,
        secondId
      );
    }

    async function moveOfferPosition(
      offerPositionGroupId: number,
      startIndex: number,
      endIndex: number
    ) {
      if (startIndex === endIndex) return;

      const opg = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );
      if (!opg) return;

      const insertAfterIndex = getInsertAfterIndex(startIndex, endIndex);

      const movedOfferPosition = opg.offerPositions[startIndex];
      const insertAfterOfferPosition =
        insertAfterIndex == null ? null : opg.offerPositions[insertAfterIndex];

      opg.offerPositions.splice(startIndex, 1);
      opg.offerPositions.splice(endIndex, 0, movedOfferPosition);

      try {
        const newOrders = await opApi.moveOfferPositionAfterAnother(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          movedOfferPosition.id,
          insertAfterOfferPosition?.id ?? null
        );
        // update the order of the offer positions
        for (const op of opg.offerPositions) {
          if (newOrders[op.id] !== undefined) {
            op.order = newOrders[op.id];
          }
        }
      } catch (e) {
        // sort OPs by their original order
        opg.offerPositions.sort((a, b) => a.order - b.order);
        throw e;
      }
    }

    async function updateOfferPositionGroup(
      id: number,
      data: Partial<OfferPositionGroup>
    ) {
      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === id
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      const oldData = { ...offerPositionGroup };

      Object.assign(offerPositionGroup, data);

      try {
        await opgApi.updateOfferPositionGroup(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          id,
          data
        );
      } catch (e) {
        Object.assign(offerPositionGroup, oldData);
      }
    }

    async function updateMultipleOfferPositionGroups(
      ids: number[],
      data: Partial<OfferPositionGroup>
    ) {
      if (!offerPositionGroups.value) return;

      const oldOfferPositionGroups = offerPositionGroups.value.filter((opg) =>
        ids.includes(opg.id)
      );

      const oldData = oldOfferPositionGroups?.map((opg) => ({ ...opg }));

      oldOfferPositionGroups.forEach((opg) => {
        Object.assign(opg, data);
      });

      try {
        await opgApi.updateMultipleOfferPositionGroups(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          ids,
          data
        );
      } catch (e) {
        if (oldOfferPositionGroups && oldData) {
          oldOfferPositionGroups.forEach((opg, i) =>
            Object.assign(opg, oldData[i])
          );
        }
      }
    }

    async function copyProducts(sourceGroupId: number, targetGroupId: number) {
      if (!offerPositionGroups.value)
        throw new Error("Offer position groups not found");

      if (sourceGroupId === targetGroupId) return;

      const newTargetGroup = await opgApi.copyProducts(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        sourceGroupId,
        targetGroupId
      );

      // replace target group in offerPositionGroups
      offerPositionGroups.value = offerPositionGroups.value.map((opg) =>
        opg.id === targetGroupId ? newTargetGroup : opg
      );
    }

    async function addOfferPosition(
      offerPositionGroup: OfferPositionGroup,
      product: Product,
      variant?: string | null
    ) {

      const [newAmount, newUnit] = getAmountAndUnitForNewOfferPosition(
        offerPositionGroup.boqAmount,
        offerPositionGroup.boqUnit,
        product,
      );

      const purchasePrice = getProductPriceInUnit(product, "purchasePrice", newUnit);
      const listPrice = getProductPriceInUnit(product, "listPrice", newUnit);

      await baseAddOfferPosition(offerPositionGroup.id, {
        product,
        amount: newAmount,
        unit: newUnit,
        purchasePrice,
        unitPrice: listPrice,
        variant,
      });
    }

    async function baseAddOfferPosition(
      offerPositionGroupId: number,
      data: Partial<OfferPosition>
    ) {
      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      if (!data.product) {
        throw new Error("Product is required");
      }

      if (!data.unit) {
        throw new Error("Unit is required");
      }

      if (!offerPositionGroup.isOffered) {
        // Automatically mark the group as offered if a position is added
        updateOfferPositionGroup(offerPositionGroupId, {
          isOffered: true,
        });
      }

      const temporaryId = crypto.randomUUID();

      const tempOfferPosition: OfferPosition = reactive({
        id: getNewId(),
        order: offerPositionGroup?.offerPositions.length || 0,
        productConfidence: 1,
        variant: null,
        productNameCustom: null,
        isAlternative: false,
        isAiSuggestion: false,
        notes: "",
        temporaryId,
        unitPrice: data.product?.listPrice ?? null,
        vatTaxRate: data.product?.vatTaxRate ?? null,
        purchasePrice: data.product?.purchasePrice ?? null,
        discountRate: null,
        ...(data as Pick<OfferPosition, "product" | "unit" | "amount"> &
          Partial<OfferPosition>),
      });

      offerPositionGroup.offerPositions.push(tempOfferPosition);

      try {
        const newOfferPosition = await opApi.addOfferPosition(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          tempOfferPosition
        );
        // update the local object since the ID assigned in the backend might be different
        Object.assign(tempOfferPosition, newOfferPosition);
      } catch (e) {
        // Remove the offer position if the addition fails
        offerPositionGroup.offerPositions =
          offerPositionGroup.offerPositions.filter(
            (op) => op.temporaryId !== temporaryId
          );
        return;
      }
    }

    async function updateOfferPosition(
      offerPositionGroupId: number,
      offerPositionId: number,
      data: Partial<OfferPosition>
    ) {
      if (isEmpty(data)) return;

      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );
      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }
      const index = offerPositionGroup.offerPositions.findIndex(
        (op) => op.id === offerPositionId
      );
      if (index === -1) return;

      const oldOfferPosition = offerPositionGroup.offerPositions[index];

      const offerPosition = {
        ...oldOfferPosition,
        ...data,
      };
      offerPositionGroup.offerPositions.splice(index, 1, offerPosition);
      try {
        await opApi.updateOfferPosition(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          offerPositionId,
          data
        );
      } catch (e) {
        console.error(e);
        offerPositionGroup.offerPositions.splice(index, 1, oldOfferPosition);
      }
    }

    async function deleteOfferPosition(
      offerPositionGroupId: number,
      offerPositionId: number
    ) {
      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      const offerPositionIndex = offerPositionGroup.offerPositions.findIndex(
        (op) => op.id === offerPositionId
      );
      const offerPosition = offerPositionGroup.offerPositions.find(
        (op) => op.id === offerPositionId
      );

      if (!offerPosition) return;

      offerPositionGroup.offerPositions =
        offerPositionGroup.offerPositions.filter(
          (op) => op.id !== offerPositionId
        );

      try {
        await opApi.deleteOfferPosition(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          offerPositionId
        );
      } catch (e) {
        // Reinsert the offer position if the deletion fails
        offerPositionGroup.offerPositions.splice(
          offerPositionIndex,
          0,
          offerPosition
        );
        throw e;
      }
    }

    async function updateVariantConfiguration(
      offerPositionGroupId: number,
      offerPositionId: number,
      variant: string,
      addons: { product: string; variant: string }[]
    ) {
      const newOpg = await opgApi.updateVariantConfiguration(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        offerPositionGroupId,
        offerPositionId,
        variant,
        addons
      );

      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      Object.assign(offerPositionGroup, newOpg);
    }

    async function addSupplierRfq(
      supplier: Supplier,
      opgIds: number[]
    ): Promise<SupplierRFQ> {
      if (draftSupplierRfqsBySupplierId.value.has(supplier.id)) {
        const supplierRfq = draftSupplierRfqsBySupplierId.value.get(
          supplier.id
        ) as SupplierRFQ;
        await addToExistingSupplierRfq(supplierRfq, opgIds);
        return supplierRfq;
      } else {
        return await createNewSupplierRfq(supplier, opgIds);
      }
    }

    async function addToExistingSupplierRfq(
      supplierRfq: SupplierRFQ,
      opgIds: number[]
    ): Promise<SupplierRFQ> {
      const relevantOpgs = visibleOfferPositionGroups.value?.filter((opg) =>
        opgIds.includes(opg.id)
      );
      if (!relevantOpgs) throw new Error("Offer position groups not found");

      const previousSupplierRfqsDraft = relevantOpgs.map((opg) => [
        ...opg.supplierRfqsDraft,
      ]);

      relevantOpgs.forEach((opg) => {
        if (!opg.supplierRfqsDraft.find((rfq) => rfq.id === supplierRfq.id)) {
          opg.supplierRfqsDraft.push(supplierRfq);
        }
      });

      try {
        const apiSupplierRfq = await supplierRfqApi.addOpgsToSupplierRfq(
          inquiryId.value,
          supplierRfq.supplier.id,
          opgIds
        );

        Object.assign(supplierRfq, apiSupplierRfq);
        return supplierRfq;
      } catch (e) {
        relevantOpgs.forEach((opg, index) => {
          opg.supplierRfqsDraft = previousSupplierRfqsDraft[index];
        });
        throw e;
      }
    }

    const { inquiry } = storeToRefs(useCurrentInquiryStore());
    const { user } = storeToRefs(useCurrentUserStore());

    async function createNewSupplierRfq(
      supplier: Supplier,
      opgIds: number[]
    ): Promise<SupplierRFQ> {
      const relevantOpgs = visibleOfferPositionGroups.value?.filter((opg) =>
        opgIds.includes(opg.id)
      );
      if (!relevantOpgs) throw new Error("Offer position groups not found");

      const temporaryId = crypto.randomUUID();

      const tempSupplierRfq: SupplierRFQ = reactive({
        id: getNewSupplierRfqId(),
        temporaryId,
        assignedUser: user.value as User,
        dueDate: null,
        inquiry: {
          id: inquiry.value?.id ?? 0,
          shortCode: inquiry.value?.shortCode ?? "",
          inboxId: inquiry.value?.inbox ?? 0,
          buildingProject: inquiry.value?.buildingProject ?? null,
        },
        supplier: supplier,
        offerPositionGroups: relevantOpgs,
        recipientsTo: [],
        recipientsCc: [],
        recipientsBcc: [],
        attachments: [],
        subject: "",
        body: "",
        attachOriginalBoq: false,
        status: "DRAFT",
        sentAt: null,
      });

      relevantOpgs.forEach((opg) => {
        if (
          opg.supplierRfqsDraft.find((rfq) => rfq.supplier.id === supplier.id)
        )
          return;
        opg.supplierRfqsDraft.push(tempSupplierRfq);
      });

      try {
        const apiSupplierRfq = await supplierRfqApi.addOpgsToSupplierRfq(
          inquiryId.value,
          supplier.id,
          opgIds
        );

        Object.assign(tempSupplierRfq, apiSupplierRfq);
        tempSupplierRfq.temporaryId = undefined;
        draftSupplierRfqsBySupplierId.value.set(supplier.id, tempSupplierRfq);
        return tempSupplierRfq;
      } catch (e) {
        relevantOpgs.forEach((opg) => {
          opg.supplierRfqsDraft = opg.supplierRfqsDraft.filter(
            (rfq) => rfq.temporaryId !== temporaryId
          );
        });
        throw e;
      }
    }

    async function removeSupplierRfq(supplier: Supplier, opgIds: number[]) {
      if (!visibleOfferPositionGroups.value) return;

      const relevantOpgs = visibleOfferPositionGroups.value?.filter((opg) =>
        opgIds.includes(opg.id)
      );

      const existingSupplierRfqByOpg: Record<number, SupplierRFQ> = {};

      relevantOpgs.forEach((opg) => {
        const existingSupplierRfq = opg.supplierRfqsDraft.find(
          (rfq) => rfq.supplier.id === supplier.id
        );
        if (existingSupplierRfq) {
          existingSupplierRfqByOpg[opg.id] = existingSupplierRfq;
        }
        opg.supplierRfqsDraft = opg.supplierRfqsDraft.filter(
          (rfq) => rfq.supplier.id !== supplier.id
        );
      });

      try {
        await supplierRfqApi.removeOpgsFromSupplierRfq(
          inquiryId.value,
          supplier.id,
          opgIds
        );
      } catch (e) {
        relevantOpgs.forEach((opg) => {
          if (existingSupplierRfqByOpg[opg.id]) {
            opg.supplierRfqsDraft.push(existingSupplierRfqByOpg[opg.id]);
          }
        });
        throw e;
      }

      removeDraftSupplierRfqIfEmpty(supplier);
    }

    function removeDraftSupplierRfqIfEmpty(supplier: Supplier) {
      const supplierRfq = draftSupplierRfqsBySupplierId.value.get(supplier.id);
      if (!supplierRfq) return;

      if (
        visibleOfferPositionGroups.value?.every(
          (opg) => !opg.supplierRfqsDraft.includes(supplierRfq)
        )
      ) {
        draftSupplierRfqsBySupplierId.value.delete(supplier.id);
      }
    }

    function getNewId() {
      return (
        Math.max(
          0,
          ...(offerPositionGroups.value
            ?.flatMap((opg) => opg.offerPositions)
            .map((position) => position.id) || [])
        ) + 1
      );
    }

    function getNewSupplierRfqId() {
      return (
        Math.max(
          0,
          ...(offerPositionGroups.value
            ?.flatMap((opg) => [
              ...opg.supplierRfqsDraft,
              ...opg.supplierRfqsNotDraft,
            ])
            .map((rfq) => rfq.id) || [])
        ) + 1
      );
    }

    async function addTag(offerPositionGroupId: number, tag: Tag) {
      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );
      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      const alreadyHasTag = offerPositionGroup.tags.find(
        (t) => t.id === tag.id
      );
      if (alreadyHasTag) return;

      offerPositionGroup.tags.push(tag);
      offerPositionGroup.tags.sort((a, b) => a.label.localeCompare(b.label));
      try {
        await opgApi.addTag(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          tag.id
        );
      } catch (e) {
        offerPositionGroup.tags = offerPositionGroup.tags.filter(
          (t) => t.id !== tag.id
        );
        throw e;
      }
    }

    async function removeTag(offerPositionGroupId: number, tag: Tag) {
      const offerPositionGroup = offerPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );
      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      offerPositionGroup.tags = offerPositionGroup.tags.filter(
        (t) => t.id !== tag.id
      );
      try {
        await opgApi.removeTag(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          tag.id
        );
      } catch (e) {
        offerPositionGroup.tags.push(tag);
        throw e;
      }
    }

    async function addTagsToMultipleOpgs(opgIds: number[], tags: Tag[]) {
      if (!visibleOfferPositionGroups.value) throw new Error("Visible offer position groups not loaded");

      const offerPositionGroups = visibleOfferPositionGroups.value.filter((opg) => opgIds.includes(opg.id));

      offerPositionGroups.forEach((opg) => {
        const newTags = tags.filter((t) => !opg.tags.some((t2) => t2.id === t.id));
        opg.tags.push(...newTags);
      });

      await opgApi.addTagsToMultipleOpgs(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        opgIds,
        tags.map((t) => t.id)
      );
    }

    async function removeTagsFromMultipleOpgs(opgIds: number[], tags: Tag[]) {
      if (!visibleOfferPositionGroups.value) throw new Error("Visible offer position groups not loaded");

      const offerPositionGroups = visibleOfferPositionGroups.value.filter((opg) => opgIds.includes(opg.id));

      const tagIds = tags.map((t) => t.id);

      offerPositionGroups.forEach((opg) => {
        opg.tags = opg.tags.filter((t) => !tagIds.includes(t.id));
      });

      await opgApi.removeTagsFromMultipleOpgs(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        opgIds,
        tagIds
      );
    }

    /** Returns the index of the group in the visibleOfferPositionGroups array */
    function getVisibleGroupIndex(groupId: number | null): number | null {
      if (groupId === null) return null;
      const index = visibleOfferPositionGroups.value?.findIndex(
        (g) => g.id === groupId
      );
      return index === -1 || index === undefined ? null : index;
    }

    /** Returns the id of the group at the given index in the visibleOfferPositionGroups array */
    function getVisibleGroupId(
      index: number | null | undefined
    ): number | null {
      if (index === null || index == undefined) return null;
      return visibleOfferPositionGroups.value?.[index]?.id || null;
    }

    const hasEmailPositions = computed(() => {
      return (
        visibleOfferPositionGroups.value?.some((opg) => opg.emailId !== null) ??
        false
      );
    });

    return {
      clear,
      update,
      isLoading,
      addOfferPosition,
      addOfferPositionGroup,
      addSupplierRfq,
      copyProducts,
      deleteOfferPosition,
      deleteOfferPositionGroup,
      draftSupplierRfqsBySupplierId,
      draftSuppliers,
      moveOfferPosition,
      moveOfferPositionGroupAfterAnother,
      visibleOfferPositionGroups,
      offerPositionGroups,
      removeSupplierRfq,
      updateMultipleOfferPositionGroups,
      updateOfferPosition,
      updateOfferPositionGroup,
      updateVariantConfiguration,
      getVisibleGroupIndex,
      getVisibleGroupId,
      hasEmailPositions,
      addTag,
      removeTag,
      filterTags,
      filterText,
      filterOffered,
      filterNotCompleted,
      filterSupplierRfqs,
      addTagsToMultipleOpgs,
      removeTagsFromMultipleOpgs,
    };
  }
);

/** Returns the index of the position after which the moved position should be inserted */
function getInsertAfterIndex(startIndex: number, endIndex: number) {
  if (endIndex == 0) return null; // moved to start
  if (endIndex > startIndex) return endIndex; // moved forwards
  return endIndex - 1; // moved backwards
}
