import type { InquiryQuery } from "@/api/inquiry";
import { DEFAULT_INQUIRY_TABLE_PAGE_SIZE } from "@/config/constants";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentUserStore } from "@/stores/currentUser";
import { watchIgnorable } from "@vueuse/core";
import { defineStore, storeToRefs } from "pinia";
import { shallowRef, watch, type ShallowRef } from "vue";
import { useRouter, type RouteLocationNormalized } from "vue-router";

export interface Pagination {
  sortBy: string;
  descending: boolean;
  page: number;
  rowsPerPage: number;
}

export const useSearchFilterSortStore = defineStore("search_filter", () => {
  const router = useRouter();
  const route = router.currentRoute.value;

  const searchText = initRef(route, "text", noop, null);
  const searchFilename = initRef(route, "filename", noop, null);
  const filterStatusStrings = initInquiryStatusesRef(route, "status");
  const filterTagsIds = initTagIdsRef(route, "tags");
  const filterSender = initRef(route, "sender", noop, null);
  const filterBuildingProject = initRef(route, "buildingProject", noop, null);
  const filterUserIds = initUserIdsRef(route, "assignedUserId");
  const filterShortCodeId = initRef(route, "shortCode", noop, null);
  const filterBuildingProjectId = initRef(route, "buildingProjectId", noop, null);
  const filterCustomer = initRef(route, "customer", noop, null);
  const pageSize = initRef(
    route,
    "pageSize",
    parseNumberWithDefault(DEFAULT_INQUIRY_TABLE_PAGE_SIZE),
    DEFAULT_INQUIRY_TABLE_PAGE_SIZE
  );
  const page = initRef(route, "page", parseNumberWithDefault(1), 1);
  const sortBy = initRef(route, "sortBy", noop, null);
  const descending = initRef(route, "descending", parseBoolean, null);

  watch(router.currentRoute, (newRoute, oldRoute) => {
    // do nothing if we stay in the same inbox
    if (newRoute.params.inboxId != null && newRoute.params.inboxId === oldRoute.params.inboxId) return;

    // reset filters if navigating away from the inbox
    // we don't want to save to query here to avoid messing with the new page's query params
    dontSaveToQuery(() => {
      filterStatusStrings.value = [];
      filterTagsIds.value = [];
      filterUserIds.value = [];
      filterSender.value = null;
      filterBuildingProject.value = null;
      filterBuildingProjectId.value = null;
      filterCustomer.value = null;
      filterShortCodeId.value = null;
    });
  });

  function getApiQuery(): InquiryQuery {
    return {
      text: searchText.value,
      filename: searchFilename.value,
      status: getApiStatusFilter(filterStatusStrings.value).join(",") || null,
      tags: filterTagsIds.value.map((t) => t.toString()).join(",") || null,
      sender: filterSender.value || null,
      buildingProject: filterBuildingProject.value || null,
      assignedUserId: getApiAssignedUserIdFilter(filterUserIds.value),
      pageSize: pageSize.value || 10,
      ordering: getOrdering(sortBy.value, descending.value),
      pageResultCount: "true",
      shortCodeId: deleteNonNumbers(filterShortCodeId.value) || null,
      buildingProjectId: filterBuildingProjectId.value || null,
      customer: filterCustomer.value || null,
    };
  }

  function saveToQuery() {
    const newQuery: Record<string, string | null> = {
      text: searchText.value,
      filename: searchFilename.value,
      status: filterStatusStrings.value.join(","),
      tags: tagIdsToString(filterTagsIds.value),
      sender: filterSender.value,
      assignedUserId: userIdsToString(filterUserIds.value),
      pageSize: pageSize.value?.toString() || null,
      page: page.value?.toString() || null,
      sortBy: sortBy.value,
      descending: descending.value?.toString() || null,
      shortCode: filterShortCodeId.value,
      buildingProjectId: filterBuildingProjectId.value,
      customer: filterCustomer.value,
    };

    router.replace({ query: newQuery });
  }

  const { ignoreUpdates: dontSaveToQuery } = watchIgnorable(
    () => [
      searchText.value,
      searchFilename.value,
      filterStatusStrings.value,
      filterUserIds.value,
      filterSender.value,
      filterBuildingProject.value,
      filterTagsIds.value,
      pageSize.value,
      page.value,
      sortBy.value,
      descending.value,
      filterShortCodeId.value,
      filterBuildingProjectId.value,
      filterCustomer.value,
    ],
    saveToQuery,
    { deep: true }
  );

  return {
    searchText,
    searchFilename,
    filterStatusStrings,
    filterSender,
    filterBuildingProject,
    filterTagsIds,
    filterUserIds,
    filterShortCodeId,
    filterBuildingProjectId,
    filterCustomer,
    pageSize,
    page,
    sortBy,
    descending,
    getApiQuery,
  };
});

function getApiStatusFilter(filterStatusStrings: string[]): string[] {
  const { availableInquiryStatuses: availableLeadStatuses } = storeToRefs(
    useCurrentInboxStore()
  );

  if (filterStatusStrings.length === 0) {
    const nonUniqueStatuses = availableLeadStatuses.value.map(
      (status) => status.originalStatus
    );
    return Array.from(new Set(nonUniqueStatuses));
  }

  return availableLeadStatuses.value
    .map((status) => status.name)
    .filter((statusName) => filterStatusStrings.includes(statusName));
}

function getApiAssignedUserIdFilter(filterUserIds: (number | null)[]): string {
  const { inboxView } = useCurrentInboxStore();

  if (inboxView !== "mine") return userIdsToString(filterUserIds);

  // View is "assigned to me", so we should filter by the current user's id
  const { user } = useCurrentUserStore();
  if (!user) return userIdsToString(filterUserIds);
  return user.id.toString();
}

function noop<T>(value: T): T {
  return value;
}

function initRef<T>(
  route: RouteLocationNormalized,
  name: string,
  convertFromString: (value: string | null) => T,
  defaultValue: T
): ShallowRef<T> {
  const queryValue = route.query[name];
  const singleQueryValue = typeof queryValue === "string" ? queryValue : null;
  const valueFromRoute = convertFromString(singleQueryValue);
  const initialValue = valueFromRoute === null ? defaultValue : valueFromRoute;
  return shallowRef<T>(initialValue);
}

function initInquiryStatusesRef(
  route: RouteLocationNormalized,
  name: string
): ShallowRef<string[]> {
  const queryValue = route.query[name];
  const singleQueryValue = typeof queryValue === "string" ? queryValue : null;
  const initialValue = arrayFromSearchString(singleQueryValue);
  return shallowRef<string[]>(initialValue);
}

function initUserIdsRef(
  route: RouteLocationNormalized,
  name: string
): ShallowRef<(number | null)[]> {
  const queryValue = route.query[name];
  const singleQueryValue = typeof queryValue === "string" ? queryValue : null;
  const initialValue = userIdsFromString(singleQueryValue);
  return shallowRef<(number | null)[]>(initialValue);
}

function initTagIdsRef(
  route: RouteLocationNormalized,
  name: string
): ShallowRef<number[]> {
  const queryValue = route.query[name];
  const singleQueryValue = typeof queryValue === "string" ? queryValue : null;
  const initialValue = tagIdsFromString(singleQueryValue);
  return shallowRef<number[]>(initialValue);
}

function arrayFromSearchString(value: string | null): string[] {
  return (value?.split(",").filter((s) => s.length) as string[]) || [];
}

function userIdsFromString(value: string | null): (number | null)[] {
  if (!value || !value.length) return [];
  return value.split(",").map((v) => (v === "null" ? null : parseInt(v)));
}

function userIdsToString(value: (number | null)[]): string {
  return value.map((v) => (v === null ? "null" : v.toString())).join(",");
}

function tagIdsFromString(value: string | null): number[] {
  if (!value || !value.length) return [];
  return value.split(",").map((s) => parseInt(s));
}

function tagIdsToString(value: number[]): string {
  return value.map((v) => v.toString()).join(",");
}

function parseBoolean(value: string | null): boolean | null {
  if (value?.toLowerCase() === "true") {
    return true;
  }
  if (value?.toLowerCase() === "false") {
    return false;
  }
  return null;
}

function parseNumber(value: string | null | undefined): number | null {
  if (value === null || value === undefined) return null;
  const parsed = parseInt(value);
  if (!isNaN(parsed)) {
    return parsed;
  }
  return null;
}

function parseNumberWithDefault(defaultValue: number) {
  return (value: string | null | undefined) => {
    const parsed = parseNumber(value);
    return parsed ?? defaultValue;
  };
}

function getOrdering(
  sortBy: string | null,
  descending: boolean | null
): string | null {
  if (!sortBy) return null;

  const snakeCased = camelToSnakeCase(sortBy);
  return descending ? `-${snakeCased}` : snakeCased;
}

function deleteNonNumbers(value: string | null): string | null {
  if (!value || !value.length) return null;
  return value.replace(/[^0-9]/gi, "");
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
