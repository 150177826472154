/** This store hold information about the current app user */
import {
  deleteCurrentUser,
  retrieveCurrentUserInfo,
  updateCurrentUserInfo,
  updateCurrentUserSettings,
} from "@/api/user";
import { useRouteParams } from "@/composables/useRouteParams";
import type { InboxRole, OrganizationRole } from "@/types/role";
import type { User, CurrentUser } from "@/types/user";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import * as Sentry from "@sentry/browser";
import type { UserSettings } from "@/types/userSettings";

export const useCurrentUserStore = defineStore("user", () => {
  const user = ref<CurrentUser | null>(null);

  const userSettings = ref<Partial<UserSettings>>({});

  const organizationRoles = computed(
    () =>
      (user.value?.roles.filter(
        (role) => role.objectModel === "organization",
      ) as OrganizationRole[]) ?? [],
  );
  const inboxRoles = computed(
    () =>
      (user.value?.roles.filter(
        (role) => role.objectModel === "inbox",
      ) as InboxRole[]) ?? [],
  );

  const { organizationId } = useRouteParams();

  const isCurrentOrganizationAdmin = computed(() => {
    return organizationRoles.value.some(
      (role) =>
        role.objectId === organizationId.value &&
        role.role === "organization_admin",
    );
  });

  const loadUser = async () => {
    let data;

    try {
      data = await retrieveCurrentUserInfo();
    } catch (error: any) {
      if (error.name === "AxiosError" && error.response?.status === 401) {
        // Ignore error 401 due to the user not being logged in
        return;
      }
      throw error;
    }

    user.value = data;
    userSettings.value = data.settings;

    Sentry.setUser({
      id: user.value.id,
      email: user.value.email,
    });
  };

  const clear = () => {
    user.value = null;
  };

  const updateUser = async (changes: Partial<User>) => {
    if (user.value) Object.assign(user.value, changes);
    const apiUser = await updateCurrentUserInfo(changes);
    user.value = apiUser;
  };

  const updateUserSettings = async (changes: Partial<UserSettings>) => {
    if (userSettings.value) Object.assign(userSettings.value, changes);
    const apiUserSettings = await updateCurrentUserSettings(changes);
    userSettings.value = apiUserSettings;
  };

  const deleteUser = async () => {
    await deleteCurrentUser();
    clear();
  };

  return {
    user,
    organizationRoles,
    inboxRoles,
    isCurrentOrganizationAdmin,
    loadUser,
    clear,
    updateUser,
    updateUserSettings,
    deleteUser,
    userSettings,
  };
});
