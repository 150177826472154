<template>
  <div class="login-form-container">
    <q-form @submit="resetPassword">
      <q-input
        dense
        outlined
        bg-color="white"
        :model-value="email"
        @update:model-value="$emit('update:email', $event!.toString())"
        type="email"
        lazy-rules
        :rules="[validateNotEmpty, validateEmail]"
        :label="$t('Email')"
        for="login-box-email"
        :disable="isLoading"
      >
        <template v-slot:prepend>
          <q-icon name="sym_r_email" />
        </template>
      </q-input>
      <q-banner
        rounded
        class="bg-negative text-white q-mb-md"
        v-if="passwordResetFailed"
      >
        {{ $t("Password reset failed. Please check the email address.") }}
        <template v-slot:action>
          <q-btn
            flat
            color="white"
            :label="$t('Dismiss')"
            @click="passwordResetFailed = false"
          />
        </template>
      </q-banner>
      <q-banner
        rounded
        class="bg-positive text-white q-mb-md"
        v-if="passwordResetSent"
      >
        {{
          $t(
            "Password reset instructions have been sent to your email address.",
          )
        }}
      </q-banner>
      <q-btn
        v-if="!passwordResetSent"
        unelevated
        color="neutral-3"
        text-color="neutral-10"
        type="submit"
        class="full-width q-mt-xs"
        :label="$t('Reset password')"
        :disable="isLoading"
      />
    </q-form>
    <back-to-login-link @click="$emit('backToLogin')" />
  </div>
</template>

<script setup lang="ts">
import { requestPasswordReset as apiResetPassword } from "@/api/auth";
import { getCsrfCookie } from "@/api/client/csrf";
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";
import type { QForm } from "quasar";
import { ref } from "vue";
import BackToLoginLink from "./BackToLoginLink.vue";
const props = defineProps<{ email: string }>();

defineEmits<{
  backToLogin: [];
  "update:email": [value: string];
}>();

const passwordResetSent = ref<boolean>(false);
const passwordResetFailed = ref<boolean>(false);
const isLoading = ref<boolean>(false);

// Make sure CSRF cookie is set before logging in
getCsrfCookie();

async function resetPassword() {
  isLoading.value = true;
  try {
    await apiResetPassword(props.email);
    passwordResetSent.value = true;
  } catch (e) {
    passwordResetFailed.value = true;
  } finally {
    isLoading.value = false;
  }
}

const { validateNotEmpty, validateEmail } = useFormValidationRules();
</script>

<style lang="scss">
.login-form-container {
  width: 25rem;
  max-width: 100%;
}
</style>
@/api/client/csrf
