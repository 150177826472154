<template>
  <q-card flat bordered v-if="inquiry?.hasQuotation">
    <q-list dense separator>
      <q-item
        clickable
        v-ripple
        v-for="fileFormat in Object.values(InquiryFileFormat)"
        :key="fileFormat as string"
        @click.stop.prevent="downloadQuotation(inquiry.id, fileFormat)"
      >
        <q-item-section>
          <q-item-label class="ellipsis">
            <q-icon
              :name="icons[fileFormat]"
              class="q-mr-sm"
              color="neutral-8"
            />
            {{ inquiry.shortCode }} - {{ inquiry.buildingProject }}.{{
              fileFormat.toLowerCase()
            }}
          </q-item-label>
        </q-item-section>
        <q-item-section side right>
          <div class="button-spacer" />
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>
</template>

<script setup lang="ts">
import { downloadQuotation } from "@/api/draftQuotation";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { InquiryFileFormat } from "@/types/inquiryFile";
import { storeToRefs } from "pinia";

const { inquiry } = storeToRefs(useCurrentInquiryStore());

const icons = {
  [InquiryFileFormat.PDF]: "sym_r_description",
  [InquiryFileFormat.P94]: "sym_r_code",
  [InquiryFileFormat.X94]: "sym_r_code",
};
</script>

<style scoped>
.button-spacer {
  width: 25.15px;
}
</style>
