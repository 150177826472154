import { client } from "@/api/client/client";
import type { CustomStatus } from "@/types/customStatus";

export async function listCustomStatuses(
  inboxId: number,
): Promise<CustomStatus[]> {
  const result = await client.get<CustomStatus[]>(
    `/inboxes/${inboxId}/custom_statuses/`,
  );
  return result.data;
}

export async function createCustomStatus(
  inboxId: number,
  customStatus: Omit<CustomStatus, "id">,
): Promise<CustomStatus> {
  const result = await client.post<CustomStatus>(
    `/inboxes/${inboxId}/custom_statuses/`,
    customStatus,
  );
  return result.data;
}

export async function deleteCustomStatus(
  inboxId: number,
  customStatusId: number,
): Promise<void> {
  await client.delete(`/inboxes/${inboxId}/custom_statuses/${customStatusId}/`);
}

export async function updateCustomStatus(
  inboxId: number,
  customStatusId: number,
  customStatus: Omit<CustomStatus, "id">,
): Promise<CustomStatus> {
  const result = await client.patch<CustomStatus>(
    `/inboxes/${inboxId}/custom_statuses/${customStatusId}/`,
    customStatus,
  );
  return result.data;
}

export async function moveCustomStatus(
  inboxId: number,
  fromIdx: number,
  toIdx: number,
): Promise<void> {
  await client.post(`/inboxes/${inboxId}/custom_statuses/reorder/`, {
    fromIdx,
    toIdx,
  });
}

interface CopyCustomStatusesFromOtherInboxResponse {
  ignoredCustomStatuses: string[];
  createdCustomStatuses: string[];
}

export async function copyCustomStatusesFromOtherInbox(
  sourceInboxId: number,
  targetInboxId: number,
): Promise<CopyCustomStatusesFromOtherInboxResponse> {
  const response = await client.post<CopyCustomStatusesFromOtherInboxResponse>(
    `/inboxes/${targetInboxId}/custom_statuses/copy_from_other_inbox/`,
    { sourceInboxId },
  );
  return response.data;
}
