<template>
  <q-btn
    icon="sym_r_download"
    color="neutral-7"
    flat
    dense
    :disable="isDisabled"
  >
    <q-menu>
      <q-list dense>
        <q-item clickable v-ripple>
          <q-item-section>
            {{ $t("downloadInquiryButton.excel") }}
          </q-item-section>
          <q-item-section avatar>
            <q-icon color="neutral-7" name="sym_r_arrow_right" />
          </q-item-section>
          <q-menu anchor="top right" self="top left">
            <q-list dense>
              <q-item
                clickable
                v-ripple
                v-close-popup
                @click="downloadInquiryInFormat('offer_excel')"
              >
                <q-item-section>
                  {{ $t("downloadInquiryButton.excelOfferFormat") }}
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-ripple
                v-close-popup
                @click="downloadInquiryInFormat('boq_excel')"
              >
                <q-item-section>
                  {{ $t("downloadInquiryButton.excelBOQFormat") }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-item>
        <q-item clickable v-ripple>
          <q-item-section>
            {{ $t("downloadInquiryButton.gaeb") }}
          </q-item-section>
          <q-item-section avatar>
            <q-icon color="neutral-7" name="sym_r_arrow_right" />
          </q-item-section>
          <q-menu anchor="top right" self="top left">
            <q-list dense>
              <q-item
                clickable
                v-ripple
                v-close-popup
                @click="downloadDraftQuotationInFormat(InquiryFileFormat.P94)"
              >
                <q-item-section>
                  {{ $t("downloadInquiryButton.formats.gaebP94") }}
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-ripple
                v-close-popup
                @click="downloadDraftQuotationInFormat(InquiryFileFormat.X94)"
              >
                <q-item-section>
                  {{ $t("downloadInquiryButton.formats.gaebX94") }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-item>
        <q-item
          v-for="format in organization?.showExtraDownloadFormats"
          :key="format"
          clickable
          v-ripple
          v-close-popup
          @click="downloadInquiryInFormat(format)"
        >
          <q-item-section>
            {{ $t(`downloadInquiryButton.formats.${format}`) }}
          </q-item-section>
        </q-item>
        <q-item
          v-if="organization?.useQuotationCreation"
          clickable
          v-ripple
          v-close-popup
          @click="downloadDraftQuotationInFormat(InquiryFileFormat.PDF)"
        >
          <q-item-section>
            {{ $t("downloadInquiryButton.finalQuotationPdfFormat") }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <q-tooltip> {{ $t("downloadInquiryButton.tooltip") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import * as draftQuotationApi from "@/api/draftQuotation";
import * as inquiryApi from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { InquiryFileFormat } from "@/types/inquiryFile";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { organizationId, inboxId, inquiryId } = useRouteParams();

const isDisabled = computed(() =>
  [organizationId.value, inboxId.value, inquiryId.value].some((id) => isNaN(id))
);

const { organization } = storeToRefs(useCurrentOrganizationStore());

async function downloadInquiryInFormat(format: string) {
  if (isDisabled.value) return;
  await inquiryApi.downloadInquiryInFormat(
    organizationId.value,
    inboxId.value,
    inquiryId.value,
    format
  );
}

async function downloadDraftQuotationInFormat(format: InquiryFileFormat) {
  await draftQuotationApi.downloadQuotation(inquiryId.value, format);
}
</script>
