import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { Tag } from "@/types/tag";
import type { ReactiveSet } from "@/utils/reactiveSet";
import { storeToRefs } from "pinia";
import { computed, inject, provide, type ComputedRef } from "vue";

const tagsSymbol = Symbol("positionsTableTags");

export interface PositionsTableTags {
    selectedTags: ComputedRef<Tag[]>;
    partiallySelectedTags: ComputedRef<Tag[]>;
    addTagsToSelectedOpgs: (tags: Tag[]) => Promise<void>;
    removeTagsFromSelectedOpgs: (tags: Tag[]) => Promise<void>;
}

export function provideTags(selectedGroupIds: ReactiveSet<number>): PositionsTableTags {
    const store = useCurrentOfferPositionGroupsStore();
    const { visibleOfferPositionGroups } = storeToRefs(store);

    const selectedGroups = computed(() => {
        return visibleOfferPositionGroups.value?.filter((group) => selectedGroupIds.has(group.id)) ?? [];
    });

    const availableTags = computed(() => {
        const seenIds = new Set<number>();
        const tags = [];
        for (const group of selectedGroups.value ?? []) {
            for (const tag of group.tags) {
                if (!seenIds.has(tag.id)) {
                    seenIds.add(tag.id);
                    tags.push(tag);
                }
            }
        }
        return tags;
    });

    const selectedTags = computed(() => {
        return availableTags.value.filter((tag) => selectedGroups.value.every((group) => group.tags.some((t) => t.id === tag.id)));
    });

    const partiallySelectedTags = computed(() => {
        return availableTags.value.filter((tag) => !selectedGroups.value.every((group) => group.tags.some((t) => t.id === tag.id)));
    });

    const addTagsToSelectedOpgs = async (tags: Tag[]) => {
        await store.addTagsToMultipleOpgs(selectedGroupIds.values, tags);
    };

    const removeTagsFromSelectedOpgs = async (tags: Tag[]) => {
        await store.removeTagsFromMultipleOpgs(selectedGroupIds.values, tags);
    };

    const tags = {
        selectedTags,
        partiallySelectedTags,
        addTagsToSelectedOpgs,
        removeTagsFromSelectedOpgs,
    };

    provide(tagsSymbol, tags);
    return tags;
}

export const useTags = () => {
    const tags = inject<PositionsTableTags>(tagsSymbol);
    if (!tags) {
        throw new Error("Tags not provided");
    }
    return tags;
};
