<template>
  <q-btn dense flat @click="openManufacturerSelectDialog">
    <div class="full-width row items-center justify-start gap-x-sm">
      <q-chip
        v-for="manufacturer in preferredManufacturers"
        class="q-ma-none"
        :key="manufacturer.id"
        :label="manufacturer.name"
        size="sm"
      />
    </div>
  </q-btn>
</template>

<script setup lang="ts">
import ManufacturerSelectDialog from "@/components/ManufacturerSelectDialog.vue";
import type { Customer } from "@/types/customer.ts";
import type { Inbox } from "@/types/inbox.ts";
import type { Inquiry } from "@/types/inquiry.ts";
import type { Manufacturer } from "@/types/manufacturer.ts";
import { useQuasar } from "quasar";
import { computed } from "vue";

const props = defineProps<{
  objectWithPreferredManufacturers: Inquiry | Inbox | Customer;
  objectType: "INQUIRY" | "INBOX" | "CUSTOMER";
}>();

const emit = defineEmits<{
  "update:preferred-manufacturers": [Manufacturer[]];
}>();

const q = useQuasar();

const preferredManufacturers = computed(() => {
  const manufacturers =
    props.objectWithPreferredManufacturers.preferredManufacturers ?? [];
  manufacturers.sort((a, b) => a.name.localeCompare(b.name));
  return manufacturers;
});

function openManufacturerSelectDialog() {
  q.dialog({
    component: ManufacturerSelectDialog,
    componentProps: {
      preferredManufacturers: preferredManufacturers.value,
      objectWithPreferredManufacturers: props.objectWithPreferredManufacturers,
      objectType: props.objectType,
    },
  }).onOk((manufacturers: Manufacturer[]) => {
    emit("update:preferred-manufacturers", manufacturers);
  });
}
</script>
