<template>
  <q-page class="login-page fit column items-center justify-center gap-lg">
    <img class="logo" src="@/assets/logo/kinisto-logo.svg" />
    <div class="login-form-container">
      <password-reset-form
        v-if="action === 'resetPassword'"
        v-model:email="email"
        @back-to-login="action = 'login'"
      />
      <reauth-form
        v-else-if="action === 'reauth'"
        :on-submit="handleReauthSubmit"
      />
      <login-mfa-form v-else-if="action === 'mfa'" />
      <sso-login-form
        v-else-if="action === 'loginWithSso'"
        v-model:email="email"
        @back-to-login="action = 'login'"
        @login-with-sso="handleLoginWithSso"
      />
      <sso-provider-select
        v-else-if="action === 'chooseSsoProvider'"
        :sso-providers="ssoProviders"
        @select-sso-provider="handleSelectSsoProvider"
        @back-to-login="action = 'login'"
      />
      <login-form
        v-else
        v-model:email="email"
        :on-submit="handleLoginFormSubmit"
        @reset-password="action = 'resetPassword'"
        @use-sso="action = 'loginWithSso'"
      />
    </div>
  </q-page>
</template>

<script setup lang="ts">
import {
  AlreadyLoggedIn,
  login,
  loginWithSso,
  MfaRequiredForLogin,
  reauthenticate,
} from "@/api/auth";
import LoginForm from "@/components/LoginPage/LoginForm.vue";
import LoginMfaForm from "@/components/LoginPage/LoginMfaForm.vue";
import PasswordResetForm from "@/components/LoginPage/PasswordResetForm.vue";
import ReauthForm from "@/components/LoginPage/ReauthForm.vue";
import SsoLoginForm from "@/components/LoginPage/SsoLoginForm.vue";
import SsoProviderSelect from "@/components/LoginPage/SsoProviderSelect.vue";
import type { SsoProvider } from "@/types/authn";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const action = ref<
  | "login"
  | "resetPassword"
  | "loginWithSso"
  | "chooseSsoProvider"
  | "reauth"
  | "mfa"
>("login");
const email = ref("");
const ssoProviders = ref<SsoProvider[]>([]);

if (router.currentRoute.value.query.reauthenticate) {
  action.value = "reauth";
}

function getRedirectUrl() {
  const redirectUrl = router.currentRoute.value.query.redirect;
  if (redirectUrl && redirectUrl.length) {
    return redirectUrl as string;
  }
  const nextUrl = router.currentRoute.value.query.next;
  if (nextUrl && nextUrl.length) {
    return nextUrl as string;
  }
  return "/";
}

function isVueRouterRedirect() {
  const vueRouterRedirect = router.currentRoute.value.query.redirect;
  if (vueRouterRedirect && vueRouterRedirect.length) {
    return true;
  }
  return false;
}

function handleRedirect() {
  const redirectUrl = getRedirectUrl();

  if (isVueRouterRedirect()) {
    router.push(redirectUrl as string);
  } else if (redirectUrl) {
    // Django uses the "next" query parameter for redirects, e.g. from the admin login
    // In these cases we need a proper redirect, not a vue router redirect
    if (redirectUrl.startsWith("/")) {
      window.location.href = redirectUrl;
    } else {
      console.warn("Blocked potential open redirect to:", redirectUrl);
      router.push("/");
    }
  }
}

async function handleLoginFormSubmit(
  email: string,
  password: string,
): Promise<boolean> {
  try {
    const result = await login(email, password);
    if (!result) {
      return false;
    }
    handleRedirect();
    return true;
  } catch (e: any) {
    if (e instanceof MfaRequiredForLogin) {
      action.value = "mfa";
      return false;
    } else if (e instanceof AlreadyLoggedIn) {
      router.push("/");
      return true;
    }
    return false;
  }
}

async function handleReauthSubmit(password: string): Promise<boolean> {
  const result = await reauthenticate(password);
  if (!result) {
    return false;
  }
  handleRedirect();
  return true;
}

function getSsoCallbackUrl() {
  // Need to combine the current URL with the redirect pathname because in local development,
  // the redirect comes from localhost:8000, not the frontend
  const currentUrl = new URL(window.location.href);
  let redirectUrl = getRedirectUrl();
  if (!redirectUrl.startsWith("/")) {
    redirectUrl = new URL(redirectUrl).pathname;
  }
  const callbackUrl = new URL(currentUrl.origin + redirectUrl);
  return callbackUrl.toString();
}

async function handleLoginWithSso(providers: SsoProvider[]) {
  if (providers.length === 1) {
    await loginWithSso(providers[0].providerId, getSsoCallbackUrl());
  } else {
    ssoProviders.value = providers;
    action.value = "chooseSsoProvider";
  }
}

async function handleSelectSsoProvider(provider: SsoProvider) {
  await loginWithSso(provider.providerId, getSsoCallbackUrl());
}
</script>

<style scoped lang="scss">
.login-page {
  overflow: auto;
}

.logo {
  height: 2rem;
}

.login-form-container {
  width: 20rem;
  max-width: 98%;
}
</style>
