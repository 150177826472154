import { MAX_NUM_CUSTOMERS_SHOWN_IN_DROPDOWN } from "@/config/constants";
import type { Customer } from "@/types/customer";
import { client } from "./client/client";
import type { Paginated } from "@/types/pagination";
import { parseCursor } from "./utils";
import type { Manufacturer } from "@/types/manufacturer.ts";


export async function findCustomers(
  organizationId: number,
  search: string | null,
  limit: number = MAX_NUM_CUSTOMERS_SHOWN_IN_DROPDOWN
) {
  const response = await client.get<{ results: Customer[] }>(
    `/organizations/${organizationId}/customers/`,
    { params: { search, page_size: limit } }
  );
  return response.data.results.slice(0, limit);
}

export async function listCustomers(
  organizationId: number,
  search: string | null,
  cursor: string | null,
  pageSize: number
) {
  const response = await client.get<Paginated<Customer>>(
    `/organizations/${organizationId}/customers/`,
    { params: { search, cursor, page_size: pageSize, page_result_count: true } }
  );
  return {
    ...response.data,
    next: parseCursor(response.data.next),
    previous: parseCursor(response.data.previous),
  };
}



export async function updateCustomerPreferredManufacturers(
  organizationId: number,
  customerId: number,
  preferredManufacturers: Manufacturer[],
): Promise<Customer> {
  const response = await client.patch(
    `/organizations/${organizationId}/customers/${customerId}/`,
    {
      preferredManufacturerIds: preferredManufacturers.map((m) => m.id),
    },
  );
  return response.data;
}


