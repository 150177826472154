<template>
  <q-btn flat dense size="sm" icon="sym_r_filter_list" class="filter-tags-button">
    <tags-circles v-if="filterTags.length > 0" :tags="filterTags" />
    <div v-else>{{ $t("inquiryPositionsPage.positionsTable.filterTags") }}</div>
    <multi-tag-select-menu
      :selected-tags="filterTags"
      @select="handleSelect"
      @unselect="handleUnselect"
    />
  </q-btn>
</template>

<script setup lang="ts">
import MultiTagSelectMenu from "@/components/Tags/MultiTagSelectMenu.vue";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import TagsCircles from "../Tags/TagsCircles.vue";
import type { Tag } from "@/types/tag";
import { storeToRefs } from "pinia";

const opgStore = useCurrentOfferPositionGroupsStore();
const { filterTags } = storeToRefs(opgStore);

const handleSelect = (tag: Tag) => {
  filterTags.value.push(tag);
};

const handleUnselect = (tag: Tag) => {
  filterTags.value = filterTags.value.filter((t) => t.id !== tag.id);
};
</script>

<style scoped>
.filter-tags-button {
  font-weight: inherit;
  font-size: inherit !important;
}
</style>
