<template>
  <q-input
    dense
    outlined
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    :label="label ?? $t('loginMfaForm.enterMfaCode')"
    mask="######"
    lazy-rules
    :rules="[validateMfaCode]"
  />
</template>

<script setup lang="ts">
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";

defineProps<{
  modelValue: string;
  label?: string;
}>();

defineEmits<{
  (e: "update:modelValue", value: string | number | null): void;
}>();

const { validateMfaCode } = useFormValidationRules();
</script>
