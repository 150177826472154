<template>
  <div class="flex row items-center no-wrap gap-x-md text-xs">
    <div class="flex row items-center gap-x-md">
      <span> {{ rowsPerPageLabel }}</span>
      <q-select
        :model-value="pageSize"
        @update:model-value="$emit('update:pageSize', $event)"
        :options="[10, 20, 50, 100]"
        dense
        borderless
        :disable="disabled"
      />
    </div>
    <div class="row no-wrap items-center">
      <q-btn
        flat
        dense
        icon="sym_r_first_page"
        @click="$emit('update:page', 1)"
        :disable="page === 1 || disabled"
      />
      <q-btn
        flat
        dense
        icon="sym_r_chevron_left"
        @click="$emit('update:page', page - 1)"
        :disable="!hasPrevious || disabled"
      />
    <div v-if="count">{{ $t("inboxPage.pagination.count", count) }}</div>
      <q-btn
        flat
        dense
        icon="sym_r_chevron_right"
        @click="$emit('update:page', page + 1)"
        :disable="!hasNext || disabled"
      />
      <q-btn
        flat
        dense
        icon="sym_r_last_page"
        @click="$emit('update:page', maxPage)"
        :disable="page === maxPage || disabled"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  pageSize: number;
  page: number;
  totalItems: number;
  rowsPerPageLabel: string;
  disabled?: boolean;
}>();

defineEmits<{
  "update:pageSize": [pageSize: number];
  "update:page": [page: number];
}>();

const count = computed(() => {
  if (!props.pageSize || !props.page) return null;
  return {
    from: Math.min(props.totalItems, (props.page - 1) * props.pageSize + 1),
    to: Math.min(props.page * props.pageSize, props.totalItems),
    total: props.totalItems,
  };
});

const maxPage = computed(() => {
  if (!props.pageSize) return 1;
  return Math.max(1, Math.ceil(props.totalItems / props.pageSize));
});

const hasNext = computed(() => {
  return props.page < maxPage.value;
});

const hasPrevious = computed(() => {
  return props.page > 1;
});
</script>
