<template>
  <q-btn :icon="icons.filter" :label="$t('Filter')" class="font-weight-inherit">
    <q-menu no-route-dismiss :offset="[0, 2]">
      <filter-item
        :active="(filterShortCodeId && filterShortCodeId.length > 0) || false"
        :icon="icons.inquiry.shortCode"
        :label="$t('inquiry.shortCode')"
      >
        <q-item dense>
          <q-input
            borderless
            dense
            :placeholder="$t('inboxPage.filterMenuButton.searchShortCodeId')"
            v-model="filterShortCodeString"
            debounce="300"
            autofocus
          />
        </q-item>
      </filter-item>
      <filter-item
        :active="filterStatusStrings.length > 0"
        :icon="icons.inquiry.status"
        :label="$t('Status')"
        align-menu="top"
      >
        <q-item
          dense
          clickable
          v-ripple
          v-for="status in availableInquiryStatuses"
          :key="status.name"
          @click="toggleStatusOption(status.name)"
          :class="{
            'bg-neutral-3': filterStatusStrings.includes(status.name),
          }"
        >
          <q-item-section>
            <q-item-label class="status-option-label">
              <q-checkbox
                dense
                size="sm"
                class="q-mr-sm"
                :model-value="filterStatusStrings.includes(status.name)"
                @click="toggleStatusOption(status.name)"
              />
              <status-chip :status="status" />
            </q-item-label>
          </q-item-section>
        </q-item>
      </filter-item>
      <filter-item
        :active="(filterSender && filterSender?.length > 0) || false"
        :icon="icons.inquiry.sender"
        :label="$t('email.sender')"
      >
        <q-item dense>
          <q-input
            borderless
            dense
            :placeholder="$t('inboxPage.filterMenuButton.searchSender')"
            v-model="filterSenderString"
            debounce="300"
            autofocus
          />
        </q-item>
      </filter-item>
      <filter-item
        :active="filterUserIds?.length > 0"
        :icon="icons.user.main"
        :label="$t('User')"
      >
        <template #menu>
          <multi-user-select-menu
            anchor="top right"
            self="top left"
            no-route-dismiss
            v-model:selected-users="selectedUsers"
          />
        </template>
      </filter-item>
      <filter-item
        :active="filterTagsIds?.length > 0"
        :icon="icons.inquiry.tag"
        :label="$t('inquiry.tag')"
      >
        <template #menu>
          <multi-tag-select-menu
            anchor="top right"
            self="top left"
            no-route-dismiss
            v-model:selected-tags="selectedTags"
          />
        </template>
      </filter-item>
      <filter-item
        :active="
          (filterBuildingProject && filterBuildingProject.length > 0) || false
        "
        :icon="icons.inquiry.buildingProject"
        :label="$t('inquiry.buildingProject')"
      >
        <q-item dense>
          <q-input
            borderless
            dense
            :placeholder="
              $t('inboxPage.filterMenuButton.searchBuildingProject')
            "
            v-model="filterBuildingProjectString"
            debounce="300"
            autofocus
          />
        </q-item>
      </filter-item>
      <filter-item
        v-if="organization?.showBuildingProjectId"
        :active="
          (filterBuildingProjectId && filterBuildingProjectId.length > 0) ||
          false
        "
        :icon="icons.inquiry.buildingProjectId"
        :label="$t('inquiry.buildingProjectId')"
      >
        <q-item dense>
          <q-input
            borderless
            dense
            :placeholder="
              $t('inboxPage.filterMenuButton.searchBuildingProjectId')
            "
            v-model="filterBuildingProjectIdString"
            debounce="300"
            autofocus
          />
        </q-item>
      </filter-item>
      <filter-item
        v-if="organization?.showCustomerId || organization?.useCustomerData"
        :active="(filterCustomer && filterCustomer.length > 0) || false"
        :icon="icons.customer"
        :label="$t('customer.label')"
      >
        <q-item dense>
          <q-input
            borderless
            dense
            :placeholder="$t('inboxPage.filterMenuButton.searchCustomer')"
            v-model="filterCustomerString"
            debounce="300"
            autofocus
          />
        </q-item>
      </filter-item>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import StatusChip from "@/components/Status/StatusChip.vue";
import MultiTagSelectMenu from "@/components/Tags/MultiTagSelectMenu.vue";
import icons from "@/config/icons";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInboxUsersStore } from "@/stores/currentInboxUsers";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { Tag } from "@/types/tag";
import type { UserOrEmptyUser } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import MultiUserSelectMenu from "../User/MultiUserSelectMenu.vue";
import FilterItem from "./FilterItem.vue";

const props = defineProps<{
  filterStatusStrings: string[];
  filterTagsIds: number[];
  filterUserIds: (number | null)[];
  filterSender: string | null;
  filterBuildingProject: string | null;
  filterShortCodeId: string | null;
  filterBuildingProjectId: string | null;
  filterCustomer: string | null;
}>();

const emit = defineEmits<{
  "update:filterStatusStrings": [statuses: string[]];
  "update:filterTagsIds": [tagIds: number[]];
  "update:filterUserIds": [userIds: (number | null)[]];
  "update:filterSender": [sender: string | null];
  "update:filterBuildingProject": [sender: string | null];
  "update:filterShortCodeId": [sender: string | null];
  "update:filterBuildingProjectId": [sender: string | null];
  "update:filterCustomer": [sender: string | null];
}>();

const { availableInquiryStatuses } = storeToRefs(useCurrentInboxStore());
const { usersWithEmptyUser } = storeToRefs(useCurrentInboxUsersStore());
const { inbox } = storeToRefs(useCurrentInboxStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());

const filterSenderString = computed({
  get: () => props.filterSender,
  set: (sender: string | null) => {
    emit("update:filterSender", sender);
  },
});

const filterBuildingProjectString = computed({
  get: () => props.filterBuildingProject,
  set: (buildingProject: string | null) => {
    emit("update:filterBuildingProject", buildingProject);
  },
});

const filterShortCodeString = computed({
  get: () => props.filterShortCodeId,
  set: (shortCode: string | null) => {
    emit("update:filterShortCodeId", shortCode);
  },
});

const filterBuildingProjectIdString = computed({
  get: () => props.filterBuildingProjectId,
  set: (buildingProjectId: string | null) => {
    emit("update:filterBuildingProjectId", buildingProjectId);
  },
});

const filterCustomerString = computed({
  get: () => props.filterCustomer,
  set: (customer: string | null) => {
    emit("update:filterCustomer", customer);
  },
});

function toggleStatusOption(status: string) {
  const newStatuses = props.filterStatusStrings.includes(status)
    ? props.filterStatusStrings.filter((s) => s !== status)
    : [...props.filterStatusStrings, status];
  emit("update:filterStatusStrings", newStatuses);
}

const selectedUsers = computed({
  get: () =>
    usersWithEmptyUser.value.filter((user) =>
      props.filterUserIds.includes(user.id),
    ),
  set: (users: UserOrEmptyUser[]) => {
    emit(
      "update:filterUserIds",
      users.map((user) => user.id),
    );
  },
});

const selectedTags = computed({
  get: () => {
    if (!inbox.value) return [];
    return inbox.value.availableTags.filter((tag) =>
      props.filterTagsIds.includes(tag.id),
    );
  },
  set: (tags: Tag[]) => {
    emit(
      "update:filterTagsIds",
      tags.map((tag) => tag.id),
    );
  },
});
</script>

<style scoped lang="scss">
.status-option-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>
