/** Global configuration of HTTP client for API access */
import { i18n } from "@/i18n";
import router from "@/router";
import { useCurrentUserStore } from "@/stores/currentUser";
import * as Sentry from "@sentry/browser";
import axios, { AxiosHeaders, type AxiosInstance } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { Notify } from "quasar";
import { CSRF_HEADER_NAME, getCsrfToken } from "./csrf";
import { trackInflightRequests } from "./inflightRequests";
import { pick } from "lodash";

const { t } = i18n.global;

function createClient(handleErrors: boolean) {
  const rawClient = axios.create({ baseURL: "/api" });

  // Add CSRF token to all requests
  rawClient.interceptors.request.use(
    function (config) {
      const token = getCsrfToken();
      if (token) {
        if (!config.headers) config.headers = new AxiosHeaders();
        config.headers[CSRF_HEADER_NAME] = token;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // Track inflight requests
  trackInflightRequests(rawClient);

  // Handle HTTP errors
  if (handleErrors) addErrorHandler(rawClient);

  return applyCaseMiddleware(rawClient);
}

function addErrorHandler(client: AxiosInstance) {
  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const ignoredErrors = error.config?.ignoreErrors || [];
      if (ignoredErrors.includes(error.response?.status))
        return Promise.reject(error);

      if (error.response?.status == 401) {
        resetLoginState();
      } else if (
        error.response?.status == 403 &&
        error.response?.data?.reason == "MFA_REQUIRED"
      ) {
        showAuthPolicyErrorNotification(t("authPolicy.error.mfaRequired"));
        resetLoginState();
      } else if (
        error.response?.status == 403 &&
        error.response?.data?.reason == "SSO_REQUIRED"
      ) {
        showAuthPolicyErrorNotification(
          t("authPolicy.error.ssoRequired", {
            allowedSsoProviderId:
              error.response?.data?.extra.allowedSsoProviderId,
          }),
        );
        resetLoginState();
      } else if (
        error.response?.status == 404 &&
        error.config?.method === "get"
      ) {
        // 404 errors for GET requests are handled by the component that initiated the request
      } else {
        const response = JSON.stringify(
          pick(error.response ?? {}, ["data", "status"]),
        );
        const request = JSON.stringify(
          pick(error.config ?? {}, ["data", "method"]),
        );
        const { url } = error.config ?? {};
        Sentry.captureException(error, {
          extra: { url, request, response },
        });
        showHttpErrorNotification(error);
      }
      return Promise.reject(error);
    },
  );
}

function resetLoginState() {
  const userStore = useCurrentUserStore();
  userStore.clear();

  const route = router.currentRoute.value;
  if (route.name === "login") return;

  router.push({ name: "login", query: { redirect: route.path } });
}
function showAuthPolicyErrorNotification(message: string) {
  Notify.create({
    message,
    type: "negative",
  });
}

export function showHttpErrorNotification(error: any) {
  const message = error.request
    ? error.request.status
      ? t("HTTP error", { status: error.request.status })
      : t("Connection timed out")
    : t("Request failed");

  Notify.create({
    caption: t("error"),
    message,
    type: "negative",
  });
}

export const client = createClient(true);
export const clientWithoutErrorNotifications = createClient(false);
