<template>
  <div
    class="q-pa-md row items-center justify-center"
    style="height: 100vh; width: 100vw"
  >
    <div v-if="user && !firstOrganizationId" class="text-center">
      <p>{{ $t("rootRedirect.noOrganization") }}</p>
    </div>
    <q-linear-progress
      v-else
      indeterminate
      color="primary"
      style="max-width: 400px"
    />
  </div>
</template>

<script setup lang="ts">
import { useCurrentUserStore } from "@/stores/currentUser";
import { storeToRefs } from "pinia";
import { computed, nextTick, watch } from "vue";
import { useRouter } from "vue-router";

const userStore = useCurrentUserStore();
const { user } = storeToRefs(userStore);
const router = useRouter();

const firstOrganizationId = computed(
  () => user.value?.memberOfOrganizationIds[0],
);
watch(firstOrganizationId, redirectToOrganization, { immediate: true });

async function redirectToOrganization() {
  if (!user.value) {
    await userStore.loadUser();
    return;
  }

  if (!firstOrganizationId.value || isNaN(firstOrganizationId.value)) {
    return;
  }

  await nextTick();

  router.replace({
    name: "organization",
    params: { organizationId: firstOrganizationId.value },
  });
}
</script>
