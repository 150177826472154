<template>
  <q-page class="fit" :style-fn="() => ''">
    <div
      v-if="is404"
      class="fit row items-center justify-center text-lg text-neutral-7"
    >
      {{ $t("inboxPage.notFound") }}
    </div>
    <template v-else>
      <header-bar>
        <div class="flex row no-wrap q-ml-lg gap-x-md">
          <sort-menu-button
            dense
            size="sm"
            :sort-by="sortBy"
            :descending="descending"
            @updateSort="updateSort"
          />
          <filter-menu-button
            dense
            size="sm"
            :filter-status-strings="filterStatusStrings"
            :filter-user-ids="filterUserIds"
            :filter-sender="filterSender"
            :filter-building-project="filterBuildingProject"
            :filter-tags-ids="filterTagsIds"
            :filter-short-code-id="filterShortCodeId"
            :filter-customer="filterCustomer"
            :filter-building-project-id="filterBuildingProjectId"
            @update:filter-status-strings="
              ($event) => (filterStatusStrings = $event)
            "
            @update:filter-user-ids="($event) => (filterUserIds = $event)"
            @update:filter-tags-ids="($event) => (filterTagsIds = $event)"
            @update:filter-sender="($event) => (filterSender = $event)"
            @update:filter-building-project="
              ($event) => (filterBuildingProject = $event)
            "
            @update:filter-short-code-id="
              ($event) => (filterShortCodeId = $event)
            "
            @update:filter-customer="($event) => (filterCustomer = $event)"
            @update:filter-building-project-id="
              ($event) => (filterBuildingProjectId = $event)
            "
          />
          <filter-chips
            :filter-status-strings="filterStatusStrings"
            :filter-user-ids="filterUserIds"
            :filter-sender="filterSender"
            :filter-building-project="filterBuildingProject"
            :filter-tags-ids="filterTagsIds"
            :filter-short-code-id="filterShortCodeId"
            :filter-customer="filterCustomer"
            :filter-building-project-id="filterBuildingProjectId"
            @update:filter-status-strings="
              ($event) => (filterStatusStrings = $event)
            "
            @update:filter-user-ids="($event) => (filterUserIds = $event)"
            @update:filter-tags-ids="($event) => (filterTagsIds = $event)"
            @update:filter-sender="($event) => (filterSender = $event)"
            @update:filter-building-project="
              ($event) => (filterBuildingProject = $event)
            "
            @update:filter-short-code-id="
              ($event) => (filterShortCodeId = $event)
            "
            @update:filter-customer="($event) => (filterCustomer = $event)"
            @update:filter-building-project-id="
              ($event) => (filterBuildingProjectId = $event)
            "
            class="gt-sm"
          />
        </div>
        <template #right>
          <q-btn
            flat
            dense
            icon="sym_r_cloud_upload"
            color="neutral-8"
            @click="showUploadDialog"
          >
            <q-tooltip>{{ $t("Upload") }}</q-tooltip>
          </q-btn>
        </template>
      </header-bar>
      <div class="page-below-header-bar">
        <component
          :is="fileDropzoneComponent"
          @drop="uploadDocuments"
          class="fit"
        >
          <inquiries-table
            class="fit"
            :is-loading="isLoading"
            :inquiries="inquiries"
            :rows-number="rowsNumber"
            :inbox="inbox"
            v-model:selected="selectedInquiries"
            @open="openInquiry"
            @update:assigned-to="assignInquiry"
            @update:status="updateInquiryStatus"
            @retry-analysis="retryAnalysisOfInquiryById"
          >
            <template #footer>
              <inquiries-actions-bar
                :is-loading="isLoading"
                :all-selected="selectedInquiries.length === inquiries?.length"
                :selected-inquiries="selectedInquiries"
                @move="moveSelectedInquiries"
                @delete="deleteSelectedInquiries"
                @assign="assignSelectedInquiries"
                @update:status="updateSelectedInquiriesStatus"
                @select-all="toggleSelectAll"
                @retry-analysis="retryAnalysisOfInquiryById($event[0])"
              />
              <q-space />
              <table-pagination
                v-model:page-size="pageSize"
                v-model:page="page"
                :total-items="rowsNumber"
                :disabled="false"
                :rows-per-page-label="$t('inboxPage.pagination.rowsPerPage')"
              />
            </template>
          </inquiries-table>
        </component>
      </div>
      <ajax-bar skip-hijack ref="ajaxBarEl" />
    </template>
  </q-page>
</template>

<script setup lang="ts">
import { listInquiries } from "@/api/inquiry";
import AjaxBar from "@/components/AjaxBar.vue";
import FileDropzone from "@/components/FileDropzone.vue";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import FilterChips from "@/components/InboxPage/FilterChips.vue";
import FilterMenuButton from "@/components/InboxPage/FilterMenuButton.vue";
import SortMenuButton from "@/components/InboxPage/SortMenuButton.vue";
import InquiriesActionsBar from "@/components/InquiriesActionsBar.vue";
import InquiriesTable from "@/components/InquiriesTable/InquiriesTable.vue";
import TablePagination from "@/components/TablePagination.vue";
import { useLeadUpload } from "@/composables/useLeadUpload";
import { useMultiInquiryActions } from "@/composables/useMultiInquiryActions";
import { usePolling } from "@/composables/usePolling";
import { useRequestCancellation } from "@/composables/useRequestCancellation";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { retryInquiryAnalysis } from "@/stores/currentInquiry";
import { useSearchFilterSortStore } from "@/stores/searchFilterSort";
import type { ShortInquiry } from "@/types/inquiry";
import { executeInNewTrace } from "@/utils/sentry";
import { watchIgnorable } from "@vueuse/core";
import { mapValues, omit } from "lodash";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch, type Ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const { inbox, is404 } = storeToRefs(useCurrentInboxStore());
const { organizationId, inboxId, inboxView } = useRouteParams();

const inquiries: Ref<ShortInquiry[] | undefined> = ref(undefined);
const selectedInquiriesIds = ref<number[]>([]);
const selectedInquiries = computed({
  get: () => {
    return (
      inquiries.value?.filter((i) =>
        selectedInquiriesIds.value.includes(i.id),
      ) || []
    );
  },
  set: (value: ShortInquiry[]) => {
    selectedInquiriesIds.value = value.map((i) => i.id);
  },
});

const rowsNumber = ref(0);

const fileDropzoneComponent = computed(() => {
  if (inbox.value?.documentUploadEnabled) {
    return FileDropzone;
  }
  return "slot";
});

const numLoadingRequests = ref(0);
const isLoading = computed(() => numLoadingRequests.value > 0);
const isSilentLoading = ref(true);
const ajaxBarEl = ref<typeof AjaxBar | undefined>(undefined);
watch(isLoading, (value) => {
  if (!ajaxBarEl.value) return;
  if (value) {
    ajaxBarEl.value?.start();
  } else {
    ajaxBarEl.value?.stop();
  }
});

const { cancelsLoading, openLoadingRequests } = useRequestCancellation();

async function silentLoadData() {
  if (!inbox.value) return;

  const requestId = crypto.randomUUID();
  let isCancelled = false;
  openLoadingRequests[requestId] = () => {
    isCancelled = true;
  };

  isSilentLoading.value = true;
  try {
    const apiInquiries = await listInquiries(
      organizationId.value,
      inboxId.value,
      filterStore.getApiQuery(),
      page.value,
    );
    if (isCancelled) return;

    inquiries.value = apiInquiries.results;
    rowsNumber.value = apiInquiries.count;
  } finally {
    isSilentLoading.value = false;
    delete openLoadingRequests[requestId];
  }
}

async function loadData() {
  numLoadingRequests.value++;

  try {
    await silentLoadData();
  } finally {
    numLoadingRequests.value--;
  }
}

function cancelAllOpenLoadingRequests() {
  for (const canceler of Object.values(openLoadingRequests)) {
    canceler();
  }
}

const filterStore = useSearchFilterSortStore();

const {
  searchText,
  searchFilename,
  filterStatusStrings,
  filterTagsIds,
  filterUserIds,
  filterSender,
  filterBuildingProject,
  filterBuildingProjectId,
  filterCustomer,
  filterShortCodeId,
  pageSize,
  page,
  sortBy,
  descending,
} = storeToRefs(filterStore);

async function updateSort(sort: { sortBy: string; descending: boolean }) {
  sortBy.value = sort.sortBy;
  descending.value = sort.descending;
  page.value = 1;
  await loadData();
}

const { ignoreUpdates: ignorePageUpdates } = watchIgnorable(
  () => page.value,
  async () => {
    cancelAllOpenLoadingRequests();
    await loadData();
  },
);

watch(
  () => [
    pageSize.value,
    searchText.value,
    searchFilename.value,
    filterStatusStrings.value,
    filterTagsIds.value,
    filterSender.value,
    filterBuildingProject.value,
    filterShortCodeId.value,
    filterUserIds.value,
    filterBuildingProjectId.value,
    filterCustomer.value,
    inbox.value?.id,
    inboxView.value,
  ],
  async (newVal, oldVal) => {
    if (newVal.every((v, i) => v === oldVal[i])) return;
    ignorePageUpdates(() => {
      page.value = 1;
    });
    cancelAllOpenLoadingRequests();
    await loadData();
  },
);

loadData();
const doPoll = () => {
  // don't poll if there is a full-text search going on since that takes a lot of time
  if (searchText.value) return false;
  // don't poll if we are currently loading data
  if (isSilentLoading.value) return false;
  return true;
};

const { start } = usePolling(
  () => executeInNewTrace(silentLoadData, "poll-inquiries"),
  doPoll,
);
onMounted(start);

function openInquiry(inquiryId: number) {
  // text and filename make the loading slow, therefore omitted in inquiry view
  const query = omit(router.currentRoute.value.query, [
    "page",
    "rowsPerPage",
    "text",
    "filename",
  ]);
  const queryAllStrings = mapValues(query, (value) =>
    value ? value.toString() : null,
  );

  router.push({
    name: "inquiry",
    params: {
      inboxId: inboxId.value,
      inquiryId,
    },
    query: queryAllStrings,
  });
}

const actions = useMultiInquiryActions(
  inbox,
  inquiries,
  selectedInquiries,
  loadData,
);

function triggersReload<T>(fn: (...args: T[]) => Promise<void>) {
  return async (...args: T[]) => {
    await fn(...args);
    await loadData();
  };
}

async function retryAnalysisOfInquiryById(inquiryId: number) {
  const inquiry = inquiries.value?.find((i) => i.id === inquiryId);
  if (!inquiry) return;
  await retryInquiryAnalysis(inquiry, organizationId.value, inbox.value!.id);
}

const assignInquiry = cancelsLoading(actions.assignInquiry);
const updateInquiryStatus = cancelsLoading(actions.updateInquiryStatus);
const moveSelectedInquiries = triggersReload(
  cancelsLoading(actions.moveSelectedInquiries),
);
const deleteSelectedInquiries = cancelsLoading(actions.deleteSelectedInquiries);
const assignSelectedInquiries = cancelsLoading(actions.assignSelectedInquiries);
const updateSelectedInquiriesStatus = cancelsLoading(
  actions.updateSelectedInquiriesStatus,
);

const { showUploadDialog, uploadDocuments } = useLeadUpload(inboxId, loadData);

function toggleSelectAll() {
  if (selectedInquiries.value.length === inquiries.value?.length) {
    selectedInquiries.value = [];
  } else {
    selectedInquiries.value = inquiries.value || [];
  }
}
</script>

<style scoped lang="scss">
.fab-spacer {
  height: 74px;
  width: 100%;
}

.use-remaining-space {
  height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
}
</style>
