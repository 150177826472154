<template>
  <div class="sidebar flex column no-wrap">
    <div class="row no-wrap items-center q-py-md q-pl-md">
      <q-btn
        v-if="organization"
        class="q-mini-drawer-hide sidebar-organization-button col"
        flat
        padding="xs none"
        align="left"
      >
        <div class="ellipsis">
          <user-avatar :user="user" class="q-mr-sm" />
          {{ organization.name }}
        </div>
        <q-menu anchor="bottom right" self="top right" :offset="[0, 4]">
          <account-menu />
        </q-menu>
      </q-btn>
      <sidebar-toggle-button />
    </div>
    <div class="sidebar-menu-container column no-wrap q-py-md q-px-sm">
      <sidebar-menu class="full-width col" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentUserStore } from "@/stores/currentUser";
import { storeToRefs } from "pinia";
import AccountMenu from "../AccountMenu.vue";
import UserAvatar from "../User/UserAvatar.vue";
import SidebarMenu from "./SidebarMenu.vue";
import SidebarToggleButton from "./SidebarToggleButton.vue";

const { organization } = storeToRefs(useCurrentOrganizationStore());

const { user } = storeToRefs(useCurrentUserStore());
</script>

<style lang="scss">
.sidebar {
  background-color: $app-background;
  position: sticky;
  top: 0;
  height: 100vh !important;
  overflow-y: auto;
  padding-right: 4px;

  & ::-webkit-scrollbar {
    width: 8px;
  }

  .sidebar-organization-button {
    max-width: 100%;
  }

  .sidebar-menu-container {
    flex-grow: 0;
    flex-shrink: 1;
    height: 100%;
    overflow: auto;
  }
}
</style>
