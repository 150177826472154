import { client, clientWithoutErrorNotifications } from "@/api/client/client";
import type { InquiryFile } from "@/types/inquiryFile";

export async function downloadInquiryFile(
  inquiryId: number,
  inquiryFileId: number
): Promise<void> {
  window.open(
    `/api/inquiries/${inquiryId}/inquiry_files/${inquiryFileId}/download/`,
    "_blank"
  );
}

export async function deleteInquiryFile(
  inquiryId: number,
  inquiryFileId: number
): Promise<void> {
  await client.delete(
    `/inquiries/${inquiryId}/inquiry_files/${inquiryFileId}/`
  );
}

export async function createInquiryFile(
  inquiryId: number,
  file: File
): Promise<InquiryFile> {
  const formData = new FormData();
  formData.append("file", file);
  const url = `/inquiries/${inquiryId}/inquiry_files/`;

  const response = await clientWithoutErrorNotifications.post(url, formData);
  return response.data;
}

export async function renameInquiryFile(
  inquiryId: number,
  inquiryFileId: number,
  filename: string
): Promise<void> {
  await client.patch(`/inquiries/${inquiryId}/inquiry_files/${inquiryFileId}/`, {
    filename,
  });
}
