<template>
  <div class="full-width">
    <q-field
      dense
      outlined
      stack-label
      :label="$t('Customer')"
      :disable="props.disable"
      @clear="emit('update:customer', null)"
      class="cursor-pointer"
    >
      <div
        v-if="selectedCustomer"
        class="text-neutral-9 ellipsis full-width"
        :title="getCustomerRepresentation(selectedCustomer)"
      >
        {{ getCustomerRepresentation(selectedCustomer) }}
      </div>
      <async-select-menu
        v-if="!props.disable"
        fit
        :preload-options="props.customer ? [props.customer] : []"
        :selected-option="selectedCustomer"
        :search-placeholder="$t('singleCustomerInput.searchPlaceholder')"
        id-value="id"
        :find-options="searchCustomers"
        :label-fn="getCustomerRepresentation"
        :min-search-length="2"
        @select="emit('update:customer', $event)"
      >
        <template #option="{ option, select, itemClass }">
          <q-item
            dense
            clickable
            v-ripple
            v-close-popup
            @click.stop="select(option)"
            :class="itemClass"
          >
            <q-item-section>
              <q-item-label class="ellipsis full-width">
                <highlight-text
                  :text="option.name"
                  :search-text="currentSearchText"
                />
              </q-item-label>
              <q-item-label caption class="ellipsis full-width">
                <highlight-text
                  :text="getAddressRepresentation(option)"
                  :search-text="currentSearchText"
                />
              </q-item-label>
              <q-item-label
                caption
                v-if="option.salesOffice"
                class="text-italic"
              >
                {{ getCustomerSalesOfficeRepresentation(option) }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label caption>
                <highlight-text
                  :text="option.externalId"
                  :search-text="currentSearchText"
                />
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </async-select-menu>
      <template #append v-if="selectedCustomer">
        <q-icon
          name="sym_r_cancel"
          class="q-field__focusable-action"
          @click.stop="emit('update:customer', null)"
        />
      </template>
    </q-field>
  </div>
</template>

<script setup lang="ts">
import { findCustomers } from "@/api/customer";
import HighlightText from "@/components/HighlightText.vue";
import AsyncSelectMenu from "@/components/SelectMenus/AsyncSelectMenu.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { NUM_CUSTOMERS_TO_RETRIEVE_IN_ADVANCE } from "@/config/constants";
import type { Customer } from "@/types/customer";
import type { Inquiry } from "@/types/inquiry";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  inquiry: Inquiry;
  customer: Customer | null;
  disable?: boolean;
}>();

const selectedCustomer = ref<Customer | null>(props.customer);
const currentSearchText = ref("");

const emit = defineEmits<{
  "update:customer": [customer: Customer | null];
}>();

const isLoading = ref(false);
const { organizationId } = useRouteParams();

watch(
  () => props.customer,
  (value) => {
    selectedCustomer.value = value;
  },
);

async function searchCustomers(searchText: string) {
  currentSearchText.value = searchText;
  if (!searchText.length) {
    isLoading.value = false;
    return [];
  }

  isLoading.value = true;
  try {
    return await findCustomers(
      organizationId.value,
      searchText,
      NUM_CUSTOMERS_TO_RETRIEVE_IN_ADVANCE,
    );
  } finally {
    isLoading.value = false;
  }
}

function getCustomerRepresentation(customer: Customer | null) {
  if (!customer) return "";
  let result = customer.name;
  if (customer.salesOffice) {
    result += ` (${getCustomerSalesOfficeRepresentation(customer)})`;
  }
  return result;
}

function getCustomerSalesOfficeRepresentation(customer: Customer) {
  if (!customer.salesOffice) return null;
  return t("Sales office") + ": " + customer.salesOffice;
}

function getAddressRepresentation(customer: Customer) {
  const townRepresentation = [customer.postCode, customer.town]
    .filter(Boolean)
    .join(" ");
  return [customer.street, townRepresentation, customer.countryCode]
    .filter(Boolean)
    .join(", ");
}
</script>

<style scoped lang="scss"></style>
