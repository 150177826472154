<template>
  <div v-if="qrCodeData" class="q-mb-sm row justify-center full-width">
    <img :src="qrCodeData" alt="QR Code" />
  </div>
</template>

<script setup lang="ts">
import QRCode from "qrcode";
import { ref, watch } from "vue";

const props = defineProps({
  url: {
    type: String,
    required: false,
    default: "",
  },
});

const qrCodeData = ref<string>("");

// Re-generate the QR code whenever the URL changes.
watch(
  () => props.url,
  async (newUrl) => {
    if (newUrl) {
      try {
        qrCodeData.value = await QRCode.toDataURL(newUrl);
      } catch (error) {
        console.error("Error generating QR code:", error);
        qrCodeData.value = "";
      }
    } else {
      qrCodeData.value = "";
    }
  },
  { immediate: true },
);
</script>
