export function isValidMfaCode(code: string): boolean {
  return /^\d{6}$/.test(code);
}

export function isValidEmail(email: string): boolean {
  const emailPattern =
    /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
  return emailPattern.test(email);
}

export function isValidHeaderName(name: string): boolean {
  // Allowed characters per RFC 7230 for header names (the "token" definition):
  // !, #, $, %, &, ', *, +, -, ., ^, _, `, |, ~, and alphanumerics.

  const tokenRegex = /^[!#$%&'*+\-.^_`|~0-9A-Za-z]+$/;
  return tokenRegex.test(name);
}
