<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onSubmit" class="q-gutter-y-sm">
        <q-card-section class="q-gutter-y-sm">
          <div class="text-subtitle1 q-mb-md">
            {{ $t("settings.newInboxDialog.title") }}
          </div>
          <q-input
            outlined
            autofocus
            dense
            name="name"
            :label="$t('Name')"
            v-model="name"
            lazy-rules
            :rules="[rules.name]"
          />
          <q-input
            outlined
            dense
            name="description"
            :label="$t('Description')"
            v-model="description"
          />
          <q-checkbox
            dense
            size="sm"
            class="q-mt-md"
            v-model="isDocumentUploadEnabled"
            :label="$t('settings.newInboxDialog.documentUploadEnabled')"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat dense :label="$t('Cancel')" @click="onDialogCancel" />
          <q-btn
            dense
            color="primary"
            :label="$t('settings.newInboxDialog.ok')"
            type="submit"
            :disable="!canBeSubmitted"
          />
        </q-card-actions>
      </q-form>
      <q-inner-loading :showing="isCommitting" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useInboxFormValidation } from "@/composables/formValidation/useInboxFormValidation";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useInboxesStore } from "@/stores/inboxes";
import type { NewInbox } from "@/types/inbox";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";

const name = ref("");
const description = ref("");
const isDocumentUploadEnabled = ref(true);

const { rules, canBeSubmitted } = useInboxFormValidation(name);

const isCommitting = ref(false);

const { organization } = storeToRefs(useCurrentOrganizationStore());
const inboxes = useInboxesStore();

defineEmits(useDialogPluginComponent.emits);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

async function onSubmit() {
  if (!organization.value) return;
  isCommitting.value = true;

  const newInbox: NewInbox = {
    name: name.value,
    description: description.value,
    documentUploadEnabled: isDocumentUploadEnabled.value,
    displayDates: "SUBMISSION_CREATED",
    preferredManufacturers: [],
  };

  try {
    await inboxes.addInbox(newInbox);
  } finally {
    isCommitting.value = false;
  }

  onDialogOK();
}
</script>
@/stores/currentOrganization
