<template>
  <multi-select-menu
    :selected-options="selectedTags"
    :partially-selected-options="partiallySelectedTags"
    :options="options"
    :label-fn="(tag) => tag?.label"
    :search-values="['label']"
    id-value="id"
    :search-placeholder="$t('multiTagSelect.searchTag')"
    @select="selectTag"
    @unselect="unselectTag"
  >
    <template #option-label="{ option }">
      <tag-chip :tag="option" v-if="option" />
    </template>
  </multi-select-menu>
</template>

<script setup lang="ts">
import MultiSelectMenu from "@/components/SelectMenus/MultiSelectMenu.vue";
import TagChip from "@/components/Tags/TagChip.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import type { Tag } from "@/types/tag";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    selectedTags: Tag[];
    partiallySelectedTags?: Tag[];
  }>(),
  { partiallySelectedTags: () => [] },
);

const emit = defineEmits<{
  "update:selectedTags": [selectedTags: Tag[]];
  select: [tag: Tag];
  unselect: [tag: Tag];
}>();

const { inbox } = storeToRefs(useCurrentInboxStore());
const options = computed(() => inbox.value?.availableTags || []);

function selectTag(tag: Tag) {
  if (props.selectedTags.includes(tag)) return;
  emit("select", tag);
  emit("update:selectedTags", [...props.selectedTags, tag]);
}

function unselectTag(tag: Tag) {
  emit("unselect", tag);
  emit(
    "update:selectedTags",
    props.selectedTags.filter((t) => t.id !== tag.id),
  );
}
</script>
