import type {
  DataImport,
  MonthlyUsage,
  Organization,
  OrganizationExport,
  OrganizationKeywords,
  PartialOrganization,
  ShortOrganization,
} from "@/types/organization";
import { client, clientWithoutErrorNotifications } from "./client/client";

export async function listOrganizations() {
  const result = await client.get<ShortOrganization[]>("/organizations/");
  return result.data;
}

export async function readOrganization(organizationId: number) {
  const result = await client.get<Organization>(
    `/organizations/${organizationId}/`,
  );
  return result.data;
}

export async function postOrganization(organization: Organization) {
  const result = await client.post<Organization>(
    "/organizations/",
    organization,
  );
  return result.data;
}

export async function updateOrganization(
  organizationId: number,
  organization: PartialOrganization,
) {
  const result = await client.patch<Organization>(
    `/organizations/${organizationId}/`,
    organization,
  );
  return result.data;
}

export async function deleteOrganization(organizationId: number) {
  const result = await client.delete(`/organizations/${organizationId}/`);
  return result.data;
}

export async function getKeywords(organizationId: number) {
  const result = await client.get<OrganizationKeywords>(
    `/organizations/${organizationId}/get_keywords/`,
  );
  return result.data;
}

export async function setKeywords(
  organizationId: number,
  data: Partial<Omit<OrganizationKeywords, "id">>,
) {
  const result = await client.patch<OrganizationKeywords>(
    `/organizations/${organizationId}/update_keywords/`,
    data,
  );
  return result.data;
}

export async function getExportConfiguration(
  organizationId: number,
): Promise<OrganizationExport> {
  const result = await clientWithoutErrorNotifications.get<OrganizationExport>(
    `/organizations/${organizationId}/export/`,
  );
  return result.data;
}

export async function setExportConfiguration(
  organizationId: number,
  data: Partial<OrganizationExport>,
): Promise<OrganizationExport> {
  const result = await client.patch<OrganizationExport>(
    `/organizations/${organizationId}/export/`,
    data,
  );
  return result.data;
}

export async function getProductImport(
  organizationId: number,
): Promise<DataImport> {
  const result = await clientWithoutErrorNotifications.get<DataImport>(
    `/organizations/${organizationId}/get_product_import/`,
  );
  return result.data;
}

export async function setProductImport(
  organizationId: number,
  data: Partial<DataImport>,
): Promise<DataImport> {
  const result = await client.put<DataImport>(
    `/organizations/${organizationId}/set_product_import/`,
    data,
  );
  return result.data;
}

export async function getCustomerImport(
  organizationId: number,
): Promise<DataImport> {
  const result = await clientWithoutErrorNotifications.get<DataImport>(
    `/organizations/${organizationId}/get_customer_import/`,
  );
  return result.data;
}

export async function setCustomerImport(
  organizationId: number,
  data: Partial<DataImport>,
): Promise<DataImport> {
  const result = await client.put<DataImport>(
    `/organizations/${organizationId}/set_customer_import/`,
    data,
  );
  return result.data;
}

export async function getMonthlyUsage(
  organizationId: number,
): Promise<MonthlyUsage[]> {
  const result = await client.get<MonthlyUsage[]>(
    `/organizations/${organizationId}/monthly_usage/`,
  );
  return result.data;
}

export async function setLogo(
  organizationId: number,
  file: File | null,
): Promise<void> {
  const formData = new FormData();
  formData.append("logo", file ?? "");

  await client.post(`/organizations/${organizationId}/set_logo/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
