<template>
  <q-btn
    v-if="user"
    id="beamer-feedback-button"
    round
    size="sm"
    icon="sym_r_lightbulb"
    color="neutral-2"
    text-color="neutral-7"
    class="fab"
  >
    <q-tooltip anchor="top left" self="bottom left" :offset="[0, 10]">
      {{ $t("changelog.title") }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { useCurrentUserStore } from "@/stores/currentUser";
import { useUserSettingsStore } from "@/stores/userSettings";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const mainLocale = computed(() => locale.value.split("-")[0].toUpperCase());

const { user } = storeToRefs(useCurrentUserStore());
const { userSettings } = storeToRefs(useUserSettingsStore());

const userId = computed(() => {
  const hostname = window.location.hostname;
  return `${hostname}-${user.value?.id}`;
});

const beamerFilter = computed(() => {
  const filters = [];
  if (
    user.value?.isSuperuser ||
    user.value?.roles.some((role) => role.role === "organization_admin")
  ) {
    filters.push("organization_admin");
  }
  for (const organizationId of user.value?.roles
    .filter((role) => role.objectModel === "organization")
    .map((role) => role.objectId) ?? []) {
    filters.push(`organization_${organizationId}`);
  }
  return filters;
});

const beamerConfig = computed(() => {
  const savedCounterSetting =
    userSettings.value?.frontendPreferences?.showNewFeaturesCounter;
  return {
    language: mainLocale.value,
    user_id: userId.value,
    counter: savedCounterSetting !== false, // default to true
    button: false, // don't display default button
    filter: beamerFilter.value.join(";"),
  };
});

watch(
  () => [
    mainLocale.value,
    userId.value,
    userSettings.value?.frontendPreferences?.showNewFeaturesCounter,
    user.value,
    beamerConfig.value,
  ],
  async () => {
    if (!user.value) return;
    const beamer = await waitForBeamer();
    beamer.update(beamerConfig.value);
    beamer.init();
  },
  { immediate: true },
);

async function waitForBeamer() {
  const win: any = window;
  if (win.Beamer) {
    return win.Beamer;
  }
  await new Promise((resolve) => setTimeout(resolve, 100));
  return waitForBeamer();
}
</script>

<style scoped lang="scss">
.fab {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}
</style>
