import { client } from "@/api/client/client";
import type { Tag } from "@/types/tag";

export async function listInboxTags(inboxId: number): Promise<Tag[]> {
  const result = await client.get<Tag[]>(`/inboxes/${inboxId}/tags/`);
  return result.data;
}

export async function listOrganizationTags(organizationId: number): Promise<Tag[]> {
  const result = await client.get<Tag[]>(`/organizations/${organizationId}/tags/`);
  return result.data;
}

export async function createInboxTag(
  inboxId: number,
  tag: Omit<Tag, "id">
): Promise<Tag> {
  const result = await client.post<Tag>(`/inboxes/${inboxId}/tags/`, tag);
  return result.data;
}

export async function createOrganizationTag(
  organizationId: number,
  tag: Omit<Tag, "id">
): Promise<Tag> {
  const result = await client.post<Tag>(`/organizations/${organizationId}/tags/`, tag);
  return result.data;
}

export async function deleteInboxTag(inboxId: number, tagId: number): Promise<void> {
  await client.delete(`/inboxes/${inboxId}/tags/${tagId}/`);
}

export async function deleteOrganizationTag(organizationId: number, tagId: number): Promise<void> {
  await client.delete(`/organizations/${organizationId}/tags/${tagId}/`);
}

export async function updateInboxTag(inboxId: number, tag: Tag): Promise<Tag> {
  const result = await client.patch<Tag>(
    `/inboxes/${inboxId}/tags/${tag.id}/`,
    tag
  );
  return result.data;
}

export async function updateOrganizationTag(organizationId: number, tag: Tag): Promise<Tag> {
  const result = await client.patch<Tag>(
    `/organizations/${organizationId}/tags/${tag.id}/`,
    tag
  );
  return result.data;
}

export async function convertToOrganizationTag(
  inboxId: number,
  tagId: number,
  config?: any,
): Promise<Tag> {
  const result = await client.post<Tag>(
    `/inboxes/${inboxId}/tags/${tagId}/convert_to_organization_tag/`,
    undefined,
    config
  );
  return result.data;
}
