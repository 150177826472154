<template>
  <q-list style="min-width: 200px">
    <q-item>
      <q-item-section class="force-row">
        <user-avatar :user="userStore.user" size="md" class="q-mr-sm" />
        <div class="column">
          <div
            v-if="userStore.user?.firstName || userStore.user?.lastName"
            class="text-bold"
          >
            {{ userStore.user?.firstName }} {{ userStore.user?.lastName }}
          </div>
          <div
            v-if="userStore.user"
            class="text-no-wrap account-menu-email-text"
          >
            {{ userStore.user.email }}
          </div>
          <q-badge v-if="userStore.user?.isSuperuser" color="primary">
            {{ $t("accountMenu.superUser") }}
          </q-badge>
        </div>
      </q-item-section>
    </q-item>
    <q-separator />
    <q-item
      v-if="
        userStore.user?.isSuperuser ||
        (userStore.user?.memberOfOrganizationIds &&
          userStore.user?.memberOfOrganizationIds.length > 1)
      "
      dense
      clickable
    >
      <q-item-section>
        {{ $t("accountMenu.switchOrganization") }}
      </q-item-section>
      <organization-select-menu anchor="top end" self="top start" :offset="[2, 0]" @select="switchOrganization" />
    </q-item>
    <q-item
      dense
      clickable
      v-close-popup
      :to="{
        name: 'settings-organization-general',
        params: { organizationId: organizationId },
      }"
      class="no-underline"
    >
      <q-item-section>
        {{ $t("accountMenu.organizationSettings") }}
      </q-item-section>
    </q-item>
    <q-item
      v-if="hasCurrentOrganizationRole['organization_admin']"
      dense
      clickable
      v-close-popup
      :to="{
        name: 'settings-organization-members',
        params: { organizationId },
      }"
      class="no-underline"
    >
      <q-item-section>
        {{ $t("accountMenu.inviteAndManageMembers") }}
      </q-item-section>
    </q-item>
    <q-separator />
    <q-item
      dense
      clickable
      v-close-popup
      :to="{ name: 'settings-account-profile', params: { organizationId } }"
      class="no-underline"
    >
      <q-item-section>
        {{ $t("accountMenu.accountSettings") }}
      </q-item-section>
    </q-item>
    <q-separator />
    <q-item
      dense
      clickable
      v-close-popup
      @click="q.dialog({ component: KeyboardShortcutsDialog })"
    >
      <q-item-section>
        {{ $t("accountMenu.keyboardShortcuts") }}
      </q-item-section>
    </q-item>
    <a href="https://help.kinisto.com" target="_blank" class="no-decoration">
      <q-item dense clickable v-close-popup>
        <q-item-section>
          <div class="row no-wrap items-center">
            {{ $t("accountMenu.help") }}
            <q-icon name="sym_r_launch" size="14px" color="neutral-5" />
          </div>
        </q-item-section>
      </q-item>
    </a>
    <q-separator />
    <q-item dense clickable v-close-popup @click="logout">
      <q-item-section>
        {{ $t("accountMenu.signOut") }}
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { logout } from "@/api/auth";
import { getCsrfCookie } from "@/api/client/csrf";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { useCurrentUserStore } from "@/stores/currentUser";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
import KeyboardShortcutsDialog from "./KeyboardShortcutsDialog.vue";
import UserAvatar from "./User/UserAvatar.vue";
import OrganizationSelectMenu from "./OrganizationSelectMenu.vue";
const q = useQuasar();

const { organizationId } = useRouteParams();

const userStore = useCurrentUserStore();

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore(),
);

getCsrfCookie();

const router = useRouter();

function switchOrganization(organizationId: number) {
  router.push({
      name: "organization",
      params: { organizationId },
    });
  }
</script>

<style lang="scss">
.no-underline:hover {
  text-decoration: none !important;
}

.force-row {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
}

.account-menu-email-text {
  font-size: 12px;
  color: $neutral-8;
}
</style>
