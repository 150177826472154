<template>
  <file-dropzone
    v-if="inquiry"
    :accept="organization?.inquiryFileExtensions.join(', ') || ''"
    @drop="uploadInquiryFile"
    class="rounded-borders"
  >
    <template #overlay>
      <div>{{ $t("inquiryPage.inquiryFiles.uploadFile") }}</div>
    </template>
    <q-card flat bordered v-if="inquiry.inquiryFiles.length > 0">
      <q-list dense separator>
        <uploaded-file-item
          v-for="inquiryFile in inquiry.inquiryFiles.filter(
            (file) => file.isAttachment
          )"
          :key="inquiryFile.id"
          :inquiry-id="inquiry.id"
          :inquiry-file="inquiryFile"
        />
      </q-list>
    </q-card>
    <div class="q-mt-xs row no-wrap justify-start">
      <q-btn
        flat
        dense
        color="neutral-8"
        icon="sym_r_add"
        size="md"
        @click.stop.prevent="fileInputEl?.click()"
      >
        {{ $t("inquiryPage.inquiryFiles.uploadFile") }}
      </q-btn>
      <input
        type="file"
        multiple
        style="display: none"
        ref="fileInputEl"
        :accept="organization?.inquiryFileExtensions.join(', ') || ''"
        @change="(event: any) => uploadInquiryFile(event.target?.files)"
      />
    </div>
  </file-dropzone>
</template>

<script setup lang="ts">
import { createInquiryFile } from "@/api/inquiryFile";
import FileDropzone from "@/components/FileDropzone.vue";
import {
  DEFAULT_MAX_UPLOADED_FILE_SIZE_KB,
  MAX_UPLOADED_FILE_NAME_LENGTH,
} from "@/config/constants";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { type InquiryFile } from "@/types/inquiryFile";
import { isAxiosError } from "axios";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import UploadedFileItem from "./UploadedFileItem.vue";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const fileInputEl = ref<HTMLInputElement | null>(null);

async function uploadInquiryFile(files: FileList) {
  const uploads = [];
  for (const file of files) {
    uploads.push(uploadSingleInquiryFile(file));
  }
  await Promise.all(uploads);
}

const q = useQuasar();
const { t } = useI18n();

async function uploadSingleInquiryFile(file: File) {
  if (!file) {
    return;
  }

  if (file.name.length > MAX_UPLOADED_FILE_NAME_LENGTH) {
    q.notify({
      message: t("errors.filenameTooLong", {
        maxLength: MAX_UPLOADED_FILE_NAME_LENGTH,
      }),
      color: "negative",
    });
    return;
  }

  const maxFileSizeKb =
    organization.value?.inquiryFileMaxSizeKb ||
    DEFAULT_MAX_UPLOADED_FILE_SIZE_KB;
  if (file.size > maxFileSizeKb * 1024) {
    q.notify({
      message: t("inquiryPage.inquiryFiles.errors.FILE_TOO_LARGE", {
        maxSize: maxFileSizeKb,
      }),
      color: "negative",
    });
    return;
  }
  const temporaryId = crypto.randomUUID();

  const tempInquiryFile: InquiryFile = reactive({
    id: -1,
    filename: file.name,
    fileFormat: null,
    isAttachment: true,
    temporaryId,
  });
  inquiry.value!.inquiryFiles.push(tempInquiryFile);
  try {
    const newInquiryFile = await createInquiryFile(inquiry.value!.id, file);
    Object.assign(tempInquiryFile, newInquiryFile);
    tempInquiryFile.temporaryId = undefined;
  } catch (e: any) {
    if (isAxiosError(e) && e.response?.data) {
      const errorCode = e.response?.data?.[0];
      const errorMessage =
        t(`inquiryPage.inquiryFiles.errors.${errorCode}`, {
          maxSize: organization.value!.inquiryFileMaxSizeKb,
          allowedExtensions:
            organization.value!.inquiryFileExtensions.join(", "),
        }) || t("inquiryPage.inquiryFiles.errors.UNKNOWN_ERROR");
      q.notify({
        message: errorMessage,
        color: "negative",
      });
      inquiry.value!.inquiryFiles = inquiry.value!.inquiryFiles.filter(
        (file) => file.temporaryId !== temporaryId,
      );
    } else {
      throw e;
    }
  }
}
</script>

<style scoped>
.button-spacer {
  width: 25.15px;
}
</style>
