import { client } from "@/api/client/client";

import type { DraftQuotation } from "@/types/quotation";
import type { InquiryFileFormat } from "@/types/inquiryFile";
export async function getDraftQuotation(
  inboxId: number,
  inquiryId: number
): Promise<DraftQuotation> {
  const response = await client.get(`/inquiries/${inquiryId}/draft_quotation/`);
  return response.data;
}

export async function updateDraftQuotation(
  inquiryId: number,
  quotation: Partial<DraftQuotation>
): Promise<DraftQuotation> {
  const response = await client.patch(
    `/inquiries/${inquiryId}/draft_quotation/`,
    quotation
  );
  return response.data;
}

export async function finalizeDraftQuotation(inquiryId: number): Promise<void> {
  await client.post(`/inquiries/${inquiryId}/draft_quotation/finalize/`);
}

export async function checkNotFinalizableReasonsDraftQuotation(
  inquiryId: number
): Promise<string[]> {
  const response = await client.get(
    `/inquiries/${inquiryId}/draft_quotation/check_finalizable/`
  );
  return response.data.reasons;
}

export async function getInquiryFile(inquiryId: number): Promise<File> {
  const response = await client.get(
    `/inquiries/${inquiryId}/draft_quotation/download/`,
    { responseType: "blob" }
  );
  const blob = new Blob([response.data], {
    type: response.headers["contentType"],
  });
  return new File([blob], response.headers["filename"], {
    type: response.headers["contentType"] as string,
  });
}

export async function downloadQuotation(
  inquiryId: number,
  format: InquiryFileFormat
): Promise<void> {
  window.open(
    `/api/inquiries/${inquiryId}/draft_quotation/download/?file_format=${format}`,
    "_blank"
  );
}
