<template>
  <q-form @submit="handleLoginClick">
    <email-input
      dense
      :model-value="props.email"
      :disable="isLoading"
      @update:model-value="$emit('update:email', $event!.toString())"
    />
    <q-banner rounded class="bg-negative text-white q-mb-md" v-if="loginFailed">
      {{ $t(`loginForm.ssoErrors.${loginError}`) }}
      <template v-slot:action>
        <q-btn
          flat
          color="white"
          :label="$t('loginForm.dismiss')"
          @click="loginFailed = false"
        />
      </template>
    </q-banner>
    <q-btn
      unelevated
      color="primary"
      type="submit"
      class="full-width q-mt-xs"
      :label="$t('loginForm.loginWithSso')"
      :loading="isLoading"
    />
    <back-to-login-link @click="$emit('backToLogin')" />
  </q-form>
</template>

<script setup lang="ts">
import { enumerateAuthnOptions } from "@/api/auth";
import { getCsrfCookie } from "@/api/client/csrf";
import EmailInput from "@/components/EmailInput.vue";
import { ref } from "vue";
import BackToLoginLink from "./BackToLoginLink.vue";
import type { SsoEnumerationError, SsoProvider } from "@/types/authn";
import { AxiosError } from "axios";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  "update:email": [email: string];
  backToLogin: [];
  loginWithSso: [providers: SsoProvider[]];
}>();

const isLoading = ref<boolean>(false);
const loginFailed = ref<boolean>(false);
const loginError = ref<SsoEnumerationError | null>(null);

// Make sure CSRF cookie is set before logging in
getCsrfCookie();

async function handleLoginClick() {
  isLoading.value = true;

  try {
    const authnOptions = await enumerateAuthnOptions(props.email);

    if (!authnOptions.ssoProviders.length) {
      loginFailed.value = true;
      loginError.value = "noSsoProviders";
      return;
    }

    emit("loginWithSso", authnOptions.ssoProviders);
  } catch (e) {
    loginFailed.value = true;
    if (e instanceof AxiosError && e.response?.status == 401) {
      loginError.value = "noSsoProviders";
    } else {
      loginError.value = "other";
    }
  } finally {
    isLoading.value = false;
  }
}
</script>
