<template>
  <div class="mfa-form-container">
    <q-form @submit.prevent="submitMfa">
      <mfa-code-input dense v-model="mfaCode" autofocus :disable="isLoading" />
      <q-banner rounded class="bg-negative text-white q-mb-md" v-if="mfaError">
        {{ mfaError }}
        <template v-slot:action>
          <q-btn
            flat
            color="white"
            :label="$t('loginMfaForm.dismiss')"
            @click="mfaError = ''"
          />
        </template>
      </q-banner>
      <q-btn
        unelevated
        color="primary"
        type="submit"
        class="full-width q-mt-xs"
        :disable="!isCodeValid"
        :loading="isLoading"
        :label="$t('loginMfaForm.submit')"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import { authenticateMFA } from "@/api/auth";
import MfaCodeInput from "@/components/MfaCodeInput.vue";
import { isValidMfaCode } from "@/utils/validation";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const mfaCode = ref<string>("");
const isLoading = ref<boolean>(false);
const mfaError = ref<string>("");
const router = useRouter();

const isCodeValid = computed(() => isValidMfaCode(mfaCode.value));

async function submitMfa() {
  if (!isCodeValid.value) {
    mfaError.value = t("loginMfaForm.invalidMfaCodeFormat");
    return;
  }

  isLoading.value = true;
  try {
    await authenticateMFA(mfaCode.value);
    const redirectUrl = router.currentRoute.value.query.redirect;
    if (redirectUrl && redirectUrl.length) {
      router.push(redirectUrl as string);
    } else {
      router.push("/");
    }
  } catch (IncorrectMfaCode) {
    mfaError.value = t("loginMfaForm.invalidMfaCode");
  } finally {
    isLoading.value = false;
  }
}
</script>

<style scoped lang="scss">
.mfa-form-container {
  width: 25rem;
  max-width: 100%;
}
</style>
