<template>
  <div>
    <q-form @submit="callOnSubmit">
      <password-input
        dense
        outlined
        v-model="password"
        :disable="isLoading"
      >
        <template v-slot:prepend>
          <q-icon name="sym_r_lock" />
        </template>
      </password-input>
      <q-banner
        rounded
        class="bg-negative text-white q-mb-md"
        v-if="reauthFailed"
      >
        {{ $t("login.reauth.wrongPassword") }}
        <template v-slot:action>
          <q-btn
            flat
            color="white"
            :label="$t('Dismiss')"
            @click="reauthFailed = false"
          />
        </template>
      </q-banner>
      <q-btn
        unelevated
        color="primary"
        type="submit"
        class="full-width q-mt-xs"
        :label="$t('login.reauth.submit')"
        :loading="isLoading"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import { getCsrfCookie } from "@/api/client/csrf";
import { ref } from "vue";
import PasswordInput from "@/components/PasswordInput.vue";

// Ensure that the CSRF cookie is set before making API calls
getCsrfCookie();

const password = ref("");
const isLoading = ref(false);
const reauthFailed = ref(false);

const props = defineProps<{
  onSubmit: (password: string) => Promise<boolean>;
}>();

async function callOnSubmit() {
  isLoading.value = true;
  reauthFailed.value = false;
  try {
    const result = await props.onSubmit(password.value);
    if (!result) {
      reauthFailed.value = true;
    }
  } catch (error) {
    reauthFailed.value = true;
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss">
.login-form-container {
  width: 25rem;
  max-width: 100%;
}
</style>
