<template>
  <q-form @submit="callOnSubmit">
    <div class="q-mb-md">
      <email-input
        dense
        :model-value="props.email"
        :disable="isLoading"
        @update:model-value="$emit('update:email', $event!.toString())"
      />
      <password-input
        dense
        outlined
        v-model="password"
        hide-bottom-space
        :disable="isLoading"
      >
        <template v-slot:prepend>
          <q-icon name="sym_r_lock" />
        </template>
      </password-input>
      <div>
        <a
          href="#"
          @click="$emit('resetPassword', email)"
          class="text-neutral-10"
        >
          {{ $t("loginForm.forgotPassword") }}
        </a>
      </div>
    </div>
    <q-banner rounded class="bg-negative text-white q-mb-md" v-if="loginFailed">
      {{ $t("loginForm.loginFailed") }}
      <template v-slot:action>
        <q-btn
          flat
          color="white"
          :label="$t('loginForm.dismiss')"
          @click="loginFailed = false"
        />
      </template>
    </q-banner>
    <q-btn
      unelevated
      color="primary"
      type="submit"
      class="full-width q-mt-xs"
      :label="$t('loginForm.login')"
      :loading="isLoading"
    />
    <q-btn
      outline
      color="neutral-7"
      class="full-width q-mt-sm"
      :label="$t('loginForm.loginWithSso')"
      :disable="isLoading"
      @click="$emit('useSso')"
    />
  </q-form>
</template>

<script setup lang="ts">
import { getCsrfCookie } from "@/api/client/csrf";
import type { QForm } from "quasar";
import { ref } from "vue";
import EmailInput from "@/components/EmailInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";

const props = defineProps<{
  email: string;
  onSubmit: (email: string, password: string) => Promise<boolean>;
}>();

defineEmits<{
  "update:email": [email: string];
  resetPassword: [email: string];
  useSso: [];
}>();

const password = ref<string>("");

const isLoading = ref<boolean>(false);
const loginFailed = ref<boolean>(false);

// Make sure CSRF cookie is set before logging in
getCsrfCookie();

async function callOnSubmit() {
  isLoading.value = true;
  try {
    const loginResult = await props.onSubmit(props.email, password.value);
    loginFailed.value = !loginResult;
  } catch (e) {
  } finally {
    isLoading.value = false;
  }
}
</script>
