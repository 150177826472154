<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" persistent maximized>
    <q-card class="q-dialog-plugin column no-wrap header" @keydown.stop>
      <div
        class="row no-wrap items-center justify-between header-row q-px-md q-py-sm"
      >
        <div class="col-auto text-h6">
          {{ $t("createDraftQuotationDialog.title") }}
        </div>
        <q-btn flat icon="close" @click="onDialogHide" aria-label="Close" />
      </div>
      <div class="flex-1 overflow-hidden full-height">
        <div class="row no-wrap full-height">
          <!-- Left side: PDF Viewer -->
          <div class="col relative-position full-height scroll">
            <pdf-viewer
              v-if="pdfFile"
              :file="pdfFile"
              class="full-height"
              initial-scale="full-page"
            />
            <div v-else class="column items-center justify-center full-height">
              <q-icon name="insert_drive_file" size="5rem" color="grey-5" />
            </div>
            <q-inner-loading :showing="isLoading" />
          </div>

          <!-- Right side: Angebotsdetails -->
          <div class="right-side full-height column no-wrap scroll">
            <div class="q-pa-md col scroll">
              <div class="text-h6 q-mb-md">
                {{ $t("createDraftQuotationDialog.details") }}
              </div>
              <q-form class="column no-wrap items-start gap-md full-width">
                <q-input
                  dense
                  outlined
                  v-model="recipientName"
                  :label="$t('createDraftQuotationDialog.recipientName')"
                  type="text"
                  class="full-width"
                  :disable="isFinal"
                  @change="updateQuotation"
                />
                <q-input
                  dense
                  outlined
                  v-model="recipientAddress"
                  :label="$t('createDraftQuotationDialog.recipientAddress')"
                  type="textarea"
                  autogrow
                  class="full-width"
                  :disable="isFinal"
                  @change="updateQuotation"
                />
                <q-input
                  dense
                  outlined
                  v-model="salutation"
                  :label="$t('createDraftQuotationDialog.salutation')"
                  type="textarea"
                  autogrow
                  class="full-width"
                  :disable="isFinal"
                  @change="updateQuotation"
                />
                <q-input
                  dense
                  outlined
                  v-model="closing"
                  :label="$t('createDraftQuotationDialog.closing')"
                  type="textarea"
                  autogrow
                  class="full-width"
                  :disable="isFinal"
                  @change="updateQuotation"
                />
              </q-form>
            </div>
            <q-separator />
            <div
              class="column no-wrap items-start gap-md full-width col-auto q-pa-md"
            >
              <q-list
                v-if="validationReasons.length > 0"
                bordered
                class="text-red rounded-borders"
              >
                <q-item
                  v-for="(error, index) in validationReasons"
                  :key="index"
                  dense
                >
                  <q-item-section avatar>
                    <q-icon name="error" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label lines="1">
                      {{
                        $t(
                          `createDraftQuotationDialog.notFinalizableReasons.${error}`
                        )
                      }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
              <div class="row items-center gap-sm full-width">
                <q-btn-dropdown
                  split
                  class="col"
                  :label="$t('createDraftQuotationDialog.createFinalPdf')"
                  color="primary"
                  :disable="
                    isFinal || isLoading || validationReasons.length > 0
                  "
                  @click="finalizeDraftQuotation(InquiryFileFormat.PDF)"
                >
                  <q-list dense>
                    <q-item
                      clickable
                      v-close-popup
                      :label="$t('createDraftQuotationDialog.createFinalP94')"
                      @click="finalizeDraftQuotation(InquiryFileFormat.P94)"
                    >
                      <q-item-section>
                        <q-item-label class="text-center">{{
                          $t("createDraftQuotationDialog.createFinalP94")
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="finalizeDraftQuotation(InquiryFileFormat.X94)"
                    >
                      <q-item-section>
                        <q-item-label class="text-center">{{
                          $t("createDraftQuotationDialog.createFinalX94")
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
                <q-icon name="sym_r_help" size="1.2rem" color="neutral-7">
                  <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :offset="[0, 8]"
                  >
                    {{ $t("createDraftQuotationDialog.createFinalTooltip") }}
                  </q-tooltip>
                </q-icon>
              </div>

              <div class="row items-center gap-sm full-width">
                <q-btn-dropdown
                  split
                  class="col"
                  color="neutral-5"
                  :label="$t('createDraftQuotationDialog.createDraftPdf')"
                  :disable="isFinal || isLoading"
                  @click="downloadDraftQuotation(InquiryFileFormat.PDF)"
                >
                  <q-list dense>
                    <q-item
                      clickable
                      v-close-popup
                      @click="downloadDraftQuotation(InquiryFileFormat.P94)"
                    >
                      <q-item-section>
                        <q-item-label class="text-center">{{
                          $t("createDraftQuotationDialog.createDraftP94")
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="downloadDraftQuotation(InquiryFileFormat.X94)"
                    >
                      <q-item-section>
                        <q-item-label class="text-center">{{
                          $t("createDraftQuotationDialog.createDraftX94")
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
                <q-icon name="sym_r_help" size="1.2rem" color="neutral-7">
                  <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :offset="[0, 8]"
                  >
                    {{ $t("createDraftQuotationDialog.createDraftTooltip") }}
                  </q-tooltip>
                </q-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/draftQuotation";
import PdfViewer from "@/components/PdfViewer/PdfViewer.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { InquiryFileFormat } from "@/types/inquiryFile";
import { useDialogPluginComponent } from "quasar";
import { onMounted, ref } from "vue";

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide } = useDialogPluginComponent();

const { inboxId, inquiryId } = useRouteParams();

const pdfFile = ref<File | null>(null);
const recipientName = ref("");
const recipientAddress = ref("");
const salutation = ref("");
const closing = ref("");
const isLoading = ref(false);
const isFinal = ref(false);
const validationReasons = ref<string[]>([]);

async function updateQuotation() {
  await api.updateDraftQuotation(inquiryId.value, {
    recipientName: recipientName.value,
    recipientAddress: recipientAddress.value,
    salutation: salutation.value,
    closing: closing.value,
  });
  await updateDraftPDF();
}

async function updateDraftPDF() {
  isLoading.value = true;
  try {
    const pdfBlob = await api.getInquiryFile(inquiryId.value);
    pdfFile.value = new File([pdfBlob], "Angebotsentwurf.pdf", {
      type: "application/pdf",
    });
    validationReasons.value =
      await api.checkNotFinalizableReasonsDraftQuotation(inquiryId.value);
  } finally {
    isLoading.value = false;
  }
}

const currentInquiryStore = useCurrentInquiryStore();

async function finalizeDraftQuotation(format: InquiryFileFormat) {
  isFinal.value = true;
  try {
    await api.finalizeDraftQuotation(inquiryId.value);
    await api.downloadQuotation(inquiryId.value, format);
    await currentInquiryStore.loadData();
  } catch (error) {
    isFinal.value = false;
  }
  onDialogHide();
}

async function downloadDraftQuotation(format: InquiryFileFormat) {
  await api.downloadQuotation(inquiryId.value, format);
}

onMounted(async () => {
  const quotation = await api.getDraftQuotation(inboxId.value, inquiryId.value);
  recipientName.value = quotation.recipientName || "";
  recipientAddress.value = quotation.recipientAddress || "";
  salutation.value = quotation.salutation || "";
  closing.value = quotation.closing || "";
  isFinal.value = quotation.isFinal;
  await updateDraftPDF();
});
</script>

<style scoped lang="scss">
.header-row {
  border-bottom: 1px solid $neutral-3;
}

.right-side {
  width: 600px;
  overflow: auto;
}

.scroll {
  overflow-y: auto;
}
</style>
