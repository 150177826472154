import { isValidEmail, isValidHeaderName, isValidMfaCode } from "@/utils/validation";
import { useI18n } from "vue-i18n";

export function useFormValidationRules() {
  const { t } = useI18n();

  function validateNotEmpty(value: string | number): boolean | string {
    if (typeof value === "number") return true;
    if (value && value.length > 0) return true;
    return t("formValidation.required");
  }

  function validateEmail(value: string): boolean | string {
    if (isValidEmail(value)) return true;
    return t("formValidation.email");
  }

  function validateHeaderName(headerName: string): boolean | string {
    if (!isValidHeaderName(headerName)) {
      // Collect all invalid characters found in the header name.
      const invalidChars: string[] = [];
      for (const char of headerName) {
        if (!isValidHeaderName(char)) {
          if (!invalidChars.includes(char)) {
            const printedChar = char === " " ? t("formValidation.space") : char;
            invalidChars.push(printedChar);
          }
        }
      }
      return t("formValidation.invalidCharactersFound", {
        invalidChars: invalidChars.join(", "),
      });
    }

    return true;
  }

  function validateMfaCode(code: string): true | string {
    if (isValidMfaCode(code)) return true;
    return t("loginMfaForm.invalidMfaCodeFormat");
  }

  return {
    validateNotEmpty,
    validateEmail,
    validateHeaderName,
    validateMfaCode,
  };
}
