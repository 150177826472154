<template>
  <q-dialog ref="dialogRef">
    <q-card class="q-dialog-plugin">
      <div v-if="inquiry?.status.name === 'REVIEWING'">
        <q-card-section title class="text-xl">
          {{ $t("exportInquiryDialog.inquiryIsNotFinalized.title") }}
        </q-card-section>
        <q-card-section>
          {{ $t("exportInquiryDialog.inquiryIsNotFinalized.confirmation") }}
        </q-card-section>
      </div>
      <div v-else-if="inquiry?.exportedAt">
        <q-card-section title class="text-xl">
          {{ $t("exportInquiryDialog.inquiryWasAlreadyExported.title") }}
        </q-card-section>
        <q-card-section>
          {{ $t("exportInquiryDialog.inquiryWasAlreadyExported.confirmation") }}
        </q-card-section>
      </div>
      <div v-else>
        <q-card-section title class="text-xl">
          {{ $t("exportInquiryDialog.inquiryWasFinalized.title") }}
        </q-card-section>
        <q-card-section>
          {{ $t("exportInquiryDialog.inquiryWasFinalized.confirmation") }}
        </q-card-section>
      </div>
      <q-card-section v-if="errorMessage !== null">
        <q-banner rounded dense class="bg-negative text-white q-mb-md">
          <div class="text-bold" v-if="errorTitle !== null">
            {{ errorTitle }}
          </div>
          <i18n-t
            :keypath="`${errorCodeKeyPath}.suggestion`"
            tag="span"
            class="white-link"
            v-if="errorCodeKeyPath"
            scope="global"
          >
            <template #email>
              <a :href="'mailto:' + $t(`${errorCodeKeyPath}.emailAddress`)">{{
                $t(`${errorCodeKeyPath}.emailAddress`)
              }}</a>
            </template>
          </i18n-t>
          <span v-else> {{ errorMessage }}</span>
          <template v-slot:action>
            <q-btn
              flat
              dense
              color="white"
              :label="$t('Dismiss')"
              @click="errorMessage = null"
            />
          </template>
        </q-banner>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          dense
          flat
          :label="$t('exportInquiryDialog.cancel')"
          @click="onDialogCancel"
        />
        <q-btn
          dense
          color="primary"
          :label="$t('exportInquiryDialog.ok')"
          :disable="isLoading || !inquiry"
          @click="handleOkClick"
        />
      </q-card-actions>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import * as Sentry from "@sentry/vue";
import { getFinalizationErrorMessage } from "@/utils/errors";

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();

const { t } = useI18n();

const { inboxId } = useRouteParams();
const store = useCurrentInquiryStore();
const { inquiry } = storeToRefs(store);
const { loadData, warnIfInquiryHasFinalizationProblems } = useCurrentInquiryStore();
const { organization } = storeToRefs(useCurrentOrganizationStore());

const isLoading = ref(false);

const errorMessage = ref<string | null>(null);
const errorCodeKeyPath = ref<string | null>(null);
const errorTitle = ref<string | null>(null);

onMounted(async () => {
  isLoading.value = true;
  try {
    await loadData();
  } catch (e) {
    onDialogCancel();
    console.error(e);
  } finally {
    isLoading.value = false;
  }
});

function handleOkClick() {
  if (!inquiry.value) return;
  warnIfInquiryHasFinalizationProblems(exportInquiry)
}

async function exportInquiry() {
  if (!inquiry.value) return;

  errorMessage.value = null;

  isLoading.value = true;
  try {
    await api.exportInquiry(inboxId.value, inquiry.value.id, {
      ignoreErrors: [400],
    });
    if (inquiry.value) {
      const isAsyncExport = organization.value?.availableExportType === "ASYNC";
      const statusName = isAsyncExport ? "EXPORTING" : "FINALIZED";
      inquiry.value.status = {
        id: statusName,
        name: statusName,
        originalStatus: statusName,
        isSelectable: false,
        color: isAsyncExport ? "warning" : "positive",
        custom: false,
      };
    }
    onDialogOK();
  } catch (e: any) {
    if (e.response?.data?.reasons) {
      errorMessage.value = getFinalizationErrorMessage(
        e.response.data.reasons,
        inquiry.value
      );
    } else {
      let errorCode = e.response?.data.code;

      if (!errorCode) {
        console.error(e);
        Sentry.captureException(e);
        errorCode = "EXPORT_ERROR";
      }

      errorMessage.value = t(
        `exportInquiryDialog.errors.${errorCode}.suggestion`
      );
      errorCodeKeyPath.value = `exportInquiryDialog.errors.${errorCode}`;
      errorTitle.value = t(
        `exportInquiryDialog.errors.${errorCode}.title`,
        e.response?.data.params || {}
      );
    }
  } finally {
    isLoading.value = false;
  }
}
</script>
