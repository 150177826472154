<template>
  <div class="fit column no-wrap">
    <div class="row items-start justify-start gap-x-sm gap-y-sm">
      <q-chip
        v-for="manufacturer in preferredManufacturers"
        class="q-ma-none"
        :key="manufacturer.id"
        :label="manufacturer.name"
        size="sm"
      />
    </div>
    <q-space />
    <div class="q-mt-md">
      <q-btn dense flat color="primary" @click="openManufacturerSelectDialog">
        {{ $t("inquiryPage.preferredManufacturers.select") }}
      </q-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import ManufacturerSelectDialog from "@/components/ManufacturerSelectDialog.vue";
import { useQuasar } from "quasar";
import { computed } from "vue";
import type { Inquiry } from "@/types/inquiry.ts";
import type { Inbox } from "@/types/inbox.ts";
import type { Customer } from "@/types/customer.ts";
import type { Manufacturer } from "@/types/manufacturer.ts";


const props = defineProps<{
  objectWithPreferredManufacturers: Inquiry | Inbox | Customer;
  objectType: "INQUIRY" | "INBOX" | "CUSTOMER";
}>();

const emit = defineEmits<{
  "update:preferred-manufacturers": [Manufacturer[]];
}>();

const q = useQuasar();

const preferredManufacturers = computed(() => {
  const manufacturers = props.objectWithPreferredManufacturers.preferredManufacturers ?? [];
  manufacturers.sort((a, b) => a.name.localeCompare(b.name));
  return manufacturers;
});

function openManufacturerSelectDialog() {
  q.dialog({
    component: ManufacturerSelectDialog,
    componentProps: {
      preferredManufacturers: preferredManufacturers.value,
      objectWithPreferredManufacturers: props.objectWithPreferredManufacturers,
      objectType: props.objectType
    },
  }).onOk((manufacturers: Manufacturer[]) => {
    emit("update:preferred-manufacturers", manufacturers);
  });
}
</script>
