import * as api from "@/api/inquiry";
import * as inquiryFileApi from "@/api/inquiryFile";
import { useRequestCancellation } from "@/composables/useRequestCancellation";
import { useRouteParams } from "@/composables/useRouteParams";
import { CLEAR_PREVIOUS_INQUIRY_AFTER_MS } from "@/config/constants";
import { useSearchFilterSortStore } from "@/stores/searchFilterSort";
import type { Customer } from "@/types/customer";
import {
  CAN_EDIT_BASE_INQUIRY_STATUSES,
  CAN_EDIT_INQUIRY_RELATED_VALUES_STATUSES,
  CAN_EDIT_POSITIONS_BASE_INQUIRY_STATUSES,
  UPDATING_BASE_INQUIRY_STATUSES,
  type Inquiry,
  type InquiryStatus,
  type ShortInquiry,
} from "@/types/inquiry";
import type { Manufacturer } from "@/types/manufacturer";
import type { Tag } from "@/types/tag";
import type { User } from "@/types/user";
import { defineStore } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const INQUIRY_POLLING_INTERVAL = 5000;

export const useCurrentInquiryStore = defineStore("currentInquiry", () => {
  const { organizationId, inboxId, inquiryId } = useRouteParams();
  const router = useRouter();
  const q = useQuasar();
  const { t } = useI18n();

  const inquiry = ref<Inquiry | null>(null);

  const is404 = ref(false);

  const { cancelsLoading, openLoadingRequests } = useRequestCancellation();

  const filterStore = useSearchFilterSortStore();

  async function loadData() {
    if (
      isNaN(organizationId.value) ||
      isNaN(inboxId.value) ||
      isNaN(inquiryId.value)
    ) {
      inquiry.value = null;
      return;
    }

    let isCancelled = false;
    const requestId = crypto.randomUUID();
    openLoadingRequests[requestId] = () => {
      isCancelled = true;
    };

    try {
      const apiQuery = filterStore.getApiQuery();
      let result;

      try {
        result = await api.getInquiry(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          apiQuery,
        );
      } catch (error: any) {
        if (isCancelled) return;
        if (error.response?.status === 404) {
          is404.value = true;
          return;
        }
        throw error;
      }

      if (isCancelled) return;
      is404.value = false;

      inquiry.value = result;

      // If the inquiry is in a status that requires polling, start polling
      if (
        UPDATING_BASE_INQUIRY_STATUSES.includes(
          inquiry.value?.status.originalStatus,
        )
      ) {
        setTimeout(() => {
          loadData();
        }, INQUIRY_POLLING_INTERVAL);
      }
    } finally {
      delete openLoadingRequests[requestId];
    }
  }

  loadData();
  watch(
    () => [organizationId.value, inboxId.value, inquiryId.value],
    async (newVal, oldVal) => {
      if (
        newVal[0] !== oldVal[0] ||
        newVal[1] !== oldVal[1] ||
        newVal[2] !== oldVal[2]
      ) {
        let hasLoaded = false;
        setTimeout(() => {
          if (!hasLoaded) {
            inquiry.value = null;
          }
        }, CLEAR_PREVIOUS_INQUIRY_AFTER_MS);
        await loadData();
        hasLoaded = true;
      }
    },
  );
  // Update if the route changes to the inquiry page
  watch(
    () => router.currentRoute.value.name,
    async (name, oldName) => {
      if (name === "inquiry" && oldName !== "inquiry") {
        await loadData();
      }
    },
  );

  async function baseUpdateStatus(status: InquiryStatus) {
    if (inquiry.value) inquiry.value.status = status;

    subscribeToNotifications();

    inquiry.value = await api.updateInquiry(inboxId.value, inquiryId.value, {
      status: status.name,
    });
  }

  async function baseUpdateAssignedUser(user: User | null) {
    if (inquiry.value) inquiry.value.assignedUser = user;

    subscribeToNotifications();

    inquiry.value = await api.updateInquiry(inboxId.value, inquiryId.value, {
      assignedUserId: user?.id || null,
    });
  }

  async function baseUpdateCustomer(customer: Customer | null) {
    if (inquiry.value) inquiry.value.customer = customer;

    subscribeToNotifications();

    inquiry.value = await api.updateInquiry(inboxId.value, inquiryId.value, {
      customerId: customer?.id || null,
    });
  }

  async function baseAddTag(tag: Tag) {
    if (inquiry.value && !inquiry.value.tags.includes(tag)) {
      inquiry.value.tags.push(tag);
    }

    await api.addTag(inboxId.value, inquiryId.value, tag.id);
    // TODO: error handling
  }

  async function baseRemoveTag(tag: Tag) {
    if (inquiry.value) {
      inquiry.value.tags = inquiry.value.tags.filter((t) => t.id !== tag.id);
    }

    await api.removeTag(inboxId.value, inquiryId.value, tag.id);
    // TODO: error handling
  }

  async function baseUpdateInquiry(update: Partial<Inquiry>) {
    if (inquiry.value) {
      Object.assign(inquiry.value, update);
    }

    subscribeToNotifications();

    inquiry.value = await api.updateInquiry(
      inboxId.value,
      inquiryId.value,
      update,
    );
  }

  async function baseRetryAnalysis() {
    subscribeToNotifications();

    await retryInquiryAnalysis(
      inquiry.value,
      organizationId.value,
      inboxId.value,
    );
  }

  async function baseRematchInquiry() {
    await doRematchInquiry(inquiry.value, organizationId.value, inboxId.value);
  }

  const updateStatus = cancelsLoading(baseUpdateStatus);
  const updateAssignedUser = cancelsLoading(baseUpdateAssignedUser);
  const updateCustomer = cancelsLoading(baseUpdateCustomer);
  const addTag = cancelsLoading(baseAddTag);
  const removeTag = cancelsLoading(baseRemoveTag);
  const updateInquiry = cancelsLoading(baseUpdateInquiry);
  const retryAnalysis = cancelsLoading(baseRetryAnalysis);
  const reanalysisInquiry = cancelsLoading(baseRematchInquiry);
  const canEditInquiryPositions = computed(
    () =>
      (inquiry.value &&
        CAN_EDIT_POSITIONS_BASE_INQUIRY_STATUSES.includes(
          inquiry.value.status.originalStatus,
        )) ||
      false,
  );

  const canEditInquiry = computed(
    () =>
      (inquiry.value &&
        CAN_EDIT_BASE_INQUIRY_STATUSES.includes(
          inquiry.value.status.originalStatus,
        )) ||
      false,
  );

  const canEditInquiryRelatedFields = computed(
    () =>
      (inquiry.value &&
        CAN_EDIT_INQUIRY_RELATED_VALUES_STATUSES.includes(
          inquiry.value.status.originalStatus,
        )) ||
      false,
  );

  async function moveToAnotherInbox(targetInboxId: number) {
    if (!inboxId.value || !inquiryId.value) throw Error("No inquiry loaded");

    subscribeToNotifications();

    const newInquiry = await api.moveInquiryToAnotherInbox(
      inboxId.value,
      inquiryId.value,
      targetInboxId,
    );

    inquiry.value = newInquiry;

    router.replace({
      name: "inquiry",
      params: {
        organizationId: organizationId.value,
        inboxId: targetInboxId,
        inquiryId: inquiryId.value,
      },
    });
  }

  async function subscribeToNotifications() {
    if (!inquiry.value) return;
    const currentInquiryId = inquiryId.value;
    await api.subscribeToNotifications(inboxId.value, inquiryId.value);
    if (inquiry.value?.id != currentInquiryId) return;
    inquiry.value.isSubscribed = true;
  }

  async function unsubscribeFromNotifications() {
    if (!inquiry.value) return;
    const currentInquiryId = inquiryId.value;
    await api.unsubscribeFromNotifications(inboxId.value, inquiryId.value);
    if (inquiry.value?.id != currentInquiryId) return;
    inquiry.value.isSubscribed = false;
  }

  async function updatePreferredManufacturers(manufacturers: Manufacturer[]) {
    if (inquiry.value) inquiry.value.preferredManufacturers = manufacturers;
    await api.updateInquiryPreferredManufacturers(
      inboxId.value,
      inquiryId.value,
      manufacturers,
    );
  }

  async function deleteInquiryFile(inquiryFileId: number) {
    if (!inquiry.value) throw new Error("No inquiry loaded");

    const inquiryFileIdx = inquiry.value.inquiryFiles.findIndex(
      (file) => file.id === inquiryFileId,
    );
    if (inquiryFileIdx === -1) throw new Error("Quotation file not found");

    const inquiryFile = inquiry.value.inquiryFiles[inquiryFileIdx];

    inquiry.value.inquiryFiles.splice(inquiryFileIdx, 1);
    try {
      await inquiryFileApi.deleteInquiryFile(inquiryId.value, inquiryFile.id);
    } catch (e) {
      inquiry.value.inquiryFiles.splice(inquiryFileIdx, 0, inquiryFile);
    }
  }

  async function renameInquiryFile(inquiryFileId: number, newFilename: string) {
    if (!inquiry.value) throw new Error("No inquiry loaded");

    const inquiryFileIdx = inquiry.value.inquiryFiles.findIndex(
      (file) => file.id === inquiryFileId,
    );
    if (inquiryFileIdx === -1) throw new Error("Quotation file not found");

    const inquiryFile = inquiry.value.inquiryFiles[inquiryFileIdx];
    const oldFilename = inquiryFile.filename;
    inquiryFile.filename = newFilename;

    try {
      await inquiryFileApi.renameInquiryFile(
        inquiryId.value,
        inquiryFile.id,
        newFilename,
      );
    } catch (e) {
      inquiry.value.inquiryFiles[inquiryFileIdx].filename = oldFilename;
    }
  }

  async function downloadInquiryFile(inquiryFileId: number) {
    if (!inquiry.value) throw new Error("No inquiry loaded");

    return inquiryFileApi.downloadInquiryFile(inquiryId.value, inquiryFileId);
  }

  async function updateInquirySuggestionsToCustomersPreferences() {
    if (!inquiry.value) return;
    inquiry.value = await api.setInquiryPreferredManufacturersFromCustomer(
      inboxId.value,
      inquiryId.value,
    );
  }

  async function warnIfInquiryHasFinalizationProblems(onOk: () => void) {
    if (!inquiry.value) return false;

    if (!inquiry.value.progress) throw new Error("Inquiry progress is not set");
    const notCompleted =
      inquiry.value.progress.completed < inquiry.value.progress.offered;
    const hasNoPositions = !(await api.hasPositions(
      inboxId.value,
      inquiryId.value,
    ));

    if (!notCompleted || !hasNoPositions) {
      onOk();
      return;
    }

    let message = "";

    if (notCompleted) {
      message += `<p>${t("warnIfInquiryHasFinalizationProblems.messages.inquiryNotCompleted")}</p>`;
    }

    if (hasNoPositions) {
      message += `<p>${t("warnIfInquiryHasFinalizationProblems.messages.inquiryHasNoPositions")}</p>`;
    }
    message += `<p>${t("warnIfInquiryHasFinalizationProblems.messages.doYouWantToContinue")}</p>`;

    q.dialog({
      title: t("warnIfInquiryHasFinalizationProblems.title"),
      message: message,
      html: true,
      ok: {
        dense: true,
        flat: true,
        label: t("warnIfInquiryHasFinalizationProblems.ok"),
      },
      cancel: {
        dense: true,
        primary: true,
        label: t("warnIfInquiryHasFinalizationProblems.cancel"),
      },
      persistent: true,
    }).onOk(onOk);
  }

  async function updateCustomField(
    customFieldId: number,
    value: string | null,
  ) {
    if (!inquiry.value) return;

    const localCustomField = inquiry.value.customFields.find(
      (field) => field.id === customFieldId,
    );
    if (!localCustomField) return;

    const oldValue = localCustomField.value;
    localCustomField.value = value;

    try {
      await api.updateInquiry(inboxId.value, inquiryId.value, {
        customFields: [
          {
            id: customFieldId,
            value: value,
          },
        ],
      });
    } catch (e) {
      localCustomField.value = oldValue;
    }
  }

  return {
    loadData,
    inquiry,
    is404,
    updateStatus,
    updateAssignedUser,
    updateCustomer,
    updateInquiry,
    updateCustomField,
    retryAnalysis,
    reanalysisInquiry,
    addTag,
    removeTag,
    canEditInquiryPositions,
    canEditInquiry,
    canEditInquiryRelatedFields,
    moveToAnotherInbox,
    subscribeToNotifications,
    unsubscribeFromNotifications,
    updatePreferredManufacturers,
    deleteInquiryFile,
    renameInquiryFile,
    downloadInquiryFile,
    updateInquirySuggestionsToCustomersPreferences,
    warnIfInquiryHasFinalizationProblems,
  };
});

export async function retryInquiryAnalysis(
  inquiry: Inquiry | ShortInquiry | null,
  organizationId: number,
  inboxId: number,
) {
  if (!inquiry) return;
  const previousStatus = inquiry.status;
  inquiry.status = {
    id: "ANALYZING",
    name: "ANALYZING",
    originalStatus: "ANALYZING",
    isSelectable: false,
    color: "neutral-6",
    custom: false,
  };

  try {
    await api.retryAnalysis(organizationId, inboxId, inquiry.id);
  } catch (e) {
    if (previousStatus) inquiry.status = previousStatus;
  }
}

export async function doRematchInquiry(
  inquiry: Inquiry | ShortInquiry | null,
  organizationId: number,
  inboxId: number,
) {
  if (!inquiry) return;
  const previousStatus = inquiry.status;
  inquiry.status = {
    id: "ANALYZING",
    name: "ANALYZING",
    originalStatus: "ANALYZING",
    isSelectable: false,
    color: "neutral-6",
    custom: false,
  };

  try {
    await api.reanalysisInquiry(organizationId, inboxId, inquiry.id);
  } catch (e) {
    if (previousStatus) inquiry.status = previousStatus;
  }
}
