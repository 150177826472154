<template>
  <q-menu ref="moreMenu">
    <q-list dense>
      <q-item dense clickable v-close-popup @click="$emit('delete')">
        <q-item-section>
          <div class="row items-center gap-sm no-wrap">
            <q-icon :name="icons.delete" color="neutral-5" size="20px" />
            {{ $t("supplierRfqsPage.deleteSupplierRfq") }}
          </div>
        </q-item-section>
      </q-item>
      <q-item dense clickable>
        <q-item-section>
          <div class="row items-center gap-sm no-wrap">
            <q-icon :name="icons.copy" color="neutral-5" size="20px" />
            {{ $t("supplierRfqsPage.copySupplierRfq") }}
          </div>
        </q-item-section>
        <supplier-select-menu
          @select="handleCopySupplierRfqSelect"
          :search-placeholder="
            $t('supplierRfqsPage.copySupplierRfqSearchPlaceholder')
          "
          :is-disabled-fn="
            (supplier: Supplier) =>
              store.hasDraftSupplierRfqForSupplierIdAndInquiryId(
                supplier.id,
                supplierRfq.inquiry.id,
              )
          "
          :disabled-hint="$t('supplierRfqsPage.copySupplierRfqDisabledHint')"
          anchor="top right"
          self="top left"
          :offset="[2, 0]"
        />
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import SupplierSelectMenu from "@/components/SupplierSelectMenu.vue";
import icons from "@/config/icons";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import type { Supplier } from "@/types/supplier";
import type { SupplierRFQ } from "@/types/supplierRfq";
import type { QMenu } from "quasar";
import { ref } from "vue";

defineProps<{
  supplierRfq: SupplierRFQ;
}>();

const emit = defineEmits<{
  delete: [];
  copy: [supplier: Supplier];
}>();

const moreMenu = ref<QMenu | null>(null);

const store = useCurrentSupplierRfqsStore();

const handleCopySupplierRfqSelect = (supplier: Supplier) => {
  emit("copy", supplier);
  moreMenu.value?.hide();
};
</script>
