<template>
  <select-menu
    :options="organizations"
    :selected-option="organization"
    :search-placeholder="$t('organizationSelectMenu.searchPlaceholder')"
    id-value="id"
    :search-values="['id', 'name']"
    :label-fn="(org: ShortOrganization) => `${org.id} - ${org.name}`"
    :max-options-displayed="10"
    :is-loading="isLoading"
    :sort-fn="(a, b) => a.id - b.id"
    @select="(org: ShortOrganization) => $emit('select', org.id)"
  />
</template>

<script setup lang="ts">
import { listOrganizations } from "@/api/organization";
import { useRouteParams } from "@/composables/useRouteParams";
import type { ShortOrganization } from "@/types/organization";
import { onMounted, ref } from "vue";
import SelectMenu from "./SelectMenus/SelectMenu.vue";

const isLoading = ref(false);
const organization = ref<ShortOrganization | null>(null);
const organizations = ref<ShortOrganization[]>([]);
const options = ref<ShortOrganization[]>([]);
const routeParams = useRouteParams();

onMounted(loadData);

async function loadData() {
  isLoading.value = true;

  try {
    organizations.value = await listOrganizations();
    options.value = organizations.value;
    organization.value =
      organizations.value.find(
        (o) => o.id == routeParams.organizationId.value,
      ) || null;
  } finally {
    isLoading.value = false;
  }
}

defineEmits<{
  select: [organizationId: number];
}>();
</script>
