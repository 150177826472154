import { getIdsQueryFromIdsList } from "@/api/inquiry";
import { offerPositionFromApi } from "@/api/offerPosition";
import type {
  ApiOfferPositionGroup,
  OfferPositionGroup,
} from "@/types/offerPositionGroup";
import type { ApiProductCandidate, ProductCandidate } from "@/types/product";
import { client } from "./client/client";
import { productFromApi } from "./product";

export async function getOfferPositionGroups(
  organizationId: number,
  inboxId: number,
  inquiryId: number
): Promise<OfferPositionGroup[]> {
  const response = await client.get<ApiOfferPositionGroup[]>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/`
  );
  return response.data.map(offerPositionGroupFromApi);
}

export async function moveOfferPositionGroupAfterAnother(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  firstId: number,
  secondId: number
): Promise<void> {
  await client.post(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/move_after/`,
    { firstId, secondId }
  );
}

export async function copyProducts(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  sourceGroupId: number,
  targetGroupId: number
): Promise<OfferPositionGroup> {
  const response = await client.post<ApiOfferPositionGroup>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${targetGroupId}/copy_products/`,
    undefined,
    { params: { source_id: sourceGroupId } }
  );
  return offerPositionGroupFromApi(response.data);
}

export async function updateOfferPositionGroup(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  data: Partial<OfferPositionGroup>
): Promise<OfferPositionGroup> {
  const response = await client.patch<ApiOfferPositionGroup>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/`,
    data
  );
  return offerPositionGroupFromApi(response.data);
}

export async function updateMultipleOfferPositionGroups(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  groupIds: number[],
  data: Partial<OfferPositionGroup>
) {
  const response = await client.patch<ApiOfferPositionGroup[]>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/update_field_for_multiple_opgs/` +
    getIdsQueryFromIdsList(groupIds),
    data
  );
  return response.data.map(offerPositionGroupFromApi);
}

export async function addOfferPositionGroup(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  afterId: number | null,
  data: Partial<OfferPositionGroup>
): Promise<OfferPositionGroup> {
  const response = await client.post<ApiOfferPositionGroup>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/`,
    {
      afterId,
      ...data,
    }
  );
  return offerPositionGroupFromApi(response.data);
}

export async function deleteOfferPositionGroup(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number
): Promise<void> {
  await client.delete<ApiOfferPositionGroup>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/`
  );
}

export async function updateVariantConfiguration(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  offerPositionId: number,
  variant: string,
  addons: { product: string; variant: string }[]
): Promise<OfferPositionGroup> {
  const response = await client.post<ApiOfferPositionGroup>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/update_variant_configuration/`,
    { offerPositionId, variant, addons }
  );
  return offerPositionGroupFromApi(response.data);
}

export async function addTag(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  tagId: number
): Promise<void> {
  await client.post<void>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/add_tag/`,
    { id: tagId }
  );
}

export async function removeTag(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  offerPositionGroupId: number,
  tagId: number
): Promise<void> {
  await client.post<void>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/${offerPositionGroupId}/remove_tag/`,
    { id: tagId }
  );
}

export async function addTagsToMultipleOpgs(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  opgIds: number[],
  tagIds: number[]
): Promise<void> {
  await client.post<void>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/add_tags_to_multiple_opgs/`,
    { opgIds, tagIds }
  );
}

export async function removeTagsFromMultipleOpgs(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  opgIds: number[],
  tagIds: number[]
): Promise<void> {
  await client.post<void>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/offer_position_groups/remove_tags_from_multiple_opgs/`,
    { opgIds, tagIds }
  );
}

function offerPositionGroupFromApi(
  data: ApiOfferPositionGroup
): OfferPositionGroup {
  return {
    ...data,
    boqAmount: data.boqAmount === null ? null : parseFloat(data.boqAmount),
    offerPositions: data.offerPositions.map(offerPositionFromApi),
    productCandidates: data.productCandidates.map(productCandidateFromApi),
  };
}

function productCandidateFromApi(data: ApiProductCandidate): ProductCandidate {
  return {
    ...data,
    product: productFromApi(data.product),
  };
}
