<template>
  <q-menu
    :offset="[0, 4]"
    ref="menuRef"
    @keydown.escape.stop="menuRef?.hide()"
    @keydown.stop
  >
    <q-card
      @keydown.stop.prevent.down="selectNext"
      @keydown.stop.prevent.up="selectPrevious"
      @keydown.stop.prevent.enter="
        selectedIndex !== null &&
        select(filteredOptions[selectedIndex] as Option)
      "
    >
      <div class="q-px-sm">
        <q-input
          borderless
          dense
          :placeholder="props.searchPlaceholder"
          v-model="searchText"
          autofocus
        />
      </div>
      <q-separator />
      <div>
        <q-list dense class="q-py-xs">
          <q-item v-if="showMinimumSearchLengthMessage">
            <q-item-section class="text-italic text-neutral-7">
              <q-item-label>
                {{
                  $t("asyncMultiSelectMenu.minSearchLength", {
                    minSearchLength,
                  })
                }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <template v-if="isLoading">
            <q-item>
              <q-item-section class="row no-wrap justify-center items-center">
                <q-circular-progress
                  indeterminate
                  size="20px"
                  color="primary"
                />
              </q-item-section>
            </q-item>
          </template>
          <template v-else>
            <template
              v-for="(option, index) in filteredOptions.slice(
                0,
                props.maxOptionsDisplayed,
              )"
              :key="option[idValue]"
            >
              <slot
                name="option"
                :option="option"
                :select="select"
                :index="index"
                :active="isActiveOption(option as Option)"
                :itemClass="{
                  'q-mx-xs': true,
                  'rounded-borders': true,
                  'q-pl-sm': true,
                  'q-pr-sm': true,
                  'bg-neutral-3': isActiveOption(option as Option),
                  'bg-neutral-2': selectedIndex === index,
                  'text-neutral-5': props.isDisabledFn(option as Option),
                  'cursor-not-allowed': props.isDisabledFn(option as Option),
                }"
              >
                <q-item
                  dense
                  :clickable="!props.isDisabledFn(option as Option)"
                  :v-ripple="!props.isDisabledFn(option as Option)"
                  :v-close-popup="!props.isDisabledFn(option as Option)"
                  @click.stop="select(option as Option)"
                  class="q-mx-xs rounded-borders force-small-x-padding"
                  :class="{
                    'bg-neutral-3': isActiveOption(option as Option),
                    'bg-neutral-2': selectedIndex === index,
                    'text-neutral-5': props.isDisabledFn(option as Option),
                    'cursor-not-allowed': props.isDisabledFn(option as Option),
                  }"
                >
                  <q-item-section>
                    <slot name="option-label" :option="option">
                      {{ props.labelFn(option as Option) }}
                    </slot>
                  </q-item-section>
                  <q-tooltip
                    v-if="
                      props.isDisabledFn(option as Option) &&
                      props.disabledHint.length
                    "
                    :offset="[0, 0]"
                  >
                    {{ props.disabledHint }}
                  </q-tooltip>
                </q-item>
              </slot>
            </template>
          </template>
        </q-list>
      </div>
    </q-card>
  </q-menu>
</template>

<script
  setup
  lang="ts"
  generic="
    IdKey extends string,
    Option extends { [key in IdKey]: string | number }
  "
>
import type { QMenu } from "quasar";
import { computed, ref, watch } from "vue";
import { useKeyboardSelection } from "./useKeyboardSelection";

const props = withDefaults(
  defineProps<{
    selectedOption: Option | null;
    preloadOptions?: Option[];
    searchPlaceholder: string;
    idValue: IdKey;
    findOptions: (searchText: string) => Promise<Option[]>;
    labelFn: (option: Option) => string;
    isDisabledFn?: (option: Option) => boolean;
    disabledHint?: string;
    maxOptionsDisplayed?: number;
    minSearchLength?: number;
  }>(),
  {
    maxOptionsDisplayed: 10,
    isDisabledFn: () => false,
    disabledHint: "",
    minSearchLength: 3,
    preloadOptions: () => [],
  },
);

const emit = defineEmits<{
  select: [option: Option];
}>();

const searchText = ref<string | null>(null);
const menuRef = ref<typeof QMenu | null>(null);

const filteredOptions = ref<Option[]>(props.preloadOptions);
const isLoading = ref(false);
watch(searchText, async () => {
  if (searchText.value && searchText.value.length >= props.minSearchLength) {
    isLoading.value = true;
    try {
      filteredOptions.value = await props.findOptions(searchText.value!);
    } finally {
      isLoading.value = false;
    }
  } else {
    filteredOptions.value = [];
  }
});

const { selectedIndex, selectNext, selectPrevious } =
  useKeyboardSelection(filteredOptions);

const showMinimumSearchLengthMessage = computed(() => {
  return (
    searchText.value &&
    searchText.value.length > 0 &&
    searchText.value.length < props.minSearchLength
  );
});

function isActiveOption(option: Option) {
  return (
    props.selectedOption &&
    option[props.idValue] === props.selectedOption[props.idValue]
  );
}

async function select(option: Option) {
  if (props.isDisabledFn(option)) {
    return;
  }

  emit("select", option);

  // Wait for events to be processed before hiding the menu, otherwise it will reopen.
  // This is a workaround for a bug in Quasar.
  await new Promise((resolve) => setTimeout(resolve, 100));

  searchText.value = null;
  menuRef.value?.hide();
}
</script>

<style scoped>
.force-small-x-padding {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
