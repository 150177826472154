<template>
  <async-select-menu
    :selectedOption="null"
    :searchPlaceholder="$t('supplierSelectMenu.searchPlaceholder')"
    idValue="id"
    :findOptions="findSuppliers"
    :labelFn="(supplier: Supplier) => supplier.name"
    @select="$emit('select', $event)"
  >
    <template #option-label="{ option }">
      <supplier-label :supplier="option" />
    </template>
  </async-select-menu>
</template>

<script setup lang="ts">
import { findSuppliers as apiFindSuppliers } from "@/api/supplier";
import AsyncSelectMenu from "@/components/SelectMenus/AsyncSelectMenu.vue";
import SupplierLabel from "@/components/SupplierLabel.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Supplier } from "@/types/supplier";

const { organizationId } = useRouteParams();

async function findSuppliers(searchText: string) {
  return await apiFindSuppliers(organizationId.value, searchText);
}

defineEmits<{
  select: [targetSupplier: Supplier];
}>();
</script>
