type HasToString = {
  toString: () => string;
};

export function filterOptions<
  SearchKey extends string | number | symbol,
  Option extends Record<SearchKey, HasToString>,
>(
  options: Option[],
  searchText: string,
  searchKeys: SearchKey[],
  sortFn: (optionA: Option, optionB: Option) => number,
) {
  if (!searchText) {
    return options;
  }
  const searchTextLower = searchText.toLowerCase();

  const startsWithMatches = options.filter((option) =>
    searchKeys.some((value) =>
      option[value].toString().toLowerCase().startsWith(searchTextLower),
    ),
  );
  startsWithMatches.sort(sortFn);

  const containsMatches = options.filter((option) =>
    searchKeys.some(
      (value) =>
        !startsWithMatches.includes(option) &&
        option[value].toString().toLowerCase().includes(searchTextLower),
    ),
  );
  containsMatches.sort(sortFn);
  return [...startsWithMatches, ...containsMatches];
}
