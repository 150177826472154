<template>
  <router-link
    :to="{
      name: 'inquiry-positions',
      query: { ...$route.query, preselectGroupIds: opgs.map((o) => o.id) },
    }"
    class="color-inherit"
  >
    <i18n-t
      v-if="shownOpgs.length > 0 && remainingOpgs.length > 0"
      tag="span"
      keypath="inquiryPage.inquiryHistory.positionsList.positionsWithAdditional"
    >
      <template #shownOpgs>
        {{ shownOpgsString }}
      </template>
      <template #furtherPositions>
        <span>
          {{
            $t("inquiryPage.inquiryHistory.positionsList.numFurtherPositions", {
              n: remainingOpgs.length,
            })
          }}
        </span>
      </template>
    </i18n-t>
    <i18n-t
      v-else-if="shownOpgs.length > 0"
      tag="span"
      keypath="inquiryPage.inquiryHistory.positionsList.positionsWithoutAdditional"
    >
      <template #shownOpgs>
        {{ shownOpgsString }}
      </template>
    </i18n-t>
    <i18n-t
      v-else
      tag="span"
      keypath="inquiryPage.inquiryHistory.positionsList.numPositions"
    >
      <template #n>
        {{ props.opgs.length }}
      </template>
    </i18n-t>
  </router-link>
</template>

<script setup lang="ts">
import type { InquiryHistoryEntryPositionsEditedOpg } from "@/types/inquiryHistory";
import { computed } from "vue";

const props = defineProps<{
  opgs: InquiryHistoryEntryPositionsEditedOpg[];
}>();

const shownOpgs = computed(() =>
  props.opgs.filter((opg) => opg.boqReferenceId).slice(0, 3),
);
const shownOpgsString = computed(() =>
  shownOpgs.value.map((opg) => opg.boqReferenceId).join(", "),
);

const remainingOpgs = computed(() =>
  props.opgs.filter(
    (opg) => !shownOpgs.value.map((o) => o.id).includes(opg.id),
  ),
);
</script>
