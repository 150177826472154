<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="manufacturerPreferences" />
    <div v-if="inbox" class="q-pa-md">
      <preferred-manufacturer-selection
        :object-with-preferred-manufacturers="inbox"
        object-type="INBOX"
        @update:preferred-manufacturers="(preferredManufacturers: Manufacturer[]) => {
          if (inbox) inbox.preferredManufacturers = preferredManufacturers;
        }"
      />
    </div>
    <q-skeleton v-else />
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import PreferredManufacturerSelection
  from "@/components/InquiryPage/InquiryValuesDisplay/PreferredManufacturerSelection.vue";
import type { Inbox } from "@/types/inbox.ts";
import { ref, watch } from "vue";
import { useInboxesStore } from "@/stores/inboxes.ts";
import type { Manufacturer } from "@/types/manufacturer.ts";


const store = useInboxesStore();

const { organizationId, inboxId } = useRouteParams();

const inbox = ref<Inbox | null>(null);
watch(
  () => [organizationId.value, inboxId.value],
  async () => {
    inbox.value = await store.getInbox(inboxId.value);
  },
  { immediate: true }
);


</script>
