<template>
  <router-link
    :to="{
      name: 'inquiry',
      params: {
        inboxId: quotation.inquiry.inboxId,
        inboxView: 'finalized',
        inquiryId: quotation.inquiry.id,
      },
    }"
    class="no-decoration text-neutral-10 full-width"
  >
    <div
      class="quotation-row row no-wrap items-center full-width q-px-md q-py-xs gap-md"
    >
      <div class="col__quotation-id column no-wrap items-end">
        <div># {{ quotation.serialId }}</div>
        <div class="text-neutral-7 text-xs">
          {{ quotation.inquiry.shortCode }}
        </div>
      </div>
      <quotation-dates :quotation="quotation" class="col__quotation-dates" />
      <div class="col__quotation-customer column no-wrap items-start">
        <div class="full-width ellipsis">
          {{ quotation.customerName }}
        </div>
        <div
          v-if="quotation.customerExternalId"
          class="text-neutral-7 text-xs full-width ellipsis"
        >
          {{ quotation.customerExternalId }}
        </div>
      </div>
      <div class="col__quotation-building-project">
        {{ quotation.buildingProject }}
      </div>
      <div class="col__quotation-tags row items-center justify-end gap-xs">
        <tag-chip v-for="tag in quotation.tags" :key="tag.id" :tag="tag" />
      </div>
      <div class="col__quotation-value column no-wrap items-end">
        <div>
          <currency-display :value="quotation.totalPriceNet" />
          <q-tooltip :delay="1000">{{
            $t("quotation.totalPriceNet")
          }}</q-tooltip>
        </div>
        <div class="text-neutral-7 text-xs">
          {{
            $t("quotation.positionCountNum", { count: quotation.positionCount })
          }}
        </div>
      </div>
      <div class="row no-wrap items-center gap-xs">
        <q-btn
          flat
          dense
          color="neutral-7"
          icon="sym_r_download"
          v-catch-clicks
        >
          <q-tooltip :delay="1000">{{
            $t("quotationsPage.downloadQuotation")
          }}</q-tooltip>
          <q-menu>
            <q-list dense>
              <q-item
                v-for="format in Object.values(InquiryFileFormat)"
                :key="format"
                clickable
                @click="downloadQuotation(quotation.inquiry.id, format)"
              >
                <q-item-section>
                  {{ format }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <quotation-status-button
          :status="quotation.status"
          icon-only
          @update:status="updateQuotationStatus"
          v-catch-clicks
        />
        <multi-inbox-user-select-button
          :user="quotation.assignedUser"
          :inbox-id="quotation.inquiry.inboxId"
          @update:user="updateAssignedUser"
          icon-only
          v-catch-clicks
        />
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { downloadQuotation } from "@/api/draftQuotation";
import CurrencyDisplay from "@/components/CurrencyDisplay.vue";
import TagChip from "@/components/Tags/TagChip.vue";
import MultiInboxUserSelectButton from "@/components/User/MultiInboxUserSelectButton.vue";
import { useCurrentQuotationsStore } from "@/stores/currentQuotations";
import { InquiryFileFormat } from "@/types/inquiryFile";
import { type Quotation, type QuotationStatus } from "@/types/quotation";
import type { User } from "@/types/user";
import QuotationDates from "./QuotationDates.vue";
import QuotationStatusButton from "./QuotationStatusButton.vue";

const props = defineProps<{
  quotation: Quotation;
}>();

const store = useCurrentQuotationsStore();

function updateQuotationStatus(status: QuotationStatus) {
  store.updateQuotation(props.quotation.id, { status });
}

function updateAssignedUser(user: User | null) {
  store.updateQuotation(props.quotation.id, {
    assignedUser: user,
  });
}
</script>

<style scoped lang="scss">
.quotation-row {
  &:hover {
    background-color: $neutral-1;
  }
}
.col__quotation-id {
  width: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}
.col__quotation-dates {
  width: 140px;
  flex-shrink: 0;
  flex-grow: 0;
}
.col__quotation-customer {
  flex-shrink: 0;
  flex-grow: 0;
  width: 200px;
}
.col__quotation-building-project {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.col__quotation-tags {
  flex-shrink: 1;
  flex-grow: 1;

  @media (max-width: $breakpoint-md-max) {
    display: none;
  }
}
.col__quotation-value {
  width: 120px;
  flex-shrink: 0;
  flex-grow: 0;
}
</style>
