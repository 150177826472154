<template>
  <div class="column items-stretch gap-y-md">
    <analyzed-in-different-inbox-banner />
    <inquiry-values-banner
      v-if="inquiry && inquiry.status.originalStatus == 'FINALIZED'"
      icon="sym_r_lock"
      color="positive"
      :title="$t('inquiryPage.finalizedBanner.title')"
      :text="$t('inquiryPage.finalizedBanner.message')"
    />
    <inquiry-values-banner
      v-if="inquiry && inquiry.status.originalStatus == 'ANALYZING'"
      icon="sym_r_hourglass"
      color="warning"
      :title="$t('inquiryPage.analyzingBanner.title')"
      :text="$t('inquiryPage.analyzingBanner.message')"
    />
    <inquiry-values-banner
      v-if="inquiry && inquiry.status.originalStatus == 'ANALYSIS_ERROR'"
      icon="sym_r_error"
      color="negative"
      :title="$t('inquiryPage.analysisErrorBanner.title')"
      :text="$t('inquiryPage.analysisErrorBanner.message')"
    />
    <possible-duplicates-banner
      v-if="
        inquiry &&
        inquiry.possibleDuplicates &&
        inquiry.possibleDuplicates.length > 0
      "
      :possibleDuplicates="inquiry.possibleDuplicates"
    />
    <div>
      <div v-if="inquiry && inbox">
        <tags-display
          :available-tags="inbox.availableTags"
          :tags="inquiry.tags"
          @add-tag="addTag"
          @remove-tag="removeTag"
        />
      </div>
      <q-skeleton style="height: 40px" v-else />
    </div>
    <div>
      <div v-if="inquiry">
        <q-input
          v-model="buildingProject"
          dense
          outlined
          :label="$t('inquiry.buildingProject')"
          :disable="!canEditInquiry"
          @blur="updateBuildingProject"
        />
        <cannot-edit-tooltip
          :inquiry="inquiry"
          :canEditInquiry="canEditInquiry"
        />
      </div>
      <q-skeleton style="height: 40px" v-else />
    </div>
    <div class="row no-wrap gap-x-md justify-stretch full-width">
      <div v-if="inquiry" class="col">
        <date-input
          v-model="requestedSubmissionDate"
          dense
          outlined
          :label="$t('inquiry.requestedSubmissionDate')"
          :disable="!canEditInquiry"
        />
        <cannot-edit-tooltip
          :inquiry="inquiry"
          :canEditInquiry="canEditInquiry"
        />
      </div>
      <q-skeleton style="height: 40px" v-else />
      <div v-if="inquiry" class="col">
        <date-input
          v-if="inquiry"
          v-model="requestedCustomerSubmissionDate"
          dense
          outlined
          :label="$t('inquiry.requestedCustomerSubmissionDate')"
          :disable="!canEditInquiry"
        />
        <cannot-edit-tooltip
          :inquiry="inquiry"
          :canEditInquiry="canEditInquiry"
        />
      </div>
      <q-skeleton style="height: 40px" v-else />
    </div>
    <div
      v-if="
        organization?.showCustomerId ||
        organization?.useCustomerData ||
        organization?.showBuildingProjectId ||
        inquiry?.customFields
      "
      class="row gap-md justify-stretch full-width"
    >
      <div v-if="organization?.showCustomerId" class="col min-w-45">
        <q-input
          v-model="externalCustomerId"
          dense
          outlined
          :label="$t('inquiry.customerId')"
          :disable="!canEditInquiry"
          @blur="updateExternalCustomerId"
        />
      </div>
      <div v-if="organization?.useCustomerData" class="col min-w-45">
        <div v-if="inquiry">
          <single-customer-input
            ref="customerInputEl"
            :inquiry="inquiry"
            :customer="customer!"
            :disable="!canEditInquiry"
            @update:customer="updateCustomerValue($event)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @keydown.stop.left
            @keydown.stop.right
            @keydown.stop.escape="customerInputEl?.blur()"
          />
          <cannot-edit-tooltip
            :inquiry="inquiry"
            :canEditInquiry="canEditInquiry"
          />
        </div>
        <q-skeleton style="height: 40px" v-else />
      </div>
      <div v-if="organization?.showBuildingProjectId" class="col min-w-45">
        <div v-if="inquiry">
          <q-input
            v-model="buildingProjectId"
            dense
            outlined
            :label="$t('inquiry.buildingProjectId')"
            :disable="!canEditInquiry"
            @blur="updateBuildingProjectId"
          />
          <cannot-edit-tooltip
            :inquiry="inquiry"
            :canEditInquiry="canEditInquiry"
          />
        </div>
        <q-skeleton style="height: 40px" v-else />
      </div>
      <div v-if="inquiry?.customFields" class="col min-w-45">
        <inquiry-custom-fields-display />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DateInput from "@/components/DateInput.vue";
import SingleCustomerInput from "@/components/InquiryPage/InquiryValuesDisplay/SingleCustomerInput.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { Customer } from "@/types/customer";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import AnalyzedInDifferentInboxBanner from "../AnalyzedInDifferentInboxBanner.vue";
import CannotEditTooltip from "./CannotEditTooltip.vue";
import InquiryCustomFieldsDisplay from "./InquiryCustomFieldsDisplay.vue";
import InquiryValuesBanner from "./InquiryValuesBanner.vue";
import PossibleDuplicatesBanner from "./PossibleDuplicatesBanner.vue";
import TagsDisplay from "./TagsDisplay.vue";
const { inbox } = storeToRefs(useCurrentInboxStore());

const store = useCurrentInquiryStore();
const { inquiry, canEditInquiry } = storeToRefs(store);
const { updateInquiry, updateCustomer, addTag, removeTag } = store;

const { organization } = storeToRefs(useCurrentOrganizationStore());
const buildingProject = ref(inquiry.value?.buildingProject);
const buildingProjectId = ref(inquiry.value?.buildingProjectId);
const externalCustomerId = ref(inquiry.value?.externalCustomerId);
const customer = ref(inquiry.value?.customer);
const requestedSubmissionDate = ref(inquiry.value?.requestedSubmissionDate);
const requestedCustomerSubmissionDate = ref(
  inquiry.value?.requestedCustomerSubmissionDate,
);
const customerInputEl = ref<typeof SingleCustomerInput | null>(null);
const q = useQuasar();
const { t } = useI18n();

watch(
  () => inquiry.value?.buildingProject,
  (value) => {
    buildingProject.value = value;
  },
);
watch(
  () => inquiry.value?.buildingProjectId,
  (value) => {
    buildingProjectId.value = value;
  },
);
watch(
  () => inquiry.value?.customer,
  (value) => {
    customer.value = value;
  },
);
watch(
  () => inquiry.value?.externalCustomerId,
  (value) => {
    externalCustomerId.value = value;
  },
);
watch(
  () => inquiry.value?.requestedSubmissionDate,
  (value) => {
    requestedSubmissionDate.value = value;
  },
);
watch(
  () => inquiry.value?.requestedCustomerSubmissionDate,
  (value) => {
    requestedCustomerSubmissionDate.value = value;
  },
);

async function updateBuildingProject() {
  await updateInquiry({ buildingProject: buildingProject.value });
  buildingProject.value = inquiry.value?.buildingProject;
}

async function updateBuildingProjectId() {
  await updateInquiry({ buildingProjectId: buildingProjectId.value });
  buildingProjectId.value = inquiry.value?.buildingProjectId;
}

async function updateCustomerValue(newCustomer: Customer | null) {
  await updateCustomer(newCustomer);
  customer.value = inquiry.value?.customer;
  if (
    organization.value?.useManufacturerPreferences &&
    customer.value?.preferredManufacturers
  ) {
    q.dialog({
      title: t("inquiry.updateInquirySuggestionsToCustomersPreferences.title"),
      message: t(
        "inquiry.updateInquirySuggestionsToCustomersPreferences.message",
      ),
      ok: {
        dense: true,
        label: t("Yes"),
      },
      cancel: {
        flat: true,
        dense: true,
        color: "neutral-7",
        label: t("No"),
      },
    }).onOk(async () => {
      await store.updateInquirySuggestionsToCustomersPreferences();
    });
  }
}

async function updateRequestedSubmissionDate() {
  if (
    requestedSubmissionDate.value === inquiry.value?.requestedSubmissionDate
  ) {
    return;
  }

  await updateInquiry({
    requestedSubmissionDate: requestedSubmissionDate.value,
  });
  requestedSubmissionDate.value = inquiry.value?.requestedSubmissionDate;
}

watch(requestedSubmissionDate, updateRequestedSubmissionDate);

async function updateExternalCustomerId() {
  await updateInquiry({ externalCustomerId: externalCustomerId.value });
  externalCustomerId.value = inquiry.value?.externalCustomerId;
}

async function updateRequestedCustomerSubmissionDate() {
  if (
    requestedCustomerSubmissionDate.value ===
    inquiry.value?.requestedCustomerSubmissionDate
  ) {
    return;
  }
  await updateInquiry({
    requestedCustomerSubmissionDate: requestedCustomerSubmissionDate.value,
  });
  requestedCustomerSubmissionDate.value =
    inquiry.value?.requestedCustomerSubmissionDate;
}

watch(requestedCustomerSubmissionDate, updateRequestedCustomerSubmissionDate);
</script>

<style scoped lang="scss">
.min-w-45 {
  min-width: 45% !important;
}
</style>
