export enum InquiryFileFormat {
    PDF = "PDF",
    P94 = "P94",
    X94 = "X94",
  }
  
  export interface InquiryFile {
    id: number;
    filename: string;
    fileFormat: InquiryFileFormat | null;
    isAttachment: boolean;
  
    temporaryId?: string;
  }
  