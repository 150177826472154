import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { ReactiveSet } from "@/utils/reactiveSet";
import { range } from "lodash";
import { storeToRefs } from "pinia";
import { computed, inject, provide, ref, watch } from "vue";
import { useRouteParams } from "../useRouteParams";

const selectionSymbol = Symbol("positionsTableSelection");

export interface PositionsTableSelection {
  selectedGroupIds: ReactiveSet<number>;
  selectedIndices: Readonly<{
    value: number[];
  }>;
  select: (index: number | null) => void;
  toggleSelectIndex: (index: number) => void;
  extendSelectIndex: (index: number) => void;
  selectNext: () => void;
  selectPrevious: () => void;
  extendSelectNext: () => void;
  extendSelectPrevious: () => void;
  unselect: () => void;
  selectAll: () => void;
  selectGroupIds: (groupIds: number[]) => void;
}

export function provideSelection(): PositionsTableSelection {
  const opgStore = useCurrentOfferPositionGroupsStore();
  const { visibleOfferPositionGroups } = storeToRefs(opgStore);
  const { getVisibleGroupIndex: getIndex, getVisibleGroupId: getId } = opgStore;

  const getIndices = (ids: number[]) =>
    ids.map(getIndex).filter((index) => index !== null) as number[];

  const getIds = (indices: number[]) =>
    indices.map(getId).filter((id) => id !== null) as number[];

  const numRows = computed(() => visibleOfferPositionGroups.value?.length || 0);

  const selectedGroupIds = new ReactiveSet<number>();
  const selectedIndices = computed(() => getIndices(selectedGroupIds.values));

  // Make sure only visible groups are selected
  watch(visibleOfferPositionGroups, (visibleGroups) => {
    selectedGroupIds.replace(
      visibleGroups
        ?.map((group) => group.id)
        .filter((id) => selectedGroupIds.has(id)) || []
    );
  });

  // Remember previously selected indices to handle up / down arrow key presses based on previous selection
  const previouslySelectedIndices = ref<number[]>([]);

  // Reset selection when inquiryId changes (i.e. when switching between inquiries)
  const { inquiryId } = useRouteParams();
  watch(inquiryId, () => {
    selectedGroupIds.clear();
    previouslySelectedIndices.value = [];
  });

  function select(index: number | null) {
    const groupId = getId(index);
    if (groupId === null) {
      selectedGroupIds.clear();
      return;
    }

    selectedGroupIds.replace([groupId]);
  }

  function toggleSelectIndex(index: number) {
    const groupId = getId(index);
    if (groupId === null) return;
    selectedGroupIds.toggle(groupId);
  }

  function extendSelectIndex(index: number) {
    if (selectedGroupIds.size === 0) {
      select(index);
      return;
    }

    const lastIndexAbove = Math.max(
      ...selectedIndices.value.filter((i) => i < index)
    );

    let newlySelectedGroupIds: number[] = [];

    if (lastIndexAbove === -Infinity) {
      // extend selection upwards
      newlySelectedGroupIds = getIds(range(index, getFirstSelectedIndex()));
    } else {
      // extend selection downwards
      newlySelectedGroupIds = getIds(range(lastIndexAbove + 1, index + 1));
    }

    selectedGroupIds.union(newlySelectedGroupIds);
  }

  function selectNext() {
    const currentIndex = getLastSelectedIndex();
    select((currentIndex + 1) % numRows.value);
  }

  function selectPrevious() {
    const currentIndex = getFirstSelectedIndex();
    select((currentIndex - 1 + numRows.value) % numRows.value);
  }

  function extendSelectNext() {
    const currentIndex = getLastSelectedIndex();
    extendSelectIndex((currentIndex + 1) % numRows.value);
  }

  function extendSelectPrevious() {
    const currentIndex = getFirstSelectedIndex();
    extendSelectIndex((currentIndex - 1 + numRows.value) % numRows.value);
  }

  function getFirstSelectedIndex() {
    if (selectedIndices.value.length > 0) {
      return Math.min(...selectedIndices.value);
    }

    if (previouslySelectedIndices.value.length > 0) {
      return Math.min(...previouslySelectedIndices.value);
    }

    return -1;
  }

  function getLastSelectedIndex() {
    if (selectedIndices.value.length > 0) {
      return Math.max(...selectedIndices.value);
    }

    if (previouslySelectedIndices.value.length > 0) {
      return Math.max(...previouslySelectedIndices.value);
    }

    return -1;
  }

  function unselect() {
    previouslySelectedIndices.value = selectedIndices.value;
    select(null);
  }

  function selectAll() {
    selectedGroupIds.replace(
      visibleOfferPositionGroups.value?.map((group) => group.id) || []
    );
  }

  function selectGroupIds(groupIds: number[]) {
    selectedGroupIds.replace(groupIds);
  }

  const selection: PositionsTableSelection = {
    selectedGroupIds,
    selectedIndices,
    select,
    toggleSelectIndex,
    extendSelectIndex,
    selectNext,
    selectPrevious,
    extendSelectNext,
    extendSelectPrevious,
    unselect,
    selectAll,
    selectGroupIds,
  };

  provide(selectionSymbol, selection);
  return selection;
}

export const useSelection = () => {
  const selection = inject<PositionsTableSelection>(selectionSymbol);
  if (!selection) {
    throw new Error("PositionsTableSelection not provided");
  }
  return selection;
};
