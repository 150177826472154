<template>
  <div class="column no-wrap gap-y-sm full-width">
    <div
      v-for="field in inquiry?.customFields"
      :key="field.id"
      class="row no-wrap gap-x-md justify-stretch full-width"
    >
      <q-input
        v-if="!field.choices?.length"
        v-model="field.value"
        dense
        outlined
        class="full-width"
        :label="field.displayName"
        :disable="!canEditInquiry"
        @blur="updateCustomField(field, field.value)"
      />
      <select-input
        v-else
        class="full-width"
        :label="field.displayName"
        :selected-option="
          field.choices.find((c) => c.value === field.value) ?? null
        "
        :options="field.choices"
        id-value="value"
        :label-fn="(value) => String(value.label)"
        :search-placeholder="
          $t('inquiryCustomFieldsDisplay.searchPlaceholder', {
            displayName: field.displayName,
          })
        "
        :search-values="['value', 'label']"
        :disable="!canEditInquiry"
        @update:selected-option="
          (option) => updateCustomField(field, option?.value ?? null)
        "
        @clear="updateCustomField(field, null)"
      />
      <cannot-edit-tooltip
        :inquiry="inquiry"
        :canEditInquiry="canEditInquiry"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectInput from "@/components/SelectMenus/SelectInput.vue";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import type { InquiryCustomField } from "@/types/inquiry";
import { storeToRefs } from "pinia";
import CannotEditTooltip from "./CannotEditTooltip.vue";

const store = useCurrentInquiryStore();
const { inquiry, canEditInquiry } = storeToRefs(store);

async function updateCustomField(
  field: InquiryCustomField,
  value: string | null,
) {
  if (!inquiry.value) return;

  await store.updateCustomField(field.id, value);
}
</script>
