<template>
  <q-page class="fit" :style-fn="() => ''">
    <header-bar>
      <master-data-search-bar v-model="searchTerm" class="q-ml-md" />
    </header-bar>
    <master-data-table
      square
      class="col page-below-header-bar"
      :is-loading="isLoading"
      :columns="columns"
      :data="data"
      :page-size="pageSize"
      :page="page"
      :total="total"
      :search-text="searchTerm || ''"
      @next-page="nextPage"
      @previous-page="previousPage"
      @request="handleTableRequest"
    />
  </q-page>
</template>

<script setup lang="ts">
import * as api from "@/api/customer";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import MasterDataSearchBar from "@/components/MasterDataPage/MasterDataSearchBar.vue";
import MasterDataTable from "@/components/MasterDataPage/MasterDataTable.vue";
import { useMasterDataPage } from "@/composables/useMasterDataPage";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import type { QTableProps } from "quasar";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const columns = computed(() => {
  const columns: QTableProps["columns"] = [
    {
      name: "externalId",
      label: t("ID"),
      align: "left",
      field: "externalId",
      sortable: false,
    },
    {
      name: "name",
      label: t("Name"),
      align: "left",
      field: "name",
      sortable: false,
      style:
        "max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "street",
      label: t("Street"),
      align: "left",
      field: "street",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "postCode",
      label: t("Post code"),
      align: "left",
      field: "postCode",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "town",
      label: t("Town"),
      align: "left",
      field: "town",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "region",
      label: t("Region"),
      align: "left",
      field: "region",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "countryCode",
      label: t("Country code"),
      align: "left",
      field: "countryCode",
      sortable: false,
      style:
        "max-width: 50px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "vatTaxId",
      label: t("VAT tax ID"),
      align: "left",
      field: "vatTaxId",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "salesOffice",
      label: t("Sales office"),
      align: "left",
      field: "salesOffice",
      sortable: false,
      style:
        "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
    },
    {
      name: "isActive",
      label: t("Active"),
      align: "left",
      field: "isActive",
      sortable: false,
      format: (val: boolean) => (val ? t("Yes") : t("No")),
    },
  ];

  if (organization.value?.useManufacturerPreferences) {
    columns.push({
      name: "preferredManufacturers",
      label: t("Manufacturer preferences"),
      align: "left",
      field: "preferredManufacturers",
      sortable: false,
    });
  }

  return columns;
});

const {
  isLoading,
  data,
  pageSize,
  page,
  total,
  searchTerm,
  nextPage,
  previousPage,
  handleTableRequest,
} = useMasterDataPage(api.listCustomers);
</script>
