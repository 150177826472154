<template>
  <q-expansion-item
    dense
    :label="inbox.name"
    v-model="isOpen"
    header-class="no-focus-helper sidebar-inbox-group"
  >
    <template #header>
      <q-item-section>
        <div class="row no-wrap full-width">
          <div class="q-pl-lg row no-wrap items-center full-width">
            <slot name="label">
              <div class="sidebar-menu-item-label ellipsis" :title="inbox.name">
                {{ inbox.name }}
              </div>
            </slot>
          </div>
        </div>
      </q-item-section>
    </template>
    <sidebar-item
      inset
      label="settings.inbox.general.label"
      :to="{
        name: 'settings-inbox-general',
        params: { inboxId: inbox.id },
      }"
    />
    <sidebar-item
      inset
      label="settings.inbox.email.label"
      :to="{
        name: 'settings-inbox-email',
        params: { inboxId: inbox.id },
      }"
    />
    <sidebar-item
      inset
      label="settings.inbox.members.label"
      :to="{
        name: 'settings-inbox-members',
        params: { inboxId: inbox.id },
      }"
    />
    <sidebar-item
      inset
      label="settings.inbox.tags.label"
      :to="{
        name: 'settings-inbox-tags',
        params: { inboxId: inbox.id },
      }"
    />
    <sidebar-item
      inset
      label="settings.inbox.customStatuses.label"
      :to="{
        name: 'settings-inbox-custom-statuses',
        params: { inboxId: inbox.id },
      }"
    />
    <sidebar-item
      inset
      label="settings.inbox.automationRules.label"
      :to="{
        name: 'settings-inbox-automation-rules',
        params: { inboxId: inbox.id },
      }"
    />
    <sidebar-item
      v-if="organization?.useManufacturerPreferences"
      inset
      label="settings.inbox.manufacturerPreferences.label"
      :to="{
        name: 'settings-inbox-manufacturer-preferences',
        params: { inboxId: inbox.id },
      }"
    />
  </q-expansion-item>
</template>

<script setup lang="ts">
import { useRouteParams } from "@/composables/useRouteParams";
import type { ShortInbox } from "@/types/inbox";
import { ref, watch } from "vue";
import SidebarItem from "./SidebarItem.vue";
import { storeToRefs } from "pinia";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";

const props = defineProps<{
  inbox: ShortInbox;
}>();

const isOpen = ref(false);

const { organization } = storeToRefs(useCurrentOrganizationStore());

const { inboxId } = useRouteParams();
watch(
  inboxId,
  (value) => {
    if (value === props.inbox.id) {
      isOpen.value = true;
    }
  },
  { immediate: true },
);
</script>

<style lang="scss">
.force-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
</style>
