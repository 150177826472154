<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="customStatuses" />
    <q-linear-progress indeterminate class="q-mb-sm" v-if="isUpdating" />
    <q-skeleton v-if="isLoading" />
    <q-list v-show="!isLoading" separator ref="customStatusesListEl">
      <q-item v-for="customStatus in customStatuses" :key="customStatus.id">
        <div class="custom-status-drag-handle-container">
          <q-icon name="sym_r_drag_indicator" color="neutral-7" size="16px" />
        </div>
        <q-item-section avatar>
          <q-avatar
            :style="`background-color: ${customStatus.color}`"
            size="sm"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row items-baseline">
            <div class="text-sm">{{ customStatus.name }}</div>
            <div class="q-ml-sm text-xs text-neutral-7">
              ({{ $t(customStatus.originalStatus) }})
            </div>
          </q-item-label>
        </q-item-section>
        <q-item-section side v-if="canWrite">
          <div class="row items-center">
            <q-btn
              dense
              flat
              size="sm"
              icon="sym_r_edit"
              @click="editCustomStatus(customStatus)"
              aria-label="Edit customStatus"
              :disable="isUpdating"
            />
            <q-btn
              dense
              flat
              size="sm"
              icon="sym_r_delete"
              @click="deleteCustomStatus(customStatus)"
              aria-label="Delete customStatus"
              class="q-ml-sm"
              :disable="isUpdating"
            />
          </div>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="row justify-end gap-sm q-mt-md">
      <q-btn
        v-if="canWrite"
        dense
        flat
        color="primary"
        :label="$t('settings.inbox.customStatuses.copyCustomStatuses')"
        :disable="isUpdating"
      >
        <inbox-select-menu
          anchor="bottom middle"
          self="top middle"
          @select="confirmCopyCustomStatuses"
        />
      </q-btn>
      <q-btn
        v-if="canWrite"
        dense
        color="primary"
        :label="$t('settings.inbox.customStatuses.createCustomStatus')"
        @click="createCustomStatus"
        :disable="isUpdating"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/customStatus";
import InboxSelectMenu from "@/components/InboxSelectMenu.vue";
import CreateOrEditCustomStatusDialog from "@/components/Settings/Inbox/CreateOrEditCustomStatusDialog.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import type { CustomStatus } from "@/types/customStatus";
import type { NamedInbox } from "@/types/inbox";
import {
  moveArrayElement,
  useSortable,
} from "@vueuse/integrations/useSortable";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const customStatuses = ref<CustomStatus[]>([]);
const isLoading = ref(false);
const isUpdating = ref(false);

const { organizationId, inboxId } = useRouteParams();

const { hasCurrentInboxRole } = storeToRefs(useCurrentPermissionsStore());
const canWrite = computed(() => hasCurrentInboxRole.value["inbox_admin"]);

const inboxStore = useCurrentInboxStore();

const { t } = useI18n();

async function loadData() {
  isLoading.value = true;

  try {
    customStatuses.value = await api.listCustomStatuses(inboxId.value);
    initSortable();
  } finally {
    isLoading.value = false;
  }
}
watch([organizationId, inboxId], loadData, { immediate: true });

const customStatusesListEl = ref<HTMLUListElement>();

function initSortable() {
  useSortable(customStatusesListEl.value, customStatuses.value, {
    handle: ".custom-status-drag-handle-container",
    animation: 150,
    onUpdate: handleSortableUpdate,
  });
}

async function handleSortableUpdate(event: any) {
  moveArrayElement(customStatuses.value, event.oldIndex, event.newIndex);

  try {
    await api.moveCustomStatus(inboxId.value, event.oldIndex, event.newIndex);
  } catch (error) {
    moveArrayElement(customStatuses.value, event.newIndex, event.oldIndex);
    return;
  }

  await inboxStore.loadData();
}

const q = useQuasar();

function editCustomStatus(customStatus: CustomStatus) {
  q.dialog({
    component: CreateOrEditCustomStatusDialog,
    componentProps: { customStatus },
  }).onOk(async (data: Omit<CustomStatus, "id">) => {
    isUpdating.value = true;
    try {
      await api.updateCustomStatus(inboxId.value, customStatus.id, data);
    } finally {
      isUpdating.value = false;
      await Promise.all([loadData(), inboxStore.loadData()]);
    }
  });
}

function deleteCustomStatus(customStatus: CustomStatus) {
  q.dialog({
    title: t("settings.inbox.customStatuses.confirmDelete.title", {
      name: customStatus.name,
    }),
    message: t("settings.inbox.customStatuses.confirmDelete.message", {
      name: customStatus.name,
    }),
    cancel: {
      label: t("Cancel"),
      flat: true,
      dense: true,
    },
    ok: {
      label: t("settings.inbox.customStatuses.confirmDelete.ok"),
      color: "negative",
      dense: true,
    },
    persistent: true,
  }).onOk(async () => {
    isUpdating.value = true;
    try {
      await api.deleteCustomStatus(inboxId.value, customStatus.id);
    } finally {
      isUpdating.value = false;
      await Promise.all([loadData(), inboxStore.loadData()]);
    }
  });
}

function createCustomStatus() {
  q.dialog({
    component: CreateOrEditCustomStatusDialog,
  }).onOk(async (data: Omit<CustomStatus, "id">) => {
    isUpdating.value = true;
    try {
      await api.createCustomStatus(inboxId.value, data);
    } finally {
      isUpdating.value = false;
      await Promise.all([loadData(), inboxStore.loadData()]);
    }
  });
}

function confirmCopyCustomStatuses(sourceInbox: NamedInbox) {
  q.dialog({
    title: t("settings.inbox.customStatuses.confirmCopyCustomStatuses.title"),
    message: t(
      "settings.inbox.customStatuses.confirmCopyCustomStatuses.message",
      { sourceInbox: sourceInbox.name },
    ),
    cancel: {
      label: t("Cancel"),
      flat: true,
      dense: true,
    },
    ok: {
      label: t("settings.inbox.customStatuses.confirmCopyCustomStatuses.ok"),
      color: "primary",
      dense: true,
    },
    persistent: true,
  }).onOk(async () => {
    isUpdating.value = true;
    try {
      const { ignoredCustomStatuses } =
        await api.copyCustomStatusesFromOtherInbox(
          sourceInbox.id,
          inboxId.value,
        );

      if (ignoredCustomStatuses.length > 0) {
        q.notify({
          message: t(
            "settings.inbox.customStatuses.confirmCopyCustomStatuses.ignoredCustomStatuses",
            {
              ignoredCustomStatuses: ignoredCustomStatuses.join(", "),
            },
          ),
          color: "warning-7",
        });
      }
    } finally {
      isUpdating.value = false;
      await Promise.all([loadData(), inboxStore.loadData()]);
    }
  });
}
</script>

<style lang="scss">
.custom-status-drag-handle-container {
  cursor: move;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
  align-items: center;
}

div:hover > .custom-status-drag-handle-container {
  display: flex;
}
</style>
