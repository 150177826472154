<template>
  <div
    class="q-px-md q-py-xs row no-wrap gap-x-sm items-center rounded-borders"
    :class="{ 'bg-neutral-2': isHighlighted }"
    :id="`inquiry-history-entry-${entry.id}`"
  >
    <inquiry-history-entry-avatar :entry="entry" />
    <div class="text-neutral-7">
      <i18n-t
        v-if="entry.type == 'ASSIGNED_USER'"
        :keypath="assignedUserKeypath"
        tag="span"
        scope="global"
      >
        <template #user>
          <inquiry-history-entry-user-label :entry="entry" />
        </template>
        <template v-if="entry.type == 'ASSIGNED_USER'" #assignedUserName>
          <UserLabel
            :user="entry.extraData.newAssignedUser"
            class="history-highlight"
          />
        </template>
      </i18n-t>
      <i18n-t
        v-else
        :keypath="`inquiryPage.inquiryHistory.events.${entry.type}`"
        tag="span"
        scope="global"
      >
        <template #user>
          <UserLabel
            v-if="entry.user"
            :user="entry.user"
            class="history-highlight"
          />
          <span
            v-else-if="['STATUS_CHANGED', 'CREATED'].includes(entry.type)"
            >{{ $t("inquiryPage.inquiryHistory.systemUser") }}</span
          >
          <span v-else>{{ $t("inquiryPage.inquiryHistory.unknownUser") }}</span>
        </template>
        <template v-if="entry.type == 'STATUS_CHANGED'" #oldStatus>
          <span class="history-highlight">
            {{ $t(entry.extraData.oldStatus.name) }}
          </span>
        </template>
        <template v-if="entry.type == 'STATUS_CHANGED'" #newStatus>
          <span class="history-highlight">
            {{ $t(entry.extraData.newStatus.name) }}
          </span>
        </template>
        <template
          v-if="entry.type == 'MOVED_TO_ANOTHER_INBOX'"
          #targetInboxName
        >
          <span class="history-highlight">{{ targetInboxName }}</span>
        </template>
        <template v-if="entry.type == 'COPIED'" #copyShortCode>
          <router-link
            :to="{
              name: 'inquiry',
              params: { inquiryId: entry.extraData.copyId },
            }"
          >
            <span class="history-highlight">
              {{ entry.extraData.copyShortCode }}
            </span>
          </router-link>
        </template>
        <template v-if="entry.type == 'CREATED_AS_COPY'" #originalShortCode>
          <router-link
            :to="{
              name: 'inquiry',
              params: { inquiryId: entry.extraData.originalId },
            }"
            ><span class="history-highlight">
              {{ entry.extraData.originalShortCode }}
            </span>
          </router-link>
        </template>
        <template v-if="entry.type == 'POSITIONS_EDITED'" #positions>
          <inquiry-history-entry-positions-list :opgs="entry.extraData.opgs" />
        </template>
        <template
          v-if="
            ['SUPPLIER_RFQ_CREATED', 'SUPPLIER_RFQ_SENT'].includes(entry.type)
          "
          #supplierRfq
        >
          <router-link
            :to="{
              name: 'inquiry-supplier-rfqs.current-supplier-rfq',
              params: {
                supplierRfqId: (entry.extraData as SupplierRfqExtraData)
                  .supplierRfqId,
              },
            }"
            class="color-inherit"
          >
            {{
              $t("inquiryPage.inquiryHistory.supplierRfq", {
                supplierName: (entry.extraData as SupplierRfqExtraData)
                  .supplierName,
              })
            }}
          </router-link>
        </template>
        <template
          v-if="entry.type == 'SUPPLIER_RFQ_STATUS_CHANGED'"
          #supplierRfqForStatusChange
        >
          <router-link
            :to="{
              name: 'inquiry-supplier-rfqs.current-supplier-rfq',
              params: {
                supplierRfqId: entry.extraData.supplierRfqId,
              },
            }"
            class="color-inherit"
          >
            {{
              $t("inquiryPage.inquiryHistory.supplierRfqForStatusChange", {
                supplierName: entry.extraData.supplierName,
              })
            }}
          </router-link>
        </template>
        <template
          v-if="entry.type == 'SUPPLIER_RFQ_STATUS_CHANGED'"
          #newRfqStatus
        >
          {{ $t(`supplierRfqStatus.${entry.extraData.newStatus}`) }}
        </template>
        <template
          v-if="entry.type == 'QUOTATION_STATUS_CHANGED'"
          #oldQuotationStatus
        >
          {{ $t(`quotationStatus.${entry.extraData.oldStatus}`) }}
        </template>
        <template
          v-if="entry.type == 'QUOTATION_STATUS_CHANGED'"
          #newQuotationStatus
        >
          <span class="history-highlight">
            {{ $t(`quotationStatus.${entry.extraData.newStatus}`) }}
          </span>
        </template>
      </i18n-t>
      <span class="text-xs">
        &bull;
        <relative-date :date="entry.createdAt" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import RelativeDate from "@/components/RelativeDate.vue";
import UserLabel from "@/components/User/UserLabel.vue";
import type {
  InquiryHistoryEntry as InquiryHistoryEntryType,
  SupplierRfqExtraData,
} from "@/types/inquiryHistory";
import { computed } from "vue";
import { useRouter } from "vue-router";
import InquiryHistoryEntryAvatar from "./InquiryHistoryEntryAvatar.vue";
import InquiryHistoryEntryPositionsList from "./InquiryHistoryEntryPositionsList.vue";
import InquiryHistoryEntryUserLabel from "./InquiryHistoryEntryUserLabel.vue";

const props = defineProps<{
  entry: InquiryHistoryEntryType;
}>();

const targetInboxName = computed(() => {
  if (props.entry.type !== "MOVED_TO_ANOTHER_INBOX") return "";
  return props.entry.extraData.toInbox.name;
});

const assignedUserKeypath = computed(() => {
  if (props.entry.type !== "ASSIGNED_USER") return "";
  const { extraData, user } = props.entry;
  const assignType =
    extraData.newAssignedUser === null
      ? "none"
      : extraData.newAssignedUser.id === user?.id
        ? "self"
        : "other";
  return `inquiryPage.inquiryHistory.events.ASSIGNED_USER.${assignType}`;
});

const { currentRoute } = useRouter();
const isHighlighted = computed(() => {
  return currentRoute.value.hash === `#inquiry-history-entry-${props.entry.id}`;
});
</script>

<style scoped lang="scss">
.history-highlight {
  color: $neutral-10;
  font-weight: 600;
}
</style>
