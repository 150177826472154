import type { DocumentFileType } from "@/types/document";
import icons from "@/config/icons";
import { InquiryFileFormat } from "@/types/inquiryFile";

export function getDocumentTypeIcon(documentType: DocumentFileType) {
  if (documentType === "GAEB") {
    return icons.documentTypes.gaeb;
  }
  return icons.documentTypes.pdf;
}

export function getInquiryFileFormatIcon(
  inquiryFileFormat: InquiryFileFormat | null
) {
  if (inquiryFileFormat === "PDF") {
    return icons.documentTypes.pdf;
  }
  return icons.documentTypes.gaeb;
}
