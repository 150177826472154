import { withReauth as requireReauth } from "@/api/auth";
import { clientWithoutErrorNotifications } from "@/api/client/client";
import { isAxiosError } from "axios";

export interface TOTPStatus {
  canSetup: boolean;
  isActive: boolean;
  secret: string | null;
  totpUrl: string | null;
}
export class TOTPActivationWrongCodeError extends Error {
  constructor(message: string = "Wrong TOTP code") {
    super(message);
    this.name = "TOTPActivationWrongCodeError";
  }
}

export async function getTOTPStatus(): Promise<TOTPStatus> {
  try {
    await clientWithoutErrorNotifications.get(
      "/allauth/browser/v1/account/authenticators/totp",
    );
    // If we get here, the request succeeded (200) so TOTP is enabled.
    return {
      canSetup: true,
      isActive: true,
      secret: null,
      totpUrl: null,
    };
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response?.status === 404) {
      const { secret, totpUrl } = error.response.data.meta;
      return {
        canSetup: true,
        isActive: false,
        secret,
        totpUrl,
      };
    } else if (isAxiosError(error) && error.response?.status === 409) {
      return {
        canSetup: false,
        isActive: false,
        secret: null,
        totpUrl: null,
      };
    }
    throw error;
  }
}

export async function activateTOTP(code: string) {
  try {
    await requireReauth(() =>
      clientWithoutErrorNotifications.post(
        "/allauth/browser/v1/account/authenticators/totp",
        {
          code,
        },
      ),
    );
  } catch (error: unknown) {
    if (
      isAxiosError(error) &&
      error.response?.status === 400 &&
      error.response?.data?.errors[0].code === "incorrect_code"
    ) {
      throw new TOTPActivationWrongCodeError();
    }
    throw error;
  }
}

export async function deactivateTOTP(): Promise<void> {
  await requireReauth(() =>
    clientWithoutErrorNotifications.delete(
      "/allauth/browser/v1/account/authenticators/totp",
    ),
  );
}
