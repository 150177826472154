import router from "@/router";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { EnumerateAuthnOptionsResponse } from "@/types/authn";
import axios from "axios";
import {
  clientWithoutErrorNotifications,
  showHttpErrorNotification,
} from "./client/client";
import { CSRF_HEADER_NAME, getCsrfToken } from "./client/csrf";

export class MfaRequiredForLogin extends Error {
  constructor(message?: string) {
    super(message || "MFA required for login");
    this.name = "MfaRequiredForLogin";
  }
}

export class AlreadyLoggedIn extends Error {
  constructor(message?: string) {
    super(message || "Already logged in");
    this.name = "AlreadyLoggedIn";
  }
}

export class ReauthenticationRequired extends Error {
  constructor(message?: string) {
    super(message || "Reauthentication required");
    this.name = "ReauthenticationRequired";
  }
}

export class IncorrectMfaCode extends Error {
  constructor(message?: string) {
    super(message || "Incorrect MFA code");
    this.name = "IncorrectMfaCode";
  }
}

/** Login to the application.
 *
 * Returns true if login was successful and false if it was not successful
 *
 * NOTE: We use axios directly instead of the default HTTP client from client.ts since the default
 * client intercepts HTTP non-authorized errors
 *
 */
export async function login(email: string, password: string): Promise<boolean> {
  try {
    await axios.post<{}>(
      "/api/allauth/browser/v1/auth/login",
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          [CSRF_HEADER_NAME]: getCsrfToken(),
        },
      },
    );

    const userStore = useCurrentUserStore();
    await userStore.loadUser();

    return true;
  } catch (e: any) {
    if (e.response?.status === 401) {
      // Check if the response indicates that MFA is required.
      if (
        e.response.data?.data?.flows &&
        Array.isArray(e.response.data.data.flows)
      ) {
        const mfaFlow = e.response.data.data.flows.find(
          (flow: any) =>
            flow.id === "mfa_authenticate" && flow.is_pending === true,
        );
        if (mfaFlow) {
          // Throw a custom error so that the UI can handle MFA authentication.
          throw new MfaRequiredForLogin();
        }
      }
      return false;
    } else if (e.response?.status === 409) {
      throw new AlreadyLoggedIn();
    } else if (e.response?.status === 403) {
      return false;
    }
    showHttpErrorNotification(e);
    throw e;
  }
}

export async function withReauth<T>(fn: () => Promise<T>): Promise<T> {
  try {
    return await fn();
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const flows = error.response.data?.data?.flows;
      if (Array.isArray(flows)) {
        // Note: There may be other allowed flows to reauthenticate. Each single one is acceptable.
        // We check for password-based reauthentication here.
        const reauthFlow = flows.find(
          (flow: any) => flow.id === "reauthenticate",
        );
        if (reauthFlow) {
          throw new ReauthenticationRequired("Reauthentication required");
        }
      }
    }
    throw error;
  }
}

export async function requestPasswordReset(email: string) {
  await axios.post(
    "/api/allauth/browser/v1/auth/password/request",
    { email },
    {
      headers: { [CSRF_HEADER_NAME]: getCsrfToken() },
    },
  );
}

export async function confirmResetPassword(key: string, password: string) {
  await axios.post(
    "/api/allauth/browser/v1/auth/password/reset",
    {
      key: key,
      password: password,
    },
    {
      headers: { [CSRF_HEADER_NAME]: getCsrfToken() },
    },
  );
}

export async function logout() {
  try {
    await axios.delete("/api/allauth/browser/v1/auth/session", {
      headers: { [CSRF_HEADER_NAME]: getCsrfToken() },
    });
  } catch (e: any) {
    // The DELETE session call returns 401 even for a successful logout.
    // We need to catch that error to make sure the user is redirected.
    //
    // Needs to be fixed in the backend.
    if (e.response?.status !== 401) {
      throw e;
    }
  }

  const userStore = useCurrentUserStore();
  userStore.clear();

  await router.push({ name: "login" });
}

export async function authenticateMFA(code: string) {
  const response = await axios.post(
    "/api/allauth/browser/v1/auth/2fa/authenticate",
    {
      code,
    },
    {
      headers: {
        "Content-Type": "application/json",
        [CSRF_HEADER_NAME]: getCsrfToken(),
      },
    },
  );
  if (response.status === 400 && response.data?.errors) {
    if (
      response.data?.errors.find(
        (error: any) => error.code === "incorrect_code",
      )
    ) {
      throw new IncorrectMfaCode();
    }
  }
}

export async function reauthenticate(password: string): Promise<boolean> {
  try {
    await axios.post(
      "/api/allauth/browser/v1/auth/reauthenticate",
      { password },
      {
        headers: {
          "Content-Type": "application/json",
          [CSRF_HEADER_NAME]: getCsrfToken(),
        },
      },
    );
    return true;
  } catch (e: any) {
    if (e.response?.status === 400) {
      // Check if the error indicates an incorrect password
      if (
        e.response.data?.errors &&
        Array.isArray(e.response.data.errors) &&
        e.response.data.errors.find(
          (err: any) => err.code === "incorrect_password",
        )
      ) {
        return false;
      }
    }
    showHttpErrorNotification(e);
    throw e;
  }
}

export async function changePassword(
  currentPassword: string,
  newPassword: string,
) {
  await clientWithoutErrorNotifications.post(
    "/allauth/browser/v1/account/password/change",
    {
      current_password: currentPassword,
      new_password: newPassword,
    },
  );
}

export async function enumerateAuthnOptions(email: string) {
  const response =
    await clientWithoutErrorNotifications.get<EnumerateAuthnOptionsResponse>(
      "/authn_options/",
      { params: { email } },
    );
  return response.data;
}

export async function loginWithSso(provider: string, callbackUrl: string) {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = "/api/allauth/browser/v1/auth/provider/redirect";
  form.style.display = "none";

  const csrfToken = getCsrfToken();
  if (!csrfToken) {
    throw new Error("CSRF token not found");
  }

  const formFields = {
    provider: provider,
    callback_url: callbackUrl,
    process: "login",
    csrfmiddlewaretoken: csrfToken,
  };

  for (const [key, value] of Object.entries(formFields)) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  document.body.appendChild(form);

  form.submit();
}
