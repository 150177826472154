import { watchIgnorable } from "@vueuse/core";
import { debounce } from "lodash";
import type { Ref } from "vue";
import { ref, watch } from "vue";

export function debounceRef<T>(ref_: Ref<T>, debounceMs: number): Ref<T> {
  const localRef = ref(ref_.value);

  const { ignoreUpdates } = watchIgnorable(ref_, () => {
    localRef.value = ref_.value;
  });

  watch(localRef, debounce(() => {
    ignoreUpdates(() => {
      ref_.value = localRef.value;
    });
  },
    debounceMs,
  ),
  );
  return ref_;
}