<template>
  <q-input
    no-error-icon
    hide-bottom-space
    v-model="stringValue"
    :mask="mask"
    :placeholder="placeholder"
    clearable
    :disable="disable"
    v-bind="$attrs"
    :error="errorMessage !== undefined"
    @clear="$emit('update:model-value', null); errorMessage = undefined"
  >
    <template v-slot:append>
      <q-icon
        v-if="errorMessage"
        color="negative"
        name="sym_r_error"
        class="cursor-pointer"
      >
        <q-tooltip>
          {{ errorMessage }}
        </q-tooltip>
      </q-icon>
    </template>
    <q-menu no-focus :offset="[0, 4]" v-model="showMenu">
      <q-date v-model="isoStringValue" minimal @mousedown.prevent />
    </q-menu>
  </q-input>
</template>

<script setup lang="ts">
import dateFormatStrings from "@/i18n/dateFormatStrings";
import { format, parse } from "date-fns";
import type { QInput, QMenu } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  modelValue: Date | null | undefined;
  disable?: boolean;
}>();
const emit = defineEmits<{
  "update:model-value": [date: Date | null | undefined];
}>();

const { locale, t } = useI18n();
const dateFormat = computed<string>(() => {
  const format =
    dateFormatStrings[locale.value as keyof typeof dateFormatStrings];
  if (!format)
    throw new Error("No date format found for locale " + locale.value);
  return format;
});
const errorMessage = ref<string | undefined>(undefined);
const stringValue = ref<string | null>(
  props.modelValue ? format(props.modelValue, dateFormat.value) : null,
);
const showMenu = ref(false);

watch(
  () => props.modelValue,
  (newValue) => {
    errorMessage.value = undefined;
    if (!newValue) {
      stringValue.value = null;
      return;
    }
    const formatted = format(newValue, dateFormat.value);
    if (formatted !== stringValue.value) {
      stringValue.value = formatted;
    }
  },
);

watch(stringValue, (value) => {
  if (props.disable) return;
  if (!value) {
    emit("update:model-value", null);
    return;
  }

  if (value.length < dateFormat.value.length) {
    // input not finished
    errorMessage.value = undefined;
    return;
  }

  const date = parse(value, dateFormat.value, new Date());
  if (!isValidDate(date)) {
    errorMessage.value = t("dateInput.notValidDate");
    return;
  }
  errorMessage.value = undefined;
  if (date.getTime() === props.modelValue?.getTime()) return;
  emit("update:model-value", date);
});

function isValidDate(date: Date): boolean {
  return date && date.getFullYear() >= 1900 && !isNaN(date.getTime());
}

const mask = computed(() => {
  if (!dateFormat.value) return undefined;
  return dateFormat.value.replace(/[a-zA-Z]/g, "#");
});

const placeholder = computed(() => {
  if (!dateFormat.value) return undefined;
  return dateFormat.value.replace(/[a-zA-Z]/g, "_");
});

const isoFormat = "yyyy/MM/dd";

const isoStringValue = computed<string | null>({
  get: () => {
    if (!props.modelValue) return null;
    return format(props.modelValue, isoFormat);
  },
  set: (value) => {
    showMenu.value = false;
    if (!value) {
      emit("update:model-value", null);
      return;
    }
    const date = parse(value, isoFormat, new Date());
    emit("update:model-value", date);
  },
});
</script>

<style scoped lang="scss">
.date-input-el {
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}
</style>
