<template>
  <q-input
    :rules="[validateNotEmpty]"
    class="q-mt-md"
    dense
    outlined
    name="url"
    v-model="url"
    :label="$t('URL')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="headerName"
    v-model="headerName"
    :label="$t('Header name')"
    @update:model-value="updateConfiguration"
    :rules="[validateHeaderName]"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="headerValue"
    v-model="headerValue"
    :label="$t('Header value')"
    @update:model-value="updateConfiguration"
    autocomplete="off"
  />
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { keyable } from "@/types/organization";
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";

const props = defineProps<{
  configuration: keyable;
}>();

const url = ref<string>(props.configuration.url);
const headerName = ref<string>(props.configuration.headerName);
const headerValue = ref<string>(props.configuration.headerValue);
const { validateNotEmpty, validateHeaderName } = useFormValidationRules();

const emits = defineEmits<{
  "update:configuration": [configuration: keyable];
}>();

const updateConfiguration = () => {
  emits("update:configuration", {
    url: url.value,
    headerName: headerName.value,
    headerValue: headerValue.value,
  });
};
</script>

<style scoped lang="scss"></style>
