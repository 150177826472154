<template>
  <q-btn flat dense align="right" class="tag-button">
    <tags-circles :tags="group.tags" />
    <multi-tag-select-menu
      :selected-tags="selectedTags"
      :partially-selected-tags="partiallySelectedTags"
      @select="addTag"
      @unselect="removeTag"
    />
    <q-tooltip v-if="!group.tags.length" :delay="1000" class="text-center">
      {{ $t("inquiryPositionsPage.offerPositionGroup.addTags") }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import MultiTagSelectMenu from "@/components/Tags/MultiTagSelectMenu.vue";
import TagsCircles from "@/components/Tags/TagsCircles.vue";
import { useTags } from "@/composables/positionsTable/useTags";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Tag } from "@/types/tag";

const {
  selectedTags,
  partiallySelectedTags,
  addTagsToSelectedOpgs,
  removeTagsFromSelectedOpgs,
} = useTags();

defineProps<{
  group: OfferPositionGroup;
}>();

async function addTag(tag: Tag) {
  await addTagsToSelectedOpgs([tag]);
}

async function removeTag(tag: Tag) {
  await removeTagsFromSelectedOpgs([tag]);
}
</script>

<style scoped>
.tag-button {
  padding: 2px;
  min-width: 42px;
  font-weight: inherit;
  font-size: inherit;

  .add-tags-label {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover .add-tags-label {
    opacity: 1;
  }
}
</style>
