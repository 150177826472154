<template>
  <q-page class="wrapper">
    <div class="box">
      <img class="logo q-mb-lg" src="@/assets/logo/kinisto-logo.svg" />
      <template v-if="isSignupSent">
        <div class="text-body1 text-center full-width q-mb-md">
          {{
            $t("Signup successful. You can now login with your new password.")
          }}
        </div>
        <router-link
          class="full-width text-center q-mt-md"
          :to="{ name: 'login' }"
        >
          {{ $t("Go to login") }}
        </router-link>
      </template>
      <template v-else-if="isValidLink">
        <div class="text-body1 text-center full-width q-mb-md">
          {{ $t("Create your kinisto account") }}
        </div>
        <q-form v-if="isValidLink" @submit="acceptInvitation">
          <q-input
            dense
            outlined
            disable
            v-model="email"
            :label="$t('user.email')"
            class="q-mb-lg"
          />
          <q-input
            dense
            outlined
            v-model="firstName"
            :label="$t('user.firstName')"
            class="q-mb-sm"
            autofocus
            lazy-rules
            :rules="[validateNotEmpty]"
          />
          <q-input
            dense
            outlined
            v-model="lastName"
            :label="$t('user.lastName')"
            class="q-mb-sm"
            lazy-rules
            :rules="[validateNotEmpty]"
          />
          <set-password-form
            :user-inputs="[firstName, lastName]"
            @update:password="password = $event"
            @update:errorStatus="isPasswordValid = !$event"
          />
          <q-banner
            rounded
            class="bg-negative text-white q-mb-md"
            v-if="isSignupFailed"
            style="white-space: pre-wrap"
          >
            {{ signupFailedMessage }}
            <template v-slot:action>
              <q-btn
                flat
                color="white"
                :label="$t('Dismiss')"
                @click="isSignupFailed = false"
              />
            </template>
          </q-banner>
          <q-btn
            v-if="!isSignupSent"
            unelevated
            color="primary"
            type="submit"
            class="full-width q-mt-xs"
            :label="$t('Create account')"
            :disable="!firstName || !lastName || !isPasswordValid"
          />
        </q-form>
      </template>
      <template v-else>
        <div class="q-mb-md">{{ $t("Invalid signup link.") }}</div>
        <div class="q-mb-md">{{ $t("Please request a new link.") }}</div>
      </template>
      <q-inner-loading :showing="isLoading">
        <q-spinner size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </q-page>
</template>

<script setup lang="ts">
import { getCsrfCookie } from "@/api/client/csrf";
import { acceptInvitation as apiAcceptInvitation } from "@/api/user";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";
import SetPasswordForm from "@/components/SetPasswordForm.vue";

const { t } = useI18n();

const router = useRouter();

const firstName = ref("");
const lastName = ref("");
const password = ref("");
const isLoading = ref(false);
const isSignupSent = ref(false);
const isSignupFailed = ref(false);
const isPasswordValid = ref(false);
const signupFailedMessage = ref("");

const code = computed(() => router.currentRoute.value.query.code as string);
const secret = computed(() => router.currentRoute.value.query.secret as string);
const email = computed(() => router.currentRoute.value.query.email as string);
const isValidLink = computed(
  () => code.value && secret.value && code.value.length && secret.value.length
);
const { validateNotEmpty } = useFormValidationRules();

// Make sure CSRF cookie is set before resetting password
getCsrfCookie();

async function acceptInvitation(): Promise<void> {
  isLoading.value = true;
  isSignupFailed.value = false;
  isSignupSent.value = false;
  try {
    await apiAcceptInvitation(
      code.value,
      secret.value,
      firstName.value,
      lastName.value,
      password.value
    );
    isSignupSent.value = true;
  } catch (e: any) {
    isSignupFailed.value = true;

    const serverResponse = e.response?.data;

    if (serverResponse) {
      if (serverResponse.nonFieldErrors) {
        signupFailedMessage.value = serverResponse.nonFieldErrors
          .map(t)
          .join("\n\n");
      } else {
        signupFailedMessage.value = Object.entries(serverResponse)
          .map(([key, value]) => {
            const errorsList = value as string[];
            const fieldError = errorsList.map(t).join(" ");
            return `${t(key)}: ${fieldError}`;
          })
          .join("\n\n");
      }
    } else {
      signupFailedMessage.value = t(
        "Signup failed. Please try again or contact your administrator."
      );
    }
  } finally {
    isLoading.value = false;
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral-1;
}
.box {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: stretch;
  justify-content: center;
  background-color: #fff;
  border: 1px solid $neutral;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 24rem;
  flex-grow: 0;
  flex-shrink: 1;
}
.logo {
  height: 2rem;
}
</style>
@/composables/formValidation/useFormValidationRules @/api/client/csrf
