import type { Tag } from "./tag";
import type { ShortUser } from "./user";

export interface DraftQuotation {
  recipientName: string;
  recipientAddress: string;
  salutation: string;
  closing: string;
  html: string;
  isFinal: boolean;
}

export type QuotationStatus = "CREATED" | "ACCEPTED" | "DECLINED";

export const QUOTATION_STATUS_OPTIONS: QuotationStatus[] = [
  "ACCEPTED",
  "DECLINED",
  "CREATED",
];

export interface Quotation {
  id: number;
  serialId: number;
  inquiry: {
    id: number;
    shortCode: string;
    inboxId: number;
  };
  createdAt: Date;
  acceptedOrDeclinedAt: Date | null;
  totalPriceNet: number;
  positionCount: number;
  tags: Tag[];
  assignedUser: ShortUser | null;
  customerExternalId?: string | null;
  customerName: string;
  buildingProject: string;
  status: QuotationStatus;
}

export interface QuotationWrite {
  assignedUserId: number | null;
  status: QuotationStatus;
  acceptedOrDeclinedAt: Date | null;
}

export interface ApiQuotationWrite
  extends Omit<QuotationWrite, "acceptedOrDeclinedAt"> {
  acceptedOrDeclinedAt: string | null;
}
