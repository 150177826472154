<template>
  <div class="slim-page">
    <settings-page-header section="account" page="access" />

    <!-- Password change section -->
    <div class="q-pa-md">
      <div class="q-mb-md text-h6">
        {{ $t("settings.account.access.password.label") }}
      </div>
      <q-form @submit="doChangePassword" class="q-gutter-y-sm" v-if="user">
        <password-input
          dense
          outlined
          hide-bottom-space
          autocomplete="current-password"
          v-model="passwordCurrent"
          :label="$t('settings.account.access.password.currentPassword')"
        />

        <div class="q-mt-xl">
          <set-password-form
            :user-inputs="[user.firstName, user.lastName, user.email]"
            @update:password="passwordNew = $event"
            @update:error-status="passwordError = $event"
          />
        </div>
        <div class="row justify-end">
          <q-btn
            dense
            type="submit"
            color="primary"
            :label="$t('settings.account.access.password.changePassword')"
            :disable="
              !user || !passwordNew || !passwordCurrent || passwordError
            "
            :loading="isUpdatingPassword"
          />
        </div>
      </q-form>
      <div v-else>
        <q-skeleton type="QInput" />
        <q-skeleton type="QInput" />
      </div>
    </div>

    <q-separator class="q-my-md" />

    <!-- TOTP (two factor) section -->
    <div class="q-pa-md" v-if="totpStatus && totpStatus.canSetup">
      <div class="q-mb-md text-h6">
        {{ $t("settings.account.access.totp.label") }}
      </div>
      <q-form
        @submit.prevent="
          totpStatus && totpStatus.isActive
            ? deactivateTOTPHandler()
            : activateTOTPHandler()
        "
      >
        <div v-if="totpStatus.isActive">
          <div class="q-mb-md">
            {{ $t("settings.account.access.totp.activeMessage") }}
          </div>
          <div class="row justify-end">
            <q-btn
              dense
              color="primary"
              :label="$t('settings.account.access.totp.deactivateButton')"
              @click.prevent="deactivateTOTPHandler"
            />
          </div>
        </div>
        <div v-else>
          <div class="q-mb-sm">
            {{ $t("settings.account.access.totp.instructions") }}:

            <!-- Let DisplayTOTPQr handle turning totpUrl into an <img> -->
            <DisplayTOTPQr :url="totpStatus.totpUrl || undefined" />

            {{ $t("settings.account.access.totp.secretLabel") }}:
            <strong>{{ totpStatus.secret }}</strong>
            <q-btn
              dense
              flat
              size="sm"
              color="neutral-7"
              icon="sym_r_content_copy"
              @click="copySecret"
            />
          </div>
          <mfa-code-input v-model="activationCode" />
          <div class="row justify-end q-mt-sm">
            <q-btn
              dense
              color="primary"
              type="submit"
              :label="$t('settings.account.access.totp.activateButton')"
              :disable="!isValidMfaCode(activationCode)"
            />
          </div>
        </div>
      </q-form>
    </div>
    <div v-else>
      <q-skeleton type="QInput" />
      <q-skeleton type="QInput" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { changePassword, ReauthenticationRequired } from "@/api/auth";
import {
  activateTOTP,
  deactivateTOTP,
  getTOTPStatus,
  TOTPActivationWrongCodeError,
  type TOTPStatus,
} from "@/api/authTotp";
import MfaCodeInput from "@/components/MfaCodeInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import SetPasswordForm from "@/components/SetPasswordForm.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import DisplayTOTPQr from "@/components/TotpQrDisplay.vue";
import { useCurrentUserStore } from "@/stores/currentUser";
import { useTotpSetupStore } from "@/stores/totpSetup";
import { isValidMfaCode } from "@/utils/validation";
import { isAxiosError } from "axios";
import { useQuasar } from "quasar";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const userStore = useCurrentUserStore();
if (!userStore.user) {
  userStore.loadUser();
}

const user = ref(userStore.user);
const passwordNew = ref("");
const passwordCurrent = ref("");

const passwordError = ref(false);
const isUpdatingPassword = ref(false);
const q = useQuasar();
const { t } = useI18n();

const totpStatus = ref<TOTPStatus | null>(null);
const activationCode = ref("");

const totpSetupStore = useTotpSetupStore();
const router = useRouter();

watch(
  () => userStore.user,
  (newUser) => {
    user.value = newUser;
  },
);

async function doChangePassword() {
  isUpdatingPassword.value = true;
  try {
    await changePassword(passwordCurrent.value, passwordNew.value);
    q.notify({
      type: "positive",
      message: t("settings.account.access.password.passwordUpdated"),
    });
    passwordNew.value = "";
    passwordCurrent.value = "";
  } catch (error: any) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status == 400 &&
      error.response?.data?.errors[0]?.code == "enter_current_password"
    ) {
      q.notify({
        type: "negative",
        message: t("settings.account.access.password.currentPasswordWrong"),
      });
    } else {
      throw error;
    }
  } finally {
    isUpdatingPassword.value = false;
  }
}

async function getTOTPStatusAndUpdate() {
  try {
    const status = await getTOTPStatus();
    totpStatus.value = status;
    if (status.canSetup && !status.isActive && status.secret) {
      // Keep the secret and totpUrl in the store, as on activation, we need
      // to reauthenticate and not storing the secret would mean GETing a new one
      // once we redirect back to the settings page
      totpSetupStore.setTotpSetup(status.secret, status.totpUrl!);
    } else {
      totpSetupStore.clearTotpSetup();
    }
  } catch (error) {
    console.error("Failed to get TOTP status:", error);
  }
}

onMounted(() => {
  if (totpSetupStore.secret && totpSetupStore.totpUrl) {
    totpStatus.value = {
      canSetup: true,
      isActive: false,
      secret: totpSetupStore.secret,
      totpUrl: totpSetupStore.totpUrl,
    };
  } else {
    getTOTPStatusAndUpdate();
  }
});

/**
 * Called when user clicks the "Activate" button (submit).
 */
async function activateTOTPHandler() {
  try {
    await activateTOTP(activationCode.value);

    q.notify({
      type: "positive",
      message: t("settings.account.access.totp.activatedMessage"),
    });
    activationCode.value = "";
    totpSetupStore.clearTotpSetup();
    await getTOTPStatusAndUpdate();
  } catch (error: any) {
    if (error instanceof TOTPActivationWrongCodeError) {
      q.notify({
        type: "negative",
        message: t("settings.account.access.totp.invalidCodeMessage"),
      });
    } else if (error instanceof ReauthenticationRequired) {
      router.push({
        name: "login",
        query: {
          reauthenticate: "1",
          redirect: router.currentRoute.value.fullPath,
        },
      });
    } else {
      throw error;
    }
  }
}

async function deactivateTOTPHandler() {
  try {
    await deactivateTOTP();
    q.notify({
      type: "positive",
      message: t("settings.account.access.totp.deactivatedMessage"),
    });
  } catch (error: any) {
    if (error instanceof ReauthenticationRequired) {
      router.push({
        name: "login",
        query: {
          reauthenticate: "1",
          redirect: router.currentRoute.value.fullPath,
        },
      });
    } else {
      q.notify({
        type: "negative",
        message: t("settings.account.access.totp.deactivationFailedMessage"),
      });
    }
  }
  // Either way, once TOTP is disabled, we clear store to avoid stale data
  totpSetupStore.clearTotpSetup();
  await getTOTPStatusAndUpdate();
}

function copySecret() {
  if (totpStatus.value?.secret) {
    navigator.clipboard.writeText(totpStatus.value.secret);
    q.notify({
      type: "positive",
      message: t("settings.account.access.totp.copiedSecretMessage"),
    });
  }
}
</script>
