<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="tags" />
    <q-linear-progress indeterminate class="q-mb-sm" v-if="isUpdating" />
    <q-skeleton v-if="isLoading" />
    <settings-tags-list
      v-else
      :tags="tags"
      :can-write="canWrite"
      :is-updating="isUpdating"
      :can-convert-to-organization-tag="false"
      @edit-tag="editTag"
      @delete-tag="deleteTag"
    />
    <q-btn
      v-if="canWrite"
      dense
      color="primary"
      class="q-mt-md float-right"
      :label="$t('settings.inbox.tags.createTag')"
      @click="createTag"
      :disable="isUpdating"
    />
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/tag";
import CreateOrEditTagDialog from "@/components/Settings/Inbox/CreateOrEditTagDialog.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import SettingsTagsList from "@/components/Settings/SettingsTagsList.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import type { Tag } from "@/types/tag";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const tags = ref<Tag[]>([]);
const isLoading = ref(false);
const isUpdating = ref(false);

const { organizationId } = useRouteParams();

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore(),
);
const canWrite = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"],
);

const inboxStore = useCurrentInboxStore();
const organizationStore = useCurrentOrganizationStore();

const { t } = useI18n();

async function loadData() {
  isLoading.value = true;

  try {
    tags.value = await api.listOrganizationTags(organizationId.value);
  } finally {
    isLoading.value = false;
  }
}
watch(organizationId, loadData, { immediate: true });

const q = useQuasar();

function editTag(tag: Tag) {
  q.dialog({
    component: CreateOrEditTagDialog,
    componentProps: { tag },
  }).onOk(async (data: { label: string; color: string }) => {
    isUpdating.value = true;
    try {
      await api.updateOrganizationTag(organizationId.value, {
        id: tag.id,
        label: data.label,
        color: data.color,
      });
    } finally {
      isUpdating.value = false;
      await Promise.all([
        loadData(),
        inboxStore.loadData(),  // available org tags are sent via inbox
        organizationStore.loadData(),
      ]);
    }
  });
}

function deleteTag(tag: Tag) {
  q.dialog({
    title: t("settings.inbox.tags.confirmDelete.title", { label: tag.label }),
    message: t("settings.inbox.tags.confirmDelete.message", {
      label: tag.label,
    }),
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    isUpdating.value = true;
    try {
      await api.deleteOrganizationTag(organizationId.value, tag.id);
    } finally {
      isUpdating.value = false;
      await Promise.all([
        loadData(),
        inboxStore.loadData(),  // available org tags are sent via inbox
        organizationStore.loadData(),
      ]);
    }
  });
}

function createTag() {
  q.dialog({
    component: CreateOrEditTagDialog,
  }).onOk(async (data: { label: string; color: string }) => {
    isUpdating.value = true;
    try {
      await api.createOrganizationTag(organizationId.value, data);
    } finally {
      isUpdating.value = false;
      await Promise.all([
        loadData(),
        inboxStore.loadData(),  // available org tags are sent via inbox
        organizationStore.loadData(),
      ]);
    }
  });
}
</script>
