import {
  updateOrganization as apiUpdateOrganization,
  readOrganization,
} from "@/api/organization";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Organization, PartialOrganization } from "@/types/organization";
import * as Sentry from "@sentry/browser";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export const useCurrentOrganizationStore = defineStore("organization", () => {
  const organization = ref<Organization | null>(null);

  const { organizationId } = useRouteParams();

  const is404 = ref(false);

  async function loadData() {
    if (isNaN(organizationId.value)) return;
    try {
      organization.value = await readOrganization(organizationId.value);
    } catch (error: any) {
      if (error.response?.status === 404) {
        is404.value = true;
        return;
      } else {
        throw error;
      }
    }

    is404.value = false;
    Sentry.setContext("organization", {
      id: organization.value.id,
      name: organization.value.name,
    });
    Sentry.setTag("organization_id", organization.value.id.toString());
  }

  watch(organizationId, loadData, { immediate: true });

  async function updateOrganization(newOrganization: PartialOrganization) {
    organization.value = await apiUpdateOrganization(
      organizationId.value,
      newOrganization
    );
  }

  const { locale } = useI18n();

  const currencyFormatter = computed(() => {
    return new Intl.NumberFormat(locale.value, {
      style: "currency",
      currency: organization.value?.details.currency,
    });
  });

  return {
    organization,
    is404,
    loadData,
    updateOrganization,
    currencyFormatter,
  };
});
