<template>
  <div class="fit col column no-wrap">
    <q-item
      dense
      class="col-auto full-width positions-table-filter-bar row items-start q-px-md q-py-xs"
    >
      <div class="row gap-x-md gap-y-sm items-center">
        <input
          dense
          outlined
          v-model="localFilterText"
          :placeholder="$t('inquiryPositionsPage.positionsFilter.text')"
          class="standalone-input"
          @keydown.stop.a
          @keydown.stop.f
        />
        <q-checkbox
          dense
          size="sm"
          v-model="filterOffered"
          :label="$t('inquiryPositionsPage.positionsFilter.offered')"
        />
        <q-checkbox
          dense
          size="sm"
          v-model="filterNotCompleted"
          :label="$t('inquiryPositionsPage.positionsFilter.notCompleted')"
        />
        <q-checkbox
          v-if="organization?.useSupplierData"
          dense
          size="sm"
          v-model="filterSupplierRfqs"
          :label="$t('inquiryPositionsPage.positionsFilter.supplierRfqs')"
        />
        <positions-table-filter-tags-button />
      </div>
      <q-space />
      <div class="positions-table-count-indicator row no-wrap items-center">
        <div class="text-nowrap q-ml-sm">{{ countIndicator }}</div>
        <q-btn
          :disable="!canEditInquiryPositions || isLoading"
          dense
          flat
          icon="sym_r_add"
          size="sm"
          @click="addGroup"
          class="q-ml-xs"
        >
          <q-tooltip>{{
            $t("inquiryPositionsPage.addOfferPositionGroup")
          }}</q-tooltip>
        </q-btn>
      </div>
    </q-item>
    <positions-table-skeleton v-if="isLoading" />
    <q-virtual-scroll
      v-else-if="visibleOfferPositionGroups"
      ref="virtualScroll"
      separator
      class="positions-table full-width"
      :items="visibleOfferPositionGroups"
      v-slot="{ item: group }"
      :virtual-scroll-slice-ratio-before="2"
      :virtual-scroll-slice-ratio-after="2"
      :virtual-scroll-item-size="30"
    >
      <offer-position-group
        :group="group"
        :key="group.id"
        :ref="
          (el) =>
            (groupElRefs[group.id] = el as typeof OfferPositionGroup | null)
        "
        :is-selected="selectedGroupIds.has(group.id)"
        :is-expanded="expandedGroupIds.has(group.id)"
        :disabled="!canEditInquiryPositions"
        :draggable="group.isManuallyCreated"
        @headerclick="(event) => handleOpgHeaderClick(event, group.id)"
        @headerrightclick="(event) => handleOpgRightClick(event, group.id)"
        @activate="handleOpgActivate(group.id)"
        @set-offered="(isOffered) => handleOpgSetOffered(group.id, isOffered)"
        @set-completed="
          (isCompleted) => handleOpgSetCompleted(group.id, isCompleted)
        "
        @inputclick="selectIfNotSelected(group.id)"
        @expand="expandGroupId(group.id)"
        @expand-only="
          expandOnlyGroupId(group.id);
          selectIfNotSelected(group.id);
        "
        @collapse="collapseGroupId(group.id)"
      />
    </q-virtual-scroll>
    <positions-table-context-menu
      :disabled="!canEditInquiryPositions"
      :clickedGroupId="contextMenuTargetGroupId"
      :copy-products-source-group-id="copyProductsSourceGroupId"
      :copy-products-source-group-has-positions="
        copyProductsSourceGroupHasPositions
      "
      @update:copy-products-source-group-id="
        (groupId: number | null) => (copyProductsSourceGroupId = groupId)
      "
    />
  </div>
</template>

<script setup lang="ts">
import { usePositionsTableKeyboardShortcuts } from "@/composables/positionsTable/usePositionsTableKeyboardShortcuts";
import { provideSelection } from "@/composables/positionsTable/useSelection";
import { provideSupplierRfq } from "@/composables/positionsTable/useSupplierRfq";
import { provideTags } from "@/composables/positionsTable/useTags";
import { usePositionsEvents } from "@/composables/usePositionsEvents";
import { useRouteParams } from "@/composables/useRouteParams";
import { useSelectedPositionIds } from "@/composables/useSelectedPositionIds";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPositionGroup as OfferPositionGroupType } from "@/types/offerPositionGroup";
import { cmdOrCtrlPressed } from "@/utils/cmdOrCtrl";
import { debounceRef } from "@/utils/debouncedRef";
import { ReactiveSet } from "@/utils/reactiveSet";
import { parseInt } from "lodash";
import { storeToRefs } from "pinia";
import { type QVirtualScroll } from "quasar";
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import OfferPositionGroup from "./OfferPositionGroup/OfferPositionGroup.vue";
import PositionsTableContextMenu from "./PositionsTableContextMenu.vue";
import PositionsTableFilterTagsButton from "./PositionsTableFilterTagsButton.vue";
import PositionsTableSkeleton from "./PositionsTableSkeleton.vue";

const { organization } = storeToRefs(useCurrentOrganizationStore());

const store = useCurrentOfferPositionGroupsStore();
const { getVisibleGroupIndex: getIndex, getVisibleGroupId: getId } = store;
const { visibleOfferPositionGroups, isLoading } = storeToRefs(store);
const {
  filterText,
  filterOffered,
  filterNotCompleted,
  filterSupplierRfqs,
} = storeToRefs(store);

const { canEditInquiryPositions } = storeToRefs(useCurrentInquiryStore());

const localFilterText = debounceRef(filterText, 300);

const virtualScroll = ref<QVirtualScroll | null>(null);

const groupElRefs = ref<Record<number, typeof OfferPositionGroup | null>>({});

const contextMenuTargetGroupId = ref<number | null>(null);
const copyProductsSourceGroupId = ref<number | null>(null);
const copyProductsSourceGroupHasPositions = computed(() => {
  if (!copyProductsSourceGroupId.value) return false;
  const group = visibleOfferPositionGroups.value?.find(
    (g) => g.id === copyProductsSourceGroupId.value,
  );
  if (!group) return false;
  return group.offerPositions.length > 0;
});

const { t } = useI18n();

const events = usePositionsEvents();

function handleSelectFromDocument(spotlightId: string) {
  const index = getIndexFromSpotlightId(spotlightId);
  if (index === null) return;
  select(index);
  scrollToIndex(index);
}

function handleclearSelection() {
  unselect();
}

onMounted(() => {
  events.on("selectFromDocument", handleSelectFromDocument);
  events.on("clearSelection", handleclearSelection);
});
onBeforeUnmount(() => {
  events.off("selectFromDocument", handleSelectFromDocument);
  events.off("clearSelection", handleclearSelection);
});

const numRows = computed(() => visibleOfferPositionGroups.value?.length || 0);
const countIndicator = computed(() => {
  if (selectedIndices.value.length === 0)
    return t("inquiryPositionsPage.positionsTable.nPositions", {
      positions: numRows.value,
    });
  return t("inquiryPositionsPage.positionsTable.nSelected", {
    selected: selectedIndices.value.length,
    positions: numRows.value,
  });
});

const {
  selectedIndices,
  selectedGroupIds,
  select,
  selectNext,
  selectPrevious,
  extendSelectNext,
  extendSelectPrevious,
  extendSelectIndex,
  toggleSelectIndex,
  unselect,
  selectAll,
  selectGroupIds,
} = provideSelection();
const selectedPositionIds = useSelectedPositionIds();
watch(selectedGroupIds.ref, () => {
  selectedPositionIds.value = selectedGroupIds.values
    .flatMap(getElementSpotlightIdsFromGroupId)
    .filter((id) => id !== null) as string[];
});

// Provide tags centrally here so that computed refs are only recomputed once, not per group
provideTags(selectedGroupIds);

const router = useRouter();

watch(visibleOfferPositionGroups, async () => {
  // handle preselected group ids from route query params
  if (!visibleOfferPositionGroups) return;

  const { preselectGroupIds: preselectGroupIdsStrings } =
    router.currentRoute.value.query;

  if (!preselectGroupIdsStrings) return;

  router.replace({
    query: { ...router.currentRoute.value.query, preselectGroupIds: null },
  });

  const preselectGroupIds = (preselectGroupIdsStrings as string[]).map(
    parseInt,
  );

  if (!preselectGroupIds.length) return;

  selectGroupIds(preselectGroupIds);

  const firstGroupIndex = getIndex(preselectGroupIds[0]);

  if (firstGroupIndex == null) return;

  await nextTick();

  scrollToIndex(firstGroupIndex);
  scrollDocumentToIndex(firstGroupIndex);
});

provideSupplierRfq(selectedGroupIds);

const expandedGroupIds = new ReactiveSet<number>();

function expandOnlyGroupId(groupId: number) {
  expandedGroupIds.replace([groupId]);
}

function expandGroupId(groupId: number) {
  expandedGroupIds.add(groupId);
}

function collapseGroupId(groupId: number) {
  expandedGroupIds.delete(groupId);
}

function selectGroupId(groupId: number | null) {
  select(getIndex(groupId));
}

function handleOpgActivate(groupId: number) {
  selectIfNotSelected(groupId);
  selectGroupId(groupId);
}

function handleOpgHeaderClick(
  event: MouseEvent | KeyboardEvent,
  groupId: number,
) {
  event.stopPropagation();

  const index = getIndex(groupId);

  if (index === null) throw new Error("Group not found");

  if (cmdOrCtrlPressed(event)) {
    toggleSelectIndex(index);
  } else if (event.shiftKey) {
    window.getSelection()?.removeAllRanges(); // prevent accidental text selection
    extendSelectIndex(index);
  } else {
    select(index);
  }

  scrollDocumentToIndex(index);
}

function handleOpgRightClick(event: Event, groupId: number) {
  event.stopPropagation();
  selectIfNotSelected(groupId, false);
  contextMenuTargetGroupId.value = groupId;
}

function selectIfNotSelected(groupId: number, scrollPdf: boolean = true) {
  const index = getIndex(groupId);
  if (index === null) throw new Error("Group not found");
  if (selectedGroupIds.has(groupId)) return;
  select(index);
  if (scrollPdf) {
    scrollDocumentToIndex(index);
  }
}

function activateSelected() {
  const id = getId(selectedIndices.value[0]);
  if (!id) return;

  const group = visibleOfferPositionGroups.value?.find((g) => g.id === id);
  if (!group) return;

  const el = groupElRefs.value[id];

  if (el) {
    el.activate();
  }
}

async function handleOpgSetOffered(groupId: number, isOffered: boolean) {
  if (selectedGroupIds.has(groupId)) {
    setOfferedSelected(isOffered);
  } else {
    // set only this group as offered
    selectIfNotSelected(groupId);
    const data: Partial<OfferPositionGroupType> = { isOffered };
    if (!isOffered) data.isCompleted = false;

    await store.updateOfferPositionGroup(groupId, data);
  }
}

async function handleOpgSetCompleted(groupId: number, isCompleted: boolean) {
  if (selectedGroupIds.has(groupId)) {
    setCompletedSelected(isCompleted);
  } else {
    // set only this group as completed
    selectIfNotSelected(groupId);
    const data: Partial<OfferPositionGroupType> = { isCompleted };
    if (isCompleted) data.isOffered = true;

    await store.updateOfferPositionGroup(groupId, data);
  }
}

function toggleOfferedSelected() {
  const id = getId(selectedIndices.value[0]);
  if (!id) return;
  const group = visibleOfferPositionGroups.value?.find((g) => g.id === id);
  if (!group) return;
  setOfferedSelected(!group.isOffered);
}

function toggleCompletedSelected() {
  const id = getId(selectedIndices.value[0]);
  if (!id) return;
  const group = visibleOfferPositionGroups.value?.find((g) => g.id === id);
  if (!group) return;
  setCompletedSelected(!group.isCompleted);
}

async function setOfferedSelected(isOffered: boolean) {
  if (!canEditInquiryPositions.value) return;
  const data: Partial<OfferPositionGroupType> = { isOffered };
  if (!isOffered) data.isCompleted = false;
  await store.updateMultipleOfferPositionGroups(selectedGroupIds.values, data);
}

function setCompletedSelected(isCompleted: boolean) {
  if (!canEditInquiryPositions.value) return;
  const data: Partial<OfferPositionGroupType> = { isCompleted };
  if (isCompleted) data.isOffered = true;
  store.updateMultipleOfferPositionGroups(selectedGroupIds.values, data);
}

function expandSelected() {
  expandedGroupIds.union(selectedGroupIds.values);
}

function expandOnlySelected() {
  expandedGroupIds.replace(selectedGroupIds.values);

  if (selectedGroupIds.values.length === 1) {
    const group = visibleOfferPositionGroups.value?.find(
      (g) => g.id === selectedGroupIds.values[0],
    );
    if (!group) return;
    const el = groupElRefs.value[group.id];
    if (!el) return;
    el.focusFirstOfferPosition();
  }
}

function collapseSelected() {
  expandedGroupIds.difference(selectedGroupIds.values);
}

function handleDownArrow() {
  selectNext();
  scrollToIndex(selectedIndices.value[0]);
  scrollDocumentToIndex(selectedIndices.value[0]);
}

function handleUpArrow() {
  selectPrevious();
  scrollToIndex(selectedIndices.value[0]);
  scrollDocumentToIndex(selectedIndices.value[0]);
}

function handleShiftDownArrow() {
  extendSelectNext();
  const lastIndex = selectedIndices.value.at(-1);
  if (lastIndex !== undefined) scrollToIndex(lastIndex);
}

function handleShiftUpArrow() {
  extendSelectPrevious();
  const firstIndex = selectedIndices.value[0];
  if (firstIndex !== undefined) scrollToIndex(firstIndex);
}

function scrollDocumentToIndex(index: number) {
  const spotlightId = getElementSpotlightIdFromIndex(index);
  if (spotlightId) events.emit("tableRowClick", spotlightId);
}

usePositionsTableKeyboardShortcuts({
  onEnter: activateSelected,
  onA: toggleOfferedSelected,
  onF: toggleCompletedSelected,
  onRightArrow: expandOnlySelected,
  onLeftArrow: collapseSelected,
  onDownArrow: handleDownArrow,
  onUpArrow: handleUpArrow,
  onShiftRightArrow: expandSelected,
  onShiftLeftArrow: collapseSelected,
  onShiftDownArrow: handleShiftDownArrow,
  onShiftUpArrow: handleShiftUpArrow,
  onEscape: unselect,
  onCtrlOrCmdA: selectAll,
});

const { inquiryId } = useRouteParams();
watch(inquiryId, unselect);

function scrollToIndex(index: number | null) {
  if (index === null) return;
  virtualScroll.value?.scrollTo(index, "center");
}

function getElementSpotlightIdFromIndex(
  index: number | null | undefined,
): string | null {
  if (index === null || index == undefined) return null;
  return visibleOfferPositionGroups.value?.[index]?.spotlightId || null;
}

function getElementSpotlightIdsFromGroupId(
  groupId: number | null,
): string[] | null {
  const group = visibleOfferPositionGroups.value?.find((g) => g.id === groupId);
  if (!group) return null;
  // spotlight ids only make sense for groups with a document or email id
  if (!group.documentId && !group.emailId) return null;
  return group.spotlightElementIds.concat([group.spotlightId]);
}

function getIndexFromSpotlightId(spotlightId: string | null): number | null {
  if (spotlightId === null) return null;
  const index = visibleOfferPositionGroups.value?.findIndex(
    (g) => g.spotlightId === spotlightId,
  );
  return index === -1 || index === undefined ? null : index;
}

function addGroup() {
  const lastSelectedIndex = selectedIndices.value.at(-1);
  const lastSelectedId = getId(lastSelectedIndex);
  store.addOfferPositionGroup(lastSelectedId);
}
</script>

<style scoped lang="scss">
.positions-table {
  height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
}

.positions-table-filter-bar {
  border-bottom: 1px solid $separator-color;
  font-size: smaller;
}

.positions-table-count-indicator {
  height: 24px;
}
</style>
