import { defineStore } from "pinia";

export const useTotpSetupStore = defineStore("totpSetup", {
  state: () => ({
    // If TOTP not active yet, store the secret we received so we can reuse it (e.g. if forced to reauth).
    secret: null as string | null,
    totpUrl: null as string | null,
  }),
  actions: {
    setTotpSetup(secret: string, totpUrl: string) {
      this.secret = secret;
      this.totpUrl = totpUrl;
    },
    clearTotpSetup() {
      this.secret = null;
      this.totpUrl = null;
    },
  },
});
