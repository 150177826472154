<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="automationRules" />
    <q-skeleton v-if="isLoading" />
    <div v-else class="q-pa-md">
      <q-list class="column no-wrap gap-y-sm">
        <automation-rule-item
          v-for="automationRule in automationRules"
          :key="automationRule.id"
          :automationRule="automationRule"
          :availableTags="availableTags"
          @delete="deleteAutomationRule(automationRule)"
        />
      </q-list>
      <div class="q-mt-lg row no-wrap justify-end">
        <q-btn
          dense
          v-if="!isLoading"
          color="primary"
          icon="add"
          :label="$t('settings.inbox.automationRules.createAutomationRule')"
          @click="addAutomationRule"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/automationRule";
import { getInbox as apiGetInbox } from "@/api/inbox";
import AutomationRuleItem from "@/components/Settings/Inbox/AutomationRules/AutomationRuleItem.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import type { AutomationRule } from "@/types/automationRule";
import type { Tag } from "@/types/tag";
import { ref, watch } from "vue";

const { organizationId, inboxId } = useRouteParams();

const automationRules = ref<AutomationRule[]>([]);
const availableTags = ref<Tag[]>([]);
const isLoading = ref(false);

async function loadAutomationRulesData() {
  isLoading.value = true;
  try {
    automationRules.value = await api.listInboxAutomationRules(
      organizationId.value,
      inboxId.value
    );
    const inbox = await apiGetInbox(organizationId.value, inboxId.value);
    availableTags.value = inbox.availableTags;
  } finally {
    isLoading.value = false;
  }
}

async function deleteAutomationRule(automationRule: AutomationRule) {
  await api.deleteAutomationRule(inboxId.value, automationRule.id);
  automationRules.value = automationRules.value.filter(
    (rule) => rule.id !== automationRule.id
  );
}

async function addAutomationRule() {
  const newRule = await api.createAutomationRule(inboxId.value);
  automationRules.value.push(newRule);
}

watch([organizationId, inboxId], loadAutomationRulesData, { immediate: true });
</script>
